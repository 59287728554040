import axios from 'axios';

const token = localStorage.getItem('token');
export default axios.create({
  // baseURL: "http://localhost:8000/api/",
  //baseURL: 'https://fmsapi.yottaerp.com/api/',
  baseURL: 'https://fmsstaginapi.yottaerp.com/api/',
  headers: {
    'Content-Type': 'application/json',
    'Authorization': token,
  }
});

// export const HeaderRequest = () => {
//   const token = localStorage.getItem('token');
//   return {headers : {Authorization : token}};
// }