import React, {useState, useEffect} from "react";
import {Form, Card, notification, Upload, Button, Row, Col} from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import jsonfile from './asset_info.json'
import {CisUI} from '../../../../util/CISUI'
import InputFields from '../../../../util/InputField'
import 'react-smart-data-table/dist/react-smart-data-table.css'
import Config from "../../../../util/config";
import axios from "axios";
import {useHistory} from "react-router-dom";
import RemoteJson from "../../../../util/gerremotedata";
import InputFieldV2 from "../../../../util/InputFieldV2";

const NewAssetInfo = (props) => {

    const [loading, setLoading] = useState([]);

    const handleChange=(e)=> {
        this.setState({
            [e.target.id]: e.target.value
        })
    }

    const [img, setImg] = useState([]);

    const normFile = e => {
        console.log('Upload event:', e);

        if (Array.isArray(e)) {
            return e;
        }

        return e && e.fileList;
    };

    const handleFile = (e) => {
        let file = e.target.files[0];
        console.log(file);
        let reader = new FileReader();
        reader.onload = (e)=> {
            setImg({
                ...img,
                warranty_attachment : e.target.result
            });
        };
        reader.readAsDataURL(file);
    }

    const userID = props.match.params.id;
    let userData = [];
    // console.log("Userid : "+props.match.params.id);
    let isEdit = 0;
    if(userID > 0) {
        isEdit = 1;
    }
    let Title = jsonfile.addtitle;
    let endpoint = jsonfile.urls.add;
    let redirectto = "../"+jsonfile.urls.list;

    if(isEdit === 1 ) {
        userData = RemoteJson(jsonfile.urls.view+"/"+userID);
        Title = jsonfile.edittitle;
        endpoint = jsonfile.urls.edit+"/"+userID;
        redirectto = "../../"+jsonfile.urls.list;
    }


    const [form] = Form.useForm();
    const history = useHistory();

    const onFinish = (values) => {
        setLoading({loading:true})
        // console.log("Input Data : "+values);
        const data = {
            ...values,
            'purchase_date' : values['purchase_date'].format("YYYY-MM-DD"),
            'maturity' : values['maturity'].format("YYYY-MM-DD")
        }
        // handleFile
        console.log(data);
        // console.log(values);

        // axios.post(Config.apiserver + endpoint, data, CisUI().HeaderRequest)
        //     .then(res => {
        //         console.log(res)
        //         if(res.data.status === 1) {
        //             notification.success({
        //                 message: 'Success',
        //                 type : "success",
        //                 description: res.data.msg
        //             });
        //             history.push(redirectto);
        //         }
        //         else {
        //             notification.warning({
        //                 message: 'Alert',
        //                 type : "warning",
        //                 description: res.data.msg
        //             });
        //         }
        //         setLoading({loading:false});
        //     })
        //     .catch(errors => {
        //         console.log(errors.response.data.errors);
        //         setLoading({loading:false});
        //     });
    };

    return (
        <Card title={Title}>
            {
                isEdit === 1
                    ? CisUI().addAction('../../'+jsonfile.urls.list)
                    : CisUI().addAction('../'+jsonfile.urls.list)
            }
            <Form
                form={form}
                {...CisUI().formItemLayout}
                name="input"
                className="ant-advanced-search-form"
                onFinish={onFinish}
                initialValues={{ remember: true }}
            >
                {InputFieldV2(jsonfile.input,userData,isEdit,props)}

                <Form.Item
                    name="warranty_attachment"
                    label="Warranty Attachment"
                    // valuePropName="fileList"
                    getValueFromEvent={normFile}
                    extra=""
                    // rules={[
                    //     {
                    //         required: list.required,
                    //         message:
                    //     },
                    // ]}
                >
                    <Upload {...props}>
                        <Button icon={<UploadOutlined />}>Select File</Button>
                    </Upload>
                </Form.Item>

                <Row gutter={24}>
                    <Col lg={24} md={24} sm={12} xs={24} style={{textAlign: 'center'}}>
                        <Button type="primary" htmlType="submit">
                            Submit
                        </Button>
                        <Button type="info" onClick={() => history.goBack()} htmlType="back">Cancel</Button>
                    </Col>
                </Row>

            </Form>
        </Card>
    );
};

export default NewAssetInfo;

//    {
//      "label" : "Warranty Attachment",
//      "name" : "warranty_attachment",
//      "placeholder" : "Enter Warranty Attachment",
//      "error_msg" : "Warranty Attachment is required.",
//      "type" : "file",
//      "size" : 6,
//      "value" : "",
//      "required" : false,
//      "readonly" : 0,
//      "onkeyup" : "",
//      "isEditable" : 1,
//      "isFilterable" : 0
//    },
//    {
//      "label" : "Invoice Attachment",
//      "name" : "invoice_attachment",
//      "placeholder" : "Enter Invoice Attachment",
//      "error_msg" : "Invoice Attachment is required.",
//      "type" : "file",
//      "size" : 6,
//      "value" : "",
//      "required" : false,
//      "readonly" : 0,
//      "onkeyup" : "",
//      "isEditable" : 1,
//      "isFilterable" : 0
//    }