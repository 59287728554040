import {Form, Row, Col, Input, Button, Card, Select, Checkbox,notification, Spin, TimePicker} from 'antd';
import React, {Component,useState, useEffect} from "react";
import jsonfile from './shift.json'
import {CisUI} from '../../../../util/CISUI'
import PostToApi from '../../../../util/PostToApi'
import InputFields from '../../../../util/InputField'
import 'react-smart-data-table/dist/react-smart-data-table.css'
import Config from "../../../../util/config";
import axios from "axios";
import {useHistory} from "react-router-dom";
import RemoteJson from "../../../../util/gerremotedata";
import SelectData from "../../../../util/selectdata";
import moment from "moment";
// import 'moment/locale/es-us';

const {Option} = Select;
const {TextArea} = Input;

const NewShift = (props) => {

    const [loading, setLoading] = useState([]);


    const [form] = Form.useForm();
    const history = useHistory();

    const handleChange=(e)=> {
        this.setState({
            [e.target.id]: e.target.value
        })
    }

    const Companies = SelectData("company/companylists");
    const Status = SelectData("/userstatus");

    const userID = props.match.params.id;
    let userData = [];
    // console.log("Userid : "+props.match.params.id);
    let isEdit = 0;
    if(userID > 0) {
        isEdit = 1;
    }
    let Title = jsonfile.addtitle;
    let endpoint = jsonfile.urls.add;
    let redirectto = "../"+jsonfile.urls.list;
    let days =[];

    if(isEdit === 1 ) {
        userData = RemoteJson(jsonfile.urls.view+"/"+userID);

        if(userData.work_day){
            days = userData.work_day.split(",");
        }


        Title = jsonfile.edittitle;
        endpoint = jsonfile.urls.edit+"/"+userID;
        redirectto = "../../"+jsonfile.urls.list;
        // form.setFieldsValue(userData)
        // console.log(typeof d);


        console.log(userData.start_time);

    }

    useEffect(() => {
        form.setFieldsValue({
            com_id: userData.com_id,
            name: userData.name,
            // start_time: moment(userData.start_time).format("HH:mm:ss"),
            working_hour: userData.working_hour,
            work_day:days,
            status: userData.status,
            description: userData.description
        });
    }, [form, userData])

    const onFinish = (values) => {
        setLoading({loading:true})
        // console.log("Input Data : "+values);

        console.log(values);

        const data = {
            ...values,
            'start_time': values['start_time'].format('HH:mm:ss'),
            'end_time': values['end_time'].format('HH:mm:ss'),
        }
        console.log(data);

        // axios.post(Config.apiserver + endpoint, data, CisUI().HeaderRequest)
        //     .then(res => {
        //         console.log(res)
        //         if(res.data.status === 1) {
        //             notification.success({
        //                 message: 'Success',
        //                 type : "success",
        //                 description: res.data.msg
        //             });
        //             history.push(redirectto);
        //         }
        //         else {
        //             notification.warning({
        //                 message: 'Alert',
        //                 type : "warning",
        //                 description: res.data.msg
        //             });
        //         }
        //         setLoading({loading:false});
        //     })
        //     .catch(errors => {
        //         console.log(errors.response.data.errors);
        //         // msg = (errors);
        //         setLoading({loading:false});
        //         //setResponse([]);
        //     });
    };


    //console.log("UserData : "+userData);

    return (
        <Card title={Title}>
            {
                isEdit === 1
                    ? CisUI().addAction('../../'+jsonfile.urls.list)
                    : CisUI().addAction('../'+jsonfile.urls.list)
            }

            <Form
                form={form}
                {...CisUI().formItemLayout}
                name="input"
                className="ant-advanced-search-form"
                onFinish={onFinish}
                initialValues={{remember: true}}
            >
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="gx-form-row0">
                                        <Form.Item
                                            name="com_id"
                                            label="Company"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Company is required."
                                                },
                                            ]}
                                        >
                                            <Select
                                                showSearch
                                                placeholder="Select a company"
                                                optionFilterProp="children"
                                                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                            >
                                                {
                                                    Companies.map((items, index) =>
                                                        <Option key={++index} value={items.id}>{items.name}</Option>
                                                    )
                                                }
                                            </Select>
                                        </Form.Item>
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="gx-form-row0">
                                        <Form.Item
                                            name="name"
                                            label="Name"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Shift Name is required."
                                                },
                                            ]}
                                        >
                                            <Input placeholder="Shift Name" />
                                        </Form.Item>
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="gx-form-row0">
                                        <Form.Item
                                            name="start_time"
                                            label="Start Time"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Select Start Time"
                                                },
                                            ]}
                                        >
                                            <TimePicker className="gx-mb-3 gx-w-100" />
                                        </Form.Item>
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="gx-form-row0">
                                        <Form.Item
                                            name="end_time"
                                            label="End Time"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Select End Time"
                                                },
                                            ]}
                                        >
                                            <TimePicker className="gx-mb-3 gx-w-100" />
                                        </Form.Item>
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="gx-form-row0">
                                        <Form.Item
                                            name="working_hour"
                                            label="Working Hour"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Working Hour is required"
                                                },
                                            ]}
                                        >
                                            <Input placeholder="Working Hour"/>
                                        </Form.Item>
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="gx-form-row0">
                                        <Form.Item
                                            name="description"
                                            label="Description"
                                        >
                                            <TextArea placeholder="Description"/>
                                        </Form.Item>
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="gx-form-row0">
                                        <Form.Item
                                            name="status"
                                            label="Status"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Status is required."
                                                },
                                            ]}
                                        >
                                            <Select
                                                showSearch
                                                placeholder="Select Status"
                                                optionFilterProp="children"
                                                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                            >
                                                {
                                                    Status.map((items, index) =>
                                                        <Option key={++index} value={items.id}>{items.name}</Option>
                                                    )
                                                }
                                            </Select>
                                        </Form.Item>
                                    </div>
                                </div>

                                <div className="col-md-12">
                                    <div className="gx-form-row0">
                                        <Form.Item
                                            name="work_day"
                                            label="Work Day"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Work Day is required."
                                                },
                                            ]}
                                            valuePropName="checked"
                                        >
                                            <Checkbox.Group style={{ width: '100%' }} defaultValue={days} >
                                                <Col span={8}>
                                                    <Checkbox value="Saturday">Saturday</Checkbox>
                                                </Col>
                                                <Col span={8}>
                                                    <Checkbox value="Sunday">Sunday</Checkbox>
                                                </Col>
                                                <Col span={8}>
                                                    <Checkbox value="Monday">Monday</Checkbox>
                                                </Col>
                                                <Col span={8}>
                                                    <Checkbox value="Tuesday">Tuesday</Checkbox>
                                                </Col>
                                                <Col span={8}>
                                                    <Checkbox value="Wednesday">Wednesday</Checkbox>
                                                </Col>
                                                <Col span={8}>
                                                    <Checkbox value="Thursday">Thursday</Checkbox>
                                                </Col>
                                                <Col span={8}>
                                                    <Checkbox value="Friday">Friday</Checkbox>
                                                </Col>
                                            </Checkbox.Group>
                                        </Form.Item>
                                    </div>
                                </div>

                            </div>

                <Row gutter={24}>
                    <Col lg={24} md={24} sm={12} xs={24} style={{textAlign: 'center'}}>
                        <Button type="primary" htmlType="submit">
                            Submit
                        </Button>
                        <Button type="info" onClick={() => history.goBack()} htmlType="back">Cancel</Button>
                    </Col>
                </Row>
            </Form>


        </Card>
    );
};

export default NewShift;