import React, {useState, useEffect} from "react";
import { Form, Row, Col, Input, Button ,Card, Table, Collapse} from 'antd';
import jsonfile from './company_type.json'
import {Link, useHistory, useRouteMatch} from "react-router-dom";
import {CisUI} from '../../../../util/CISUI';
import AdvancedSearch from '../../../../util/AdvancedSearch';
import ActionButton from '../../../../util/actionbutton';
import SmartDataTable from 'react-smart-data-table';
import 'react-smart-data-table/dist/react-smart-data-table.css';
import IntlMessages from "util/IntlMessages";
import Config from "../../../../util/config";

const Panel = Collapse.Panel;

const LoginHistory = () => {

    const [form] = Form.useForm();
    const history = useHistory();
    const [state,setState] = useState({
        reqURL: Config.apiserver+jsonfile.urls.list,
        filtervalue : '',
        subtitle: ''
    });

    const headers = {
        // _id: {
        //     text: '#',
        //     invisible: false,
        //     filterable: false,
        //     transform: (value) => `${++value}`,
        // },
        id: {
            text: <IntlMessages id="Sl NO"/>,
            sortable: true,
            filterable: true,
        },
        status: {
            text: <IntlMessages id="Status"/>,
            sortable: true,
            filterable: true,
            transform: (value, idx, row) => (
                CisUI().getStatusLabel(value)
            )
        },
        actions: {
            text: 'Actions',
            sortable: false,
            filterable: false,
            transform: (value, idx, row) => (
                <>
                    {ActionButton(row.id,jsonfile.urls,jsonfile.urls.list)}
                </>
            ),
        },
    };

    const orderedHeaders = jsonfile.listView.title;

    const getFields = () => {
        const count = expand ? 10 : 6;
        const children = [];
        const inputs = jsonfile.input;

        inputs.map((list, index) => {
            if(list.isFilterable == 1) {
                children.push(
                    <Col lg={6} md={6} sm={12} xs={24} key={index} style={{display: index < count ? 'block' : 'none'}}>
                        <div className="gx-form-row0">
                            <Form.Item
                                name={`${list.name}`}
                                label={`${list.label}`}
                                rules={[
                                    {
                                        required: true,
                                        message: "",
                                    },
                                ]}
                            >
                                <Input placeholder={list.name} />
                            </Form.Item>
                        </div>
                    </Col>,
                );
            }
        });
        return children;
    };

    const [expand, setExpand] = useState(false);

    const onFinish = values => {
        // console.log('Received values of form: ', values);
        const qsup = CisUI().getQueryStrings(values);
        history.push("?"+qsup);
        const newURL = Config.apiserver+jsonfile.urls.list+"/?"+qsup;
        console.log(newURL);
        setState({reqURL: newURL});
    };

    let  searchView = "";
    if(jsonfile.has_search) {
        searchView = <Card className="advance-search-card">
            <Collapse
                accordion
                expandIconPosition="right"
                defaultActiveKey={['1']} >
                <Panel header="Advance Search"  key="1">
                    <Form
                        key={5}
                        form={form}
                        {...CisUI().formItemLayout}
                        name="advanced_search"
                        className="ant-advanced-search-form"
                        onFinish={onFinish}
                    >
                        {AdvancedSearch(jsonfile.input)}
                    </Form>
                </Panel>
            </Collapse>
        </Card>
    }

    const getExtra = (
        <>
            {/*<Search*/}
            {/*    placeholder='Filter...'*/}
            {/*    name='filtervalue'*/}
            {/*    onSearch={handleOnFilter}*/}
            {/*    onChange={handleOnFilter}*/}
            {/*    style={{width: 200}}*/}
            {/*/>*/}
            {CisUI().listAction("../"+jsonfile.urls.add, "", state.reqURL, "data", 'company_type', jsonfile, state.subtitle, 'portrait')}
        </>
    );

    return (
        <>
            {searchView}
            {/*<Card title={jsonfile.title} extra={getExtra}>*/}
            {/*    <SmartDataTable*/}
            {/*        name='test-fake-table'*/}
            {/*        data={Config.apiserver+"company/alltype"}*/}
            {/*        dataRequestOptions={CisUI().HeaderRequest}*/}
            {/*        dataKey="data"*/}
            {/*        headers={headers}*/}
            {/*        orderedHeaders={orderedHeaders}*/}
            {/*        hideUnordered={CisUI().hideUnordered}*/}
            {/*        className={CisUI().sematicUI.table}*/}
            {/*        filterValue={filterValue}*/}
            {/*        perPage={CisUI().perPage}*/}
            {/*        sortable*/}
            {/*        withLinks*/}
            {/*        withHeader*/}
            {/*        loader={CisUI().loader}*/}
            {/*        parseBool={{*/}
            {/*            yesWord: 'Yes',*/}
            {/*            noWord: 'No',*/}
            {/*        }}*/}
            {/*        parseImg={{*/}
            {/*            style: {*/}
            {/*                border: '1px solid #ddd',*/}
            {/*                borderRadius: '50px',*/}
            {/*                padding: '3px',*/}
            {/*                width: '30px',*/}
            {/*                height: '30px'*/}
            {/*            },*/}
            {/*        }}*/}
            {/*        emptyTable={CisUI().emptyTable}*/}
            {/*    />*/}
            {/*</Card>*/}

            <Card title="Company Types" extra={getExtra}>
                <SmartDataTable
                    name='test-fake-table'
                    data={state.reqURL}
                    dataRequestOptions={CisUI().HeaderRequest}
                    dataKey="data"
                    headers={headers}
                    orderedHeaders={orderedHeaders}
                    hideUnordered={CisUI().hideUnordered}
                    className={CisUI().sematicUI.table}
                    perPage={CisUI().perPage}
                    sortable
                    withLinks
                    dynamic
                    withHeader
                    loader={CisUI().loader}
                    parseBool={{
                        yesWord: 'Yes',
                        noWord: 'No',
                    }}
                    parseImg={{
                        style: {
                            border: '1px solid #ddd',
                            borderRadius: '50px',
                            padding: '3px',
                            width: '30px',
                            height: '30px'
                        },
                    }}
                    emptyTable={CisUI().emptyTable}
                />
            </Card>
        </>
    );
};

export default LoginHistory;