import React, {useState, useEffect} from "react";
import {Form, Row, Col, Input, Button, Collapse, Card} from 'antd';
import jsonfile from './nation.json'
import {Link, useHistory, useRouteMatch} from "react-router-dom";
import {CisUI} from '../../../../util/CISUI';
import AdvancedSearch from '../../../../util/AdvancedSearch';
import ActionButton from '../../../../util/actionbutton';
import SmartDataTable from 'react-smart-data-table';
import 'react-smart-data-table/dist/react-smart-data-table.css';
import IntlMessages from "util/IntlMessages";
import Config from "../../../../util/config";
import SelectData from "../../../../util/selectdata";
import axios from '../../../../util/Api'
import Pdf from "react-to-pdf";
import { useTable, useExpanded } from 'react-table'
import styled from "styled-components";

const Search = Input.Search;
const Panel = Collapse.Panel;

const Styles = styled.div`
  padding: 1rem;

  table {
    border-spacing: 0;
    width: 100%;
    border: 1px solid black;

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid black;
      border-right: 1px solid black;
      text-align: right;

      :last-child {
        border-right: 0;
      }
    }
  }
  
  .table th {
    white-space: normal;
  }
  
  .table th:nth-child(1) {
    font-weight : bold;
    font-size: 18px !important;
  }
  
  .table td:nth-child(1) {
    font-weight : bold;
    font-size: 18px !important;
  }
  
  table td:last-child {
    font-weight : bold;
  }
  
  table tr:last-child td {
    font-weight : bold;
  }

  
  .table td:nth-child(2) {
    text-align: left;
  }
  
  .table th:nth-child(3) {
    text-align: left;
  }
  
  .table th:nth-child(2) {
    text-align: left;
  }
  
  .table td:nth-child(1) {
    text-align: left;
  }
  
  .table th:nth-child(1) {
    text-align: left;
  }
  
`

function Table({ columns: userColumns, data }) {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        state: { expanded },
    } = useTable(
        {
            columns: userColumns,
            data,
        },
        useExpanded // Use the useExpanded plugin hook
    )

    return (
        <>
            <table className="table table-striped table-bordered" {...getTableProps()}>
                <thead>
                {headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(column => (
                            <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                        ))}
                    </tr>
                ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                {rows.map((row, i) => {
                    prepareRow(row)
                    const type = row.original.type;
                    console.log(type);
                    return (
                        <tr {...row.getRowProps()}>
                            {row.cells.map(cell => {
                                //console.log("CellData :"+ JSON.stringify(cell));
                                if(cell.value > 0) {
                                    return <td {...cell.getCellProps()}>{CisUI().getCurrencyFormated1(cell.value)}</td>
                                }
                                else {
                                    if(row.original.type === 'District') {
                                        return <td {...cell.getCellProps()}><div style={{paddingLeft:'20px',fontWeight : 'bold'}}>{cell.render('Cell')}</div></td>
                                    }
                                    else if(row.original.type === 'Local') {
                                        return <td {...cell.getCellProps()}><div style={{paddingLeft:'40px'}}>{cell.render('Cell')}</div></td>
                                    }
                                    else {
                                        return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                    }
                                }
                            })}
                        </tr>
                    )
                })}
                </tbody>
            </table>
            {/*<br />*/}
            {/*<div>Showing the first 20 results of {rows.length} rows</div>*/}
            {/*      <pre>*/}
            {/*  /!*<code>{JSON.stringify({ expanded: expanded }, null, 2)}</code>*!/*/}
            {/*</pre>*/}
        </>
    )
}

const NationExpenseReport = () => {
    const value = 0;
    const ref = React.createRef();
    const formRef = React.createRef();

    const [state, setState] = useState({
        reqURL: Config.apiserver + jsonfile.urls.expensereport,
        filtervalue: '',
        reportTitle: '',
        reportData: [],
        regeionExpense: [],
        districtExpense: [],
        localExpense: [],
        regeionColumn: 2,
        districtColumn: 2,
        localColumn: 2,
    });

    const [report, setReport] = useState({});

    const history = useHistory();


    const [expand, setExpand] = useState(false);
    const [form] = Form.useForm();

    const onFinish = values => {
        console.log('Received values of form: ', values);
        let wkd = "";
        let wk = "";
        let dt = "";
        const qs = Object.keys(values).map(key => {
                if (key === 'date') {
                    wk = formRef.current.getFieldValue('date');
                    const dt = CisUI().ParseDateFormat(values[key]);
                    return `${key}=${dt}`
                } else if (key === 'month') {
                    wk = formRef.current.getFieldValue('month');
                    // console.log("Selected Week : "+ wk);
                    wkd = CisUI().getMonthTitle(formRef.current.getFieldValue('month'));
                    return `${key}=${wkd[1]}`
                } else {
                    return `${key}=${values[key]}`
                }
            }
        ).join('&');

        const qsup = qs.replaceAll("undefined", "");
        //reqURL = reqURL+"/?"+qsup;
        history.push("?" + qsup);
        const newURL = Config.apiserver + jsonfile.urls.expensereport + "?" + qsup;
        console.log("NEURL :" + newURL);
        console.log("Month : " + wkd);
        const WKR = CisUI().ParseDateFormat(wk);
        const reportTitle = "Between " + CisUI().DateFormat(WKR[0]) + " and " + CisUI().DateFormat(WKR[1]);

        const url = Config.apiserver + jsonfile.urls.expensereport;
        //const data = SelectData(url);

        document.getElementById("loader").style.display = "block";
        axios.get(newURL)
            .then((res) => {
                console.log(res.data);
                document.getElementById("loader").style.display = "none";
                if (res.data.status === 1) {
                    //console.log("Response : "+ res.data);
                    const data = res.data;
                    setState({
                        reportData: data.data,
                        // regeionExpense: data.regeionExpense,
                        // districtExpense: data.districtExpense,
                        // localExpense: data.localExpense,
                        // regeionColumn: (data.regeionExpense.length) + 2,
                        // districtColumn: (data.districtExpense.length) + 2,
                        // localColumn: (data.localExpense.length) + 2
                    })
                }
            })
            .catch((error) => {
                console.log(error);
                document.getElementById("loader").style.display = "none";
            });
    };

    const handleOnFilter = ({target: {name, value}}) => {
        //filterValue = value;
        console.log("filteringwith :" + value);
        setState({filtervalue: "test"});
    }

    let searchView = "";
    if (jsonfile.has_search) {
        searchView = <Card className="advance-search-card no-print">
            <Collapse
                accordion
                expandIconPosition="right"
                defaultActiveKey={['1']}>
                <Panel header="Advance Search" key="1">
                    <Form
                        key={5}
                        form={form}
                        ref={formRef}
                        {...CisUI().formItemLayout}
                        name="advanced_search"
                        className="ant-advanced-search-form"
                        onFinish={onFinish}
                    >
                        {AdvancedSearch([], "", 1)}
                    </Form>
                </Panel>
            </Collapse>
        </Card>
    }

    const getExtra = (
        <>
            {CisUI().listAction("../" + jsonfile.urls.add)}
        </>
    );

    //for ReactTable
    const columns = React.useMemo(
        () => [
            {
                // Build our expander column
                id: 'expander', // Make sure it has an ID
                Header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded }) => (
                    <span {...getToggleAllRowsExpandedProps()}>
            {isAllRowsExpanded ? '-' : '+'}
          </span>
                ),
                Cell: ({ row }) =>
                    // Use the row.canExpand and row.getToggleRowExpandedProps prop getter
                    // to build the toggle for expanding a row
                    row.canExpand ? (
                        <span
                            {...row.getToggleRowExpandedProps({
                                style: {
                                    // We can even use the row.depth property
                                    // and paddingLeft to indicate the depth
                                    // of the row
                                    paddingLeft: `${row.depth * 2}rem`,
                                },
                            })}
                        >
              {row.isExpanded ? '-' : '+'}
            </span>
                    ) : null,
            },
            {
                Header: 'Title',
                columns: [
                    {
                        Header: 'Region/District/Local',
                        accessor: 'name',
                    }
                ],
            },
            {
                Header: 'Expenses',
                //columns: state.headtitle
                columns: [{"Header":"51600-Office Telephone and Cable TV","accessor":"s51600"},{"Header":"51710-Electric,Gas, and Oil","accessor":"s51710"},{"Header":"52100-Car Running (Gas & Tolls)","accessor":"s52100"},{"Header":"52800-Mortgage\/Rent","accessor":"s52800"},{"Header":"53260-Postage","accessor":"s53260"},{"Header":"54010-Travel Expenses","accessor":"s54010"},{"Header":"54270-Presiding Elder's Allowance","accessor":"s54270"},{"Header":"55600-Insurance","accessor":"s55600"},{"Header":"55610-Auto Insurance","accessor":"s55610"},{"Header":"56000-Auto Maintenance & Repairs","accessor":"s56000"},{"Header":"51620-Telephone, Cable -Parsonage","accessor":"s51620"},{"Header":"51700-Utilities","accessor":"s51700"},{"Header":"51800-Cable TV","accessor":"s51800"},{"Header":"54200-Supports\/Allowances","accessor":"54200"},{"Header":"55620-Property Insurance","accessor":"s55620"},{"Header":"55640-Building Insurance","accessor":"s55640"},{"Header":"63350-Mission House Expenses","accessor":"s63350"},{"Header":"51610-Office Telephone and Cable TV","accessor":"s51610"},{"Header":"51715-Office Electric, Gas, and Oil","accessor":"s51715"},{"Header":"51725-Office Water Supply","accessor":"s51725"},{"Header":"52700-Car Note","accessor":"s52700"},{"Header":"52820-Office Mortgage\/Rent","accessor":"s52820"},{"Header":"53210-Office Supplies","accessor":"s53210"},{"Header":"63210-Office Building Repairs & Maintenance","accessor":"s63210"}]
            },
        ],
        []
    )


    const options = {
        orientation: 'landscape',
        unit: 'in',
        format: [4, 2]
    };

    return (
        <>
            {searchView}
            <Card id="reportview" extra={getExtra}>
                {CisUI().showLoading}
                <div ref={ref}>
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <h3>National Expense Report</h3>
                            <h2>{state.reportTitle}</h2>
                        </div>
                    </div>

                    <Styles>
                        <div style={{overflow: 'auto'}}>
                            <Table columns={columns} data={state.reportData} />
                        </div>
                    </Styles>

                </div>

            </Card>
        </>
    );
};

export default NationExpenseReport;