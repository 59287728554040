module.exports = {
  footerText: 'Copyright Creative IT Soft © 2021',
  apiserver: 'https://fmsstaginapi.yottaerp.com/api/',
  baseurl: 'https://fmsstaginapi.yottaerp.com',

  //apiserver: 'https://fmsapi.yottaerp.com/api/',
  //baseurl: 'https://fmsapi.yottaerp.com',
  // baseurl: 'http://localhost:3000/',
  // apiserver: 'http://localhost:8000/api/',
  logourl : '/assets/images/coplogo.png',
  print_logourl : '/assets/images/cop_nlogo.png',
  company_name : 'The Church of Pentecost U.S.A., Inc',
  address : 'Wayne, New Jersey, USA',
  city : 'Wayne',
  state : 'New Jersey',
  zipcode : '11111',
  phone : "",
  email : "info@copusa.org",
  website : "https://copusa.org"
}
