import React, { useState, useEffect } from 'react';
import {Form, Row, Col, Input, InputNumber, DatePicker, Button, Card, Select, Checkbox, Upload} from 'antd';
import 'moment/locale/es-us';
import {CisUI} from "../../../../util/CISUI";
import {useHistory} from "react-router-dom";
import InputFields from "../../../../util/InputField";
import SelectData from "../../../../util/selectdata";
import axios from "../../../../util/Api";



const { Option } = Select;
// const Option = Select.Option;
const {TextArea} = Input;

function handleChange(value) {
    // console.log(`selected ${value}`);
}
const BillInput = (polist,formRef) => {

    const [comp, setComp] = useState([]);
    const [vendor, setVendor] = useState([]);
    const [accounts, setAccount] = useState([]);
    const [payable, setPayable] = useState([]);
    const [fund, setFund] = useState([]);
    const [expense, setExpense] = useState([]);

    const handleChange = (e) => {
        setComp({
            [e.target.name]: e.target.value
        })
    }

    let Vendors = [];
    const Companies = SelectData("company/companylists");

    const [comapny, setCompany] = useState([]);
    const [local, setLocal] = useState([]);

    const onCompanyChange = value => {
        console.log("selected : "+ value);
        let fl = Companies.filter(item => item.id === value);
        if(fl.length > 0) {
            const company = fl[0];
            axios.get("billentrydata?com_id="+value)
                .then((res) => {
                    console.log(res.data);
                    if(res.data.status === 1) {
                        setVendor(res.data.vendor);
                        setAccount(res.data.account);
                        setPayable(res.data.payable);
                        setFund(res.data.fund);
                        setExpense(res.data.expense);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });

            formRef.current.setFieldsValue({
                vendor: "",
                accounts_payable: "",
                accounts_id: ""
            });
        }
    }

    return (
        <>
            <div className="row">
                <div className="col-md-4">
                    <Form.Item
                        name="po_number"
                        label="PO Number #"
                        initialValue={polist.po_number}
                        rules={[
                            {
                                required: false,
                                message: "Enter po number"
                            },
                        ]}
                    >
                        <Input value={polist.po_number} className="gx-mb-3 gx-w-100" placeholder="Enter po number"/>
                    </Form.Item>
                </div>

                <div className="col-md-4">
                    <Form.Item
                        name="date"
                        label="Date"
                        initialValue={polist.date}
                        rules={[
                            {
                                required: true,
                                message: "Select a date"
                            },
                        ]}
                    >
                        <DatePicker format={CisUI().dateFormat} className="gx-mb-3 gx-w-100" placeholder="Date"/>
                    </Form.Item>
                </div>

                <div className="col-md-4">
                    <Form.Item
                        name="due_date"
                        label="Due Date"
                        initialValue={polist.due_date}
                        rules={[
                            {
                                required: true,
                                message: "Select a due date"
                            },
                        ]}
                    >
                        <DatePicker format={CisUI().dateFormat} className="gx-mb-3 gx-w-100" placeholder="Due Date"/>
                    </Form.Item>
                </div>

                <div className="col-md-4">
                    <Form.Item
                        name="reference_no"
                        label="Reference#"
                        initialValue={polist.reference_no}
                        rules={[
                            {
                                required: false,
                                message: "Enter reference number"
                            },
                        ]}
                    >
                        <Input className="gx-mb-3 gx-w-100" placeholder="Enter a reference number"/>
                    </Form.Item>
                </div>
                <div className="col-md-4">
                    <Form.Item
                        name="company_id"
                        label="Company"
                        initialValue={polist.company_id}
                        rules={[
                            {
                                required: true,
                                message: "Select a company"
                            },
                        ]}
                    >
                        <Select
                            showSearch
                            placeholder="Select a company"
                            optionFilterProp="children"
                            onChange={onCompanyChange}
                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        >
                            {
                                Companies.map((items, index) =>
                                    <Option key={++index} value={items.id}>{items.name}</Option>
                                )
                            }
                        </Select>
                    </Form.Item>
                </div>

                <div className="col-md-4">
                    <Form.Item
                        name="vendor_id"
                        label="Vendor"
                        initialValue={polist.vendor_id}
                        rules={[
                            {
                                required: true,
                                message: "Select a vendor"
                            },
                        ]}
                    >
                        <Select
                            showSearch
                            placeholder="Select a vendor"
                            optionFilterProp="children"
                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        >
                            {
                                vendor.map((items, index) =>
                                    <Option key={++index} value={items.id}>{items.name}</Option>
                                )
                            }
                        </Select>
                    </Form.Item>
                </div>

                <div className="col-md-4">
                    <Form.Item
                        name="payable_id"
                        label="Accounts Payable"
                        initialValue={polist.payable_id}
                        rules={[
                            {
                                required: true,
                                message: "Select a accounts payable"
                            },
                        ]}
                    >
                        <Select
                            showSearch
                            placeholder="Select a payable account"
                            optionFilterProp="children"
                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        >
                            {
                                payable.map((items, index) =>
                                    <Option key={++index} value={items.id}>{items.name}</Option>
                                )
                            }
                        </Select>
                    </Form.Item>
                </div>

                <div className="col-md-4">
                    <Form.Item
                        name="account_id"
                        initialValue={polist.account_id}
                        label="Account"
                        rules={[
                            {
                                required: true,
                                message: "Select an account"
                            },
                        ]}
                    >
                        <Select
                            showSearch
                            placeholder="Select an account"
                            optionFilterProp="children"
                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        >
                            {
                                accounts.map((items, index) =>
                                    <Option key={++index} value={items.id}>{items.name}</Option>
                                )
                            }
                        </Select>
                    </Form.Item>
                </div>

                <div className="col-md-4">
                    <Form.Item
                        name="fund_program_id"
                        label="Select a Fund Program"
                        initialValue={polist.fund_program_id}
                        rules={[
                            {
                                required: false,
                                message: "Select a fund program"
                            },
                        ]}
                    >
                        <Select
                            showSearch
                            placeholder="Select a fund prgoram"
                            optionFilterProp="children"
                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        >
                            {
                                fund.map((items, index) =>
                                    <Option key={++index} value={items.id}>{items.name}</Option>
                                )
                            }
                        </Select>
                    </Form.Item>
                </div>

                <div className="col-md-4">
                    <Form.Item
                        name="func_expense_id"
                        label="Functional Expense"
                        initialValue={polist.func_expense_id}
                        rules={[
                            {
                                required: false,
                                message: "Select a functional expense"
                            },
                        ]}
                    >
                        <Select
                            showSearch
                            placeholder="Select a functional expense"
                            optionFilterProp="children"
                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        >
                            {
                                expense.map((items, index) =>
                                    <Option key={++index} value={items.id}>{items.name}</Option>
                                )
                            }
                        </Select>
                    </Form.Item>
                </div>

                <div className="col-md-4">
                    <Form.Item
                        name="amount"
                        label="Amount"
                        initialValue={polist.amount}
                        rules={[
                            {
                                required: true,
                                message: "Enter an amount here"
                            },
                        ]}
                    >
                        <Input placeholder="Enter an amount here"/>
                    </Form.Item>
                </div>

                <div className="col-md-4">
                    <Form.Item
                        name="description"
                        label="Description"
                        initialValue={polist.description}
                        rules={[
                            {
                                required: true,
                                message: "Enter description here"
                            },
                        ]}
                    >
                        <TextArea placeholder="Enter description here"/>
                    </Form.Item>
                </div>

                <div className="col-md-4">
                    <Form.Item
                        name="remark"
                        label="Remark/Memo"
                        initialValue={polist.remark}
                        rules={[
                            {
                                required: false,
                                message: "Enter something here"
                            },
                        ]}
                    >
                        <TextArea placeholder="Enter somthing ehre"/>
                    </Form.Item>
                </div>
            </div>
        </>
    );
}
export default BillInput;