import {Form, Card, notification, Input, Checkbox } from 'antd';
import React, {useState, useEffect} from "react";
import jsonfile from './vendor_info.json'
import {CisUI} from '../../../../util/CISUI'
import PostToApi from '../../../../util/PostToApi'
import InputFields from '../../../../util/InputField'
import 'react-smart-data-table/dist/react-smart-data-table.css'
import Config from "../../../../util/config";
import axios from "axios";
import {useHistory} from "react-router-dom";
import RemoteJson from "../../../../util/gerremotedata";

const NewVendorInfo = (props) => {

    const [loading, setLoading] = useState([]);

    // const handleChange=(e)=> {
    //     this.setState({
    //         [e.target.id]: e.target.value
    //     })
    // }


    const userID = props.match.params.id;
    let userData = [];
    // console.log("Userid : "+props.match.params.id);
    let isEdit = 0;
    if(userID > 0) {
        isEdit = 1;
    }
    let Title = jsonfile.addtitle;
    let endpoint = jsonfile.urls.add;
    let redirectto = "../"+jsonfile.urls.list;

    if(isEdit === 1 ) {
        userData = RemoteJson(jsonfile.urls.view+"/"+userID);
        Title = jsonfile.edittitle;
        endpoint = jsonfile.urls.edit+"/"+userID;
        redirectto = "../../"+jsonfile.urls.list;
    }


    const [form] = Form.useForm();
    const history = useHistory();

    function onchange(e) {
        const check = e.target.checked;
        let data = form.getFieldsValue([
            'billing_address_line_1',
            'billing_address_line_2',
            'billing_city',
            'billing_country',
            'billing_state',
            'billing_zipcode'
        ]);
        if(check)
        {
            form.setFieldsValue({
                'shipping_address_line_1' : data.billing_address_line_1,
                'shipping_address_line_2' : data.billing_address_line_2,
                'shipping_city' : data.billing_city,
                'shipping_country' : data.billing_country,
                'shipping_state' : data.billing_state,
                'shipping_zipcode' : data.billing_zipcode,
            });
        } else {
            form.setFieldsValue({
                'shipping_address_line_1' : "",
                'shipping_address_line_2' : "",
                'shipping_city' : "",
                'shipping_country' : "",
                'shipping_state' : "",
                'shipping_zipcode' : "",
            });
        }
    }

    const onFinish = (values) => {
        setLoading({loading:true})
        // console.log(values);

        axios.post(Config.apiserver + endpoint, values, CisUI().HeaderRequest)
            .then(res => {
                console.log(res)
                if(res.data.status === 1) {
                    notification.success({
                        message: 'Success',
                        type : "success",
                        description: res.data.msg
                    });
                    history.push(redirectto);
                }
                else {
                    notification.warning({
                        message: 'Alert',
                        type : "warning",
                        description: res.data.msg
                    });
                }
                setLoading({loading:false});
            })
            .catch(errors => {
                console.log(errors.response.data.errors);
                setLoading({loading:false});
            });
    };

    return (
        <Card title={Title}>
            {
                isEdit === 1
                    ? CisUI().addAction('../../'+jsonfile.urls.list)
                    : CisUI().addAction('../'+jsonfile.urls.list)
            }
            <Form
                form={form}
                {...CisUI().formItemLayout}
                name="input"
                className="ant-advanced-search-form"
                onFinish={onFinish}
                initialValues={{ remember: true }}
            >
                {InputFields(jsonfile.input,userData,isEdit,props, onchange)}
            </Form>
        </Card>
    );
};

export default NewVendorInfo;