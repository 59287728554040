import React from "react";
import {Alert, Card, Input, notification, Spin, Tag} from "antd";
import faker from 'faker'
import {Link} from "react-router-dom";
import 'react-smart-data-table/dist/react-smart-data-table.css'
import moment from "moment";
import Config from './config'
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import axios from './Api'
import jsPDF from 'jspdf'
import 'jspdf-autotable'
import { Document, Packer, Paragraph, Table, TableRow, TableCell,  TextRun } from "docx";



const Search = Input.Search;

const CisUI = () => {
    const sematicUI = {
        change: 'ui labeled secondary icon button',
        changeIcon: 'exchange icon',
        checkbox: 'ui toggle checkbox',
        deleteIcon: 'trash red icon',
        input: 'ui input',
        iconInput: 'ui icon input',
        labeledInput: 'ui right labeled input',
        loader: 'ui active inverted dimmer"',
        message: 'ui message',
        refresh: 'ui labeled primary icon button',
        refreshIcon: 'sync alternate icon',
        rowsIcon: 'numbered list icon',
        searchIcon: 'search icon',
        segment: 'ui segment',
        select: 'ui dropdown',
        table: 'ui compact selectable table',
    }

    const divider = <span style={{ display: 'inline-block', margin: '10px' }} />
    const filterValue = "";

    const getStatusLabel = (status) => {
        let statusLB = {status};
        if(status == 'Active') {
            statusLB = <Tag color="#34495e">{status}</Tag>
        }
        else if(status == 1) {
            statusLB = <Tag color="#34495e" >Active</Tag>
        }
        else if(status == 0) {
            statusLB = <Tag color="#750707" >Inactive</Tag>
        }
        else {
            statusLB = <Tag color="#750707" >{status}</Tag>
        }
        return statusLB;
    };

    const getOrderStatus = (status) => {
        let statusLB = {status};
        if(status === 'Active') {
            statusLB = <Tag color="#34495e">{status}</Tag>
        }
        else if(status === 1) {
            statusLB = <Tag color="#34495e" >Approved</Tag>
        }
        else if(status === 0) {
            statusLB = <Tag color="#750707" >Pending</Tag>
        }
        else if(status === 3) {
            statusLB = <Tag color="#750707" >Cancelled</Tag>
        }
        else {
            statusLB = <Tag color="#750707" >{status}</Tag>
        }
        return statusLB;
    };

    const print = (apiurl,datakey,filename) => {
       // window.print();
       window.open(Config.baseurl+"print.html?url="+window.btoa(apiurl)+"&filename="+filename+"&data="+datakey,"_blank");
    }


    //for excel data
    const exportToExcel = (apiData, fileName) => {
        const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        const fileExtension = ".xlsx";
        //console.log("File type "+fileExtension);
        document.getElementById("rploader").style.display = "inline";
        const ws = XLSX.utils.json_to_sheet(apiData);
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const data = new Blob([excelBuffer], { type: fileType });
        document.getElementById("rploader").style.display = "none";
        FileSaver.saveAs(data, fileName + fileExtension);
    };

    const getAPIData = (APIUrl,dataParameter,filename,fulljson) => {
        axios.get(APIUrl)
            .then((res) => {
                //console.log(res.data);
                if(res.data.status === 1) {
                    const data = res.data[dataParameter];
                    exportToExcel(generateObject(data,fulljson.listView.print),filename);
                    //console.log(JSON.stringify(data));
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    function createHeaders(keys) {
        var result = [];
        for (var i = 0; i < keys.length; i += 1) {
            result.push({
                id: keys[i],
                name: keys[i],
                prompt: keys[i],
                padding: 0,
                fontSize : '10px'
            });
        }
        return result;
    }

    const generateDatas = function(datav,headers) {
        var result = [];
        datav.map((x, i) => {
            //console.log(JSON.stringify(x));
            //var data = {};
            var data = [];
            for (var f = 0; f < headers.length; f += 1) {
                //data[headers[f]] = " "+x[headers[f]];
                data.push(x[headers[f]]);
            }
            //data.id = (i + 1).toString();
            result.push(Object.assign([], data));
        });
        //console.log(JSON.stringify(result));
        return result;

    };

    const generateObject = function(datav,headers) {
        var result = [];
        datav.map((x, i) => {
            //console.log(JSON.stringify(x));
            //var data = {};
            var data = [];
            for (var f = 0; f < headers.length; f += 1) {
                data[headers[f]] = " "+x[headers[f]];
                //data.push(x[headers[f]]);
            }
            result.push(Object.assign({}, data));
        });
        //console.log(JSON.stringify(result));
        return result;

    };

    const exportToDocs = (apiurl,datakey,filename,jsonfile) => {
        const table = new Table({
            rows: [
                new TableRow({
                    children: [
                        new TableCell({
                            children: [new Paragraph("hello")],
                        }),
                    ],
                }),
            ],
        });
        const buffer = Packer.toBuffer(table);
        const blob = new Blob([buffer], {type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document"});
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = 'my.docx';
        link.click();
    }

    const getUserInfo = (fld) => {
        const user = localStorage.getItem('userData');
        const uj = JSON.parse(user);
        return uj[fld];
    }

    const exportToPDF = (apiurl,datakey,filename,jsonfile,printmode) => {
        //var doc = new jsPDF('p', 'pt');
        //var URL  = Config.baseurl+"print.html?url="+window.btoa(apiurl)+"&data="+datakey;
        document.getElementById("rploader").style.display = "inline";
        axios.get(apiurl)
            .then((res) => {
                 console.log(res);
                //doc.html(res);
                var datav = res.data.data;
                var doc = new jsPDF({ putOnlyUsedFonts: true, orientation: printmode });
                //console.log(JSON.stringify(jsonfile.listView.title));
                //doc.table(10, 10, generateDatas(datav,jsonfile.listView.print), createHeaders(jsonfile.listView.print), { autoSize: true, padding: 2, fontSize: 10 });
                const pageWidth = doc.internal.pageSize.getWidth();
                const pageHeight = doc.internal.pageSize.getHeight();

                const marginX = (pageWidth - 80) / 2;
                const xOffset = (doc.internal.pageSize.width / 2);

                // const marginY = (pageHeight - 30) / 2;
                const user = localStorage.getItem('userData');
                const uj = JSON.parse(user);
                //console.log("Username : "+uj["name"]);

                doc.addImage(Config.print_logourl, "PNG", marginX, 5, 80, 27);
                doc.text(jsonfile.printtitle,xOffset,37, { align: 'center' });
                doc.setFontSize(8);
                doc.text("Printed By : "+uj["name"],20,45, { align: 'left' });
                doc.setFontSize(8);
                doc.text("Date/Time : "+moment().format("MM/DD/YYYY HH:mm"),20,50, { align: 'left' });
                //doc.text(0, 37, jsonfile.printtitle, 'center');
                doc.autoTable({
                    margin: { top: 53 },
                    didDrawPage: function (data) {
                        data.settings.margin.top = 10;
                    },
                    headStyles : { fillColor: [52, 73, 94] },
                    head: [jsonfile.listView.print_head],
                    body: generateDatas(datav,jsonfile.listView.print),
                })
                doc.setFontSize(8);
                doc.save(filename+'.pdf');
                document.getElementById("rploader").style.display = "none";
            })
            .catch((error) => {
                document.getElementById("rploader").style.display = "none";
                console.log(error);
            });
    }

    const ListActionButton = (url="",querystring="",apiURL="",dataParameter="",filename="",fullJson={}, subtitle="",printmode="portrait") =>
        (
            <div className="no-print">
                <a id="rploader" style={{width: '40px', height : '40px', display : 'none'}}>
                    <img src="/assets/images/loader.svg" style={{width: '40px', height : '40px'}} alt="loader" /> Please wait ...
                </a>
                <Link to={url+querystring} className="btn btn-text-adjust btn-circle-md" title="Add New">
                    <i className="fas fa-plus"/>
                </Link>
                <button className="btn btn-text-adjust btn-circle-md" title="Font Size Increase"><i
                    className="fas fa-font"/></button>
                <button className="btn btn-text-adjust btn-circle-md2" title="Font Size Decrease"><i
                    className="fas fa-font"/></button>
                <button className="btn btn-export-excel btn-circle-md" onClick={(e) => getAPIData(apiURL,dataParameter,filename,fullJson)} title="Export to Excel"><i
                    className="fas fa-file-excel"/></button>
                <button className="btn btn-export-pdf btn-circle-md" onClick={(e) => exportToPDF(apiURL,dataParameter,filename,fullJson,printmode)} title="Export to PDF"><i
                    className="fas fa-file-pdf"/></button>
                <button className="btn btn-export-word btn-circle-md" onClick={(e) => exportToDocs(apiURL,dataParameter)} title="Export MS Word"><i
                    className="fas fa-file-word"/></button>
                <button onClick={(e) => print(apiURL,dataParameter,filename)} className="btn btn-export-print btn-circle-md" title="Print"><i
                    className="fas fa-print"/></button>
            </div>
        );

    const ListActionButtonNoReport = (url="",querystring="",apiURL="",dataParameter="",filename="",fullJson={}, subtitle="",printmode="portrait") =>
        (
            <div className="no-print">
                <a id="rploader" style={{width: '40px', height : '40px', display : 'none'}}>
                    <img src="/assets/images/loader.svg" style={{width: '40px', height : '40px'}} alt="loader" /> Please wait ...
                </a>
                <Link to={url+querystring} className="btn btn-text-adjust btn-circle-md" title="Add New">
                    <i className="fas fa-plus"/>
                </Link>
            </div>
        );

    const AddActionButton = (url) => (<>
        <div className="row no-print">
            <div className="col-md-6">
            </div>
            <div className="col-md-6 right">
                <Link to={url} className="btn btn-text-adjust btn-circle-md" title="Font Size Increase">
                    <i className="fas fa-list"/>
                </Link>
            </div>
        </div>
    </>);


    const generateData = (numResults = 100) => {
        let total = numResults || 0
        if (typeof numResults === 'string') {
            total = parseInt(numResults, 10)
        }
        const data = []
        for (let i = 0; i < total; i += 1) {
            data.push({
                _id: i,
                address: {
                    city: faker.address.city(),
                    state: faker.address.state(),
                    country: faker.address.country(),
                },
                url: faker.internet.url(),
                isMarried: faker.random.boolean(),
                actions: null,
                avatar: '',
                fullName: faker.name.findName(),
                _username: faker.internet.userName(),
                password_: faker.internet.password(),
                'email.address': faker.internet.email(),
                phone_number: faker.phone.phoneNumber(),
            })
        }
        return data
    }

    const formItemLayout = {
        labelCol: {
            span: 24,
        },
        wrapperCol: {
            span: 24,
        },
    };

    const  hideUnordered =  false;
    const perPage = 50;

    const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'superadmin:123456'
    }

    const dateFormat = 'MM/DD/YYYY';

    const emptyTable = (
        <div className={sematicUI.message}>
            There is no data available to display.
        </div>
    )

    const createNotification = (type,message) => {
        return () => {
            switch (type) {
                case 'info':
                    notification.open({
                        message: 'Alert',
                        description: {message},
                    });
                    break;
                case 'success':
                    notification.open({
                        message: 'Success',
                        description: {message},
                    });
                    break;
                case 'warning':
                    notification.open({
                        message: 'Alert',
                        description: {message},
                    });
                    break;
                case 'error':
                    notification.open({
                        message: 'Error',
                        description: {message},
                    });
                    break;
                default:
                    notification.open({
                        message: 'Alert',
                        description: {message},
                    });
                    break;

            }
        };
    };

    const loadingMsg =(
        <Card title="Customize" className="gx-card">
            <Spin tip="Loading...">
                <Alert
                    message="Alert message title"
                    description="Further details about the context of this alert."
                    type="info"
                />
            </Spin>
        </Card>
    )

    const HeaderRequest = () => {
        const token = localStorage.getItem('token');
        return {headers : {Authorization : token}};
    }

    const CurrencyFormat = (x) => {
        let number = parseFloat(x).toFixed(2);
        return "$" + number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    const FullName = (fname, lname) => {
        return fname + " " + lname;
    }

    const DateFormat = (data) => {
        // return moment(data).format("MM/DD/YYYY");
        if(data !== null) {
            return moment(data).format("MM-DD-YYYY");
        }
    }

    const ParseDateFormat1 = (data) => {
        // return moment(data).format("MM/DD/YYYY");
        if(data !== null) {
            return moment(data[0]).format("MM-DD-YYYY")+","+moment(data[1]).format("MM-DD-YYYY");
        }
    }

    const DateFormatWithTime = (data) => {
        // return moment(data).format("MM/DD/YYYY hh:mm:ss");
        if(data !== null) {
            return moment(data).format("MM-DD-YYYY hh:mm:ss");
        }
    }

    const TimeFormat = (data) => {
        // return moment(data).format("MM/DD/YYYY hh:mm:ss");
        if(data !== null) {
            return moment(data);
        }
    }

    const loader = (
        <div className="loader-table">
            <img src="/assets/images/loader.svg" />
        </div>
    )

    const LoadingOn = () => {
        return <div className="loader-table">
            <img src="/assets/images/loader.svg" />
        </div>
    }

    const showLoading = (
        <div id="loader" className="loading_view" style={{display: 'none'}}>
            <div className="loading_center">
                <div className="loader-table">
                    <img style={{background : 'transparent'}} src="/assets/images/loader.svg" />
                </div>
                <h4 style={{textAlign: 'center'}}>Please wait...</h4>
            </div>
        </div>
    );

    const numberFormat = (value) =>
        new Intl.NumberFormat('en-us', {
            style: 'currency',
            currency: 'USD'
        }).format(value);

    const getCurrencyFormated = (amount) => {
        return <div style={{textAlign: 'right', marginRight:"30px"}}>{numberFormat(amount || 0)}</div>
    }

    const getCurrencyFormated1 = (amount) => {
        if(amount >= 0) {
            return numberFormat(amount || 0);
        }
        else {
            const amt = numberFormat(amount || 0);
            return "("+amt.replace("-","")+")";
        }
    }

    const getNumber = (amount) => {
        return new Intl.NumberFormat('en-us').format(amount);
    }

    const parseParams = (params = "") => {
        const rawParams = params.replace("?", "").split("&");
        const extractedParams = {};
        rawParams.forEach((item) => {
            item = item.split("=");
            extractedParams[item[0]] = item[1];
        });
        return extractedParams;
    };

    const getMonth = (month) => {
        if(month !== null) {
            moment.locale('en-gb');
            const  date = month.toString().substr(0, 4)+"-"+month.toString().substr(4, 2)+"-01";
            return moment(date).format("MMMM YYYY");
        }
    }

    const ParseDateFormat = (value) => {
        if(value === null || value === undefined) {
            return '';
        }
        else {
            const from = moment(value[0]).format("YYYY-MM-DD");
            const to = moment(value[1]).format("YYYY-MM-DD");
            return [from, to];
        }
    }

    const ParseDateFormatFromMonth = (value) => {
        if(value === null || value === undefined) {
            return '';
        }
        else {
            return  moment(value).format("YYYY-MM-01");
        }
    }

    const getWeekRange = (date) => {
        moment.locale('en');
        //const startdate = moment(date).clone().startOf('week').isoWeekday(0).format("MM-DD-YYYY");
        //const enddate = moment(date).clone().endOf('week').isoWeekday(0).format("MM-DD-YYYY");
        //return startdate+","+enddate;
        return moment(date).clone().startOf('week').format("YYYY-MM-DD");
    }

    const getWeekRangeLocal = (date) => {
        moment.locale('en');
        const from = moment(date).clone().startOf('week').format("MM/DD/YYYY");
        const to = moment(date).clone().endOf('week').format("MM/DD/YYYY");

        return [from,to];
    }

    const getMonthRangeLocal = (date) => {
        moment.locale('en');
        const from = moment(date).format("MM/01/YYYY");
        const to = moment(date).format("MM/31/YYYY");

        return [from,to];
    }

    const getMonthTitle = (date) => {
        moment.locale('en-gb');
        const title = moment(date).format("MMMM, YYYY");
        const month = moment(date, 'YYYY-MM-DD').toDate();

        return [title];
    }

    const getMonthRange = (date) => {
        const from = moment(date).format("YYYY-MM-01");
        const to = moment(date).format("YYYY-MM-31");

        return [from,to];
    }

    const getQueryStrings = (values) => {
        const qs = Object.keys(values).map(key =>
            {
                if(values[key] === undefined || values[key] === "" || values[key] === null) {
                    return `${key}=${""}`
                }
                else {
                    console.log(key+":"+values[key]);
                    if (key === 'date') {
                        return `${key}=${CisUI().ParseDateFormat(values[key])}`
                    } else {
                        return `${key}=${values[key]}`
                    }
                }
            }
        ).join('&');

        return qs.replaceAll("undefined", "");
    }

    const getBillSatus = (statuscode) => {
        let sts = <Tag color="#34495e">Pending</Tag>
        if(statuscode === 0) {
            sts =  <Tag color="#34495e">Pending</Tag>
        }
        else if(statuscode === 1) {
            sts = <Tag color="#34495e">Approved</Tag>
        }
        else if(statuscode === 3) {
            sts = <Tag color="#34495e">Reject</Tag>
        }
       return sts;
    }

    return {
        loader : loader,
        emptyTable : emptyTable,
        hideUnordered : hideUnordered,
        perPage : perPage,
        loadingMsg : loadingMsg,
        sematicUI : sematicUI,
        formItemLayout : formItemLayout,
        headers : headers,
        createNotification : createNotification,
        listAction : ListActionButton,
        listActionNoReport : ListActionButtonNoReport,
        addAction : AddActionButton,
        getStatusLabel : getStatusLabel,
        getBillSatus : getBillSatus,
        getOrderStatus : getOrderStatus,
        generateData : generateData(),
        HeaderRequest : HeaderRequest(),
        CurrencyFormat : CurrencyFormat,
        FullName:FullName,
        DateFormat : DateFormat,
        DateFormatWithTime : DateFormatWithTime,
        dateFormat: dateFormat,
        LoadingOn: LoadingOn,
        getCurrency: getCurrencyFormated,
        getMonth: getMonth,
        ParseDateFormat: ParseDateFormat,
        ParseDateFormatFromMonth: ParseDateFormatFromMonth,
        getWeekRange: getWeekRange,
        getWeekRangeLocal: getWeekRangeLocal,
        getCurrencyFormated1: getCurrencyFormated1,
        getNumber: getNumber,
        getMonthRangeLocal: getMonthRangeLocal,
        getMonthRange: getMonthRange,
        getMonthTitle: getMonthTitle,
        showLoading: showLoading,
        parseParams : parseParams,
        getQueryStrings : getQueryStrings,
        TimeFormat: TimeFormat,
        getUserInfo: getUserInfo
    }
}


const generateData = (numResults = 100) => {
    let total = numResults || 0
    if (typeof numResults === 'string') {
        total = parseInt(numResults, 10)
    }
    const data = []
    for (let i = 0; i < total; i += 1) {
        data.push({
            _id: i,
            address: {
                city: faker.address.city(),
                state: faker.address.state(),
                country: faker.address.country(),
            },
            url: faker.internet.url(),
            isMarried: faker.random.boolean(),
            actions: null,
            avatar: '',
            fullName: faker.name.findName(),
            _username: faker.internet.userName(),
            password_: faker.internet.password(),
            'email.address': faker.internet.email(),
            phone_number: faker.phone.phoneNumber(),
        })
    }
    return data
}

export {
    CisUI,
    generateData
}