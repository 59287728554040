import React, {useState, useEffect} from "react";
import { Form, Row, Col, Input, Button, Collapse ,Card, Table} from 'antd';
import jsonfile from './localexpenses.json'
import {Link, useHistory, useRouteMatch} from "react-router-dom";
import {CisUI} from '../../../../util/CISUI';
import AdvancedSearch from '../../../../util/AdvancedSearch';
import ActionButton from '../../../../util/actionbutton';
import SmartDataTable from 'react-smart-data-table';
import 'react-smart-data-table/dist/react-smart-data-table.css';
import IntlMessages from "util/IntlMessages";
import Config from "../../../../util/config";
import SelectData from "../../../../util/selectdata";
import axios from '../../../../util/Api'

const Search = Input.Search;
const Panel = Collapse.Panel;

const LocalMonthlyReport = (props) => {
    const value = 0;
    const formRef = React.createRef();

    const qst = CisUI().parseParams(props?.location?.search);
    const localID = qst["local_id"];
    const month = qst["month"];

    let reportTitle = 'Monthly Report';
    if(month !== null) {
        const WKR = CisUI().getMonthTitle(month);
        reportTitle = "Monthly Report For the month of "+WKR[0];
    }

    const [state,setState] = useState({
        reqURL: Config.apiserver+jsonfile.urls.monthly+"?local_id="+localID+"&month="+month,
        filtervalue : '',
        reportTitle : 'Monthly Report',
        reportData : [],
        totalContribution : 0,
        month : "",
        region : "",
        district : "",
        lcoal : "",
        contribution : [],
        expenses : [],
        totalExpenses : 0,
        netTitheAfterExpense : 0,
        ddf : 0,
        NetTitheToDistrict : 0,
        missionOffering : 0,
        MinistryIncome : 0,
        Mckeowns : 0,
        InternalMissions : 0,
        NationalPrayer : 0,
        PENSOS : 0,
        netCashToDistrict : 0,
    });

    const executeReportAPI = (newURL,month) => {
        const WKR = CisUI().getMonthTitle(month);
        document.getElementById("loader").style.display = "block";
        axios.get(newURL)
            .then((res) => {
                // console.log(res.data);
                document.getElementById("loader").style.display = "none";
                if(res.data.status === 1) {
                    //console.log("Response : "+ res.data);
                    const data = res.data.data;
                    setState({
                        contribution : data.contribution,
                        totalContribution : data.totalContribution,
                        local : data.local,
                        district : data.district,
                        region : data.region,
                        reportTitle : reportTitle,
                        month: WKR[0],
                        expenses : data.expenses,
                        totalExpenses : data.totalExpenses,
                        netTitheAfterExpense : data.netTitheAfterExpense,
                        ddf : data.ddf,
                        NetTitheToDistrict : data.NetTitheToDistrict,
                        missionOffering : data.missionOffering,
                        MinistryIncome : data.MinistryIncome,
                        Mckeowns : data.Mckeowns,
                        InternalMissions : data.InternalMissions,
                        NationalPrayer : data.NationalPrayer,
                        PENSOS : data.PENSOS,
                        netCashToDistrict : data.netCashToDistrict,
                    })
                }
            })
            .catch((error) => {
                console.log(error);
                document.getElementById("loader").style.display = "none";
            });
    }

    if(localID > 0) {
        const WKR = CisUI().getMonthTitle(month);
        reportTitle = "Monthly Report For the month of "+WKR[0];
        //executeReportAPI(state.reqURL,month);
    }

    const history = useHistory();

    const headers = {
        // _id: {
        //     text: '#',
        //     invisible: false,
        //     filterable: false,
        //     transform: (value) => `${++value}`,
        // },
        amount: {
            text: <IntlMessages id="Amount"/>,
            sortable: true,
            filterable: true,
            transform: (value, idx, row) => (
                CisUI().getCurrency(value)
            )
        }
    };

    const orderedHeaders = jsonfile.listView.weeklytitle;

    const getFields = () => {
        const count = expand ? 10 : 6;
        const children = [];
        const inputs = jsonfile.input;

        inputs.map((list, index) => {
            if(list.isFilterable == 1) {
                children.push(
                    <Col lg={6} md={6} sm={12} xs={24} key={index} style={{display: index < count ? 'block' : 'none'}}>
                        <div className="gx-form-row0">
                            <Form.Item
                                name={`${list.name}`}
                                label={`${list.label}`}
                                rules={[
                                    {
                                        required: true,
                                        message: "",
                                    },
                                ]}
                            >
                                <Input placeholder={list.name} />
                            </Form.Item>
                        </div>
                    </Col>,
                );
            }
        });
        return children;
    };

    const [expand, setExpand] = useState(false);
    const [form] = Form.useForm();

    const onFinish = values => {
        console.log('Received values of form: ', values);
        let wkd = "";
        let wk = "";
        const qs = Object.keys(values).map(key =>
            {
                if(key === 'date') {
                    return `${key}=${CisUI().ParseDateFormat(values[key])}`
                }
                else if(key === 'month') {
                    wk = formRef.current.getFieldValue('month');
                    // console.log("Selected Week : "+ wk);
                    const mnts = CisUI().ParseDateFormatFromMonth(wk);
                    //console.log(mnts);
                    wkd = mnts;
                    return `${key}=${mnts}`
                }
                else {
                    return `${key}=${values[key]}`
                }
            }
        ).join('&');

        const qsup = qs.replaceAll("undefined","");
        //reqURL = reqURL+"/?"+qsup;
        history.push("?"+qsup);
        const newURL = Config.apiserver+jsonfile.urls.monthly+"?"+qsup;
        console.log("NEURL :" + newURL);
        console.log("Month : "+ wkd);
        const WKR = CisUI().getMonthTitle(wk);
        const reportTitle = "Monthly Report For the month of "+WKR[0];

        const url = Config.apiserver+jsonfile.urls.monthly;
        //const data = SelectData(url);

        // setState({
        //     reqURL: newURL,
        //     reportTitle: reportTitle
        // });

        executeReportAPI(newURL);

    };



    const handleOnFilter = ({ target: { name, value } }) => {
        //filterValue = value;
        console.log("filteringwith :"+ value);
        setState({filtervalue : "test"});
    }

    let  searchView = "";
    if(jsonfile.has_search) {
        searchView = <Card className="advance-search-card no-print">
            <Collapse
                accordion
                expandIconPosition="right"
                defaultActiveKey={['1']} >
                <Panel header="Advance Search"  key="1">
                    <Form
                        key={5}
                        form={form}
                        ref={formRef}
                        {...CisUI().formItemLayout}
                        name="advanced_search"
                        className="ant-advanced-search-form"
                        onFinish={onFinish}
                    >
                        {AdvancedSearch(jsonfile.input,"",0,'month')}
                    </Form>
                </Panel>
            </Collapse>
        </Card>
    }

    const getExtra = (
        <>
            {CisUI().listAction("../"+jsonfile.urls.add)}
        </>
    );

    const ReportData = state.reportData;
    const expenseTypes = SelectData("donation/expensetype?area=6");

    return (
        <>
            {searchView}
            <Card extra={getExtra}>
                {CisUI().showLoading}
                <div className="row">
                    <div className="col-md-12 text-center">
                        <h3>Local Report</h3>
                        <h2>{state.reportTitle}</h2>
                    </div>
                    <div className="col-md-6">
                        <strong>Month :</strong> {state.month}
                    </div>
                    <div className="col-md-6 text-right">
                        <table width={400}>
                            <tr>
                                <th style={{textAlign: 'left'}}>Region</th>
                                <td>: {state.region}</td>
                            </tr>
                            <tr>
                                <th style={{textAlign: 'left'}}>Dsitrict</th>
                                <td>: {state.district}</td>
                            </tr>
                            <tr>
                                <th style={{textAlign: 'left'}}>Assembly</th>
                                <td>: {state.local}</td>
                            </tr>
                        </table>
                    </div>
                </div>
                <table className="table table-bordered">
                    <thead>
                        <tr>
                            <th>CONTRIBUTIONS RECEIVED</th>
                            <th style={{textAlign : 'right'}}>Amount</th>
                            <th style={{textAlign : 'right'}}>Amount</th>
                        </tr>
                    </thead>
                    <tbody>
                    {state.contribution.map((x, i) => {
                        return (
                            <>
                                <tr>
                                    <td>{x.title}</td>
                                    <td style={{textAlign : 'right'}}></td>
                                    <td style={{textAlign : 'right'}}>{CisUI().getCurrencyFormated1(x.amount)}</td>
                                </tr>
                            </>
                        )})}
                        <tr>
                            <th>TOTAL CONTRIBUTION RECEIVED (Gross Tithe & Offering)	</th>
                            <th style={{textAlign : 'right'}}></th>
                            <th style={{textAlign : 'right'}}>{CisUI().getCurrencyFormated1(state.totalContribution)}</th>
                        </tr>

                        <tr>
                            <th>ALLOWABLE LOCAL EXPENSES	</th>
                            <th style={{textAlign : 'right'}}></th>
                            <th style={{textAlign : 'right'}}></th>
                        </tr>
                        {
                            state.expenses.map((items, index) =>
                                <tr>
                                    <td>{items.title}</td>
                                    <td style={{textAlign : 'right'}}>{CisUI().getCurrencyFormated1(items.amount)}</td>
                                    <td style={{textAlign : 'right'}}></td>
                                </tr>
                            )
                        }
                        <tr>
                            <th>Total Allowable Local Expenses</th>
                            <th style={{textAlign : 'right'}}></th>
                            <th style={{textAlign : 'right'}}>({CisUI().getCurrencyFormated1(state.totalExpenses)})</th>
                        </tr>

                        <tr>
                            <th colSpan={2} style={{textAlign : 'right'}}>Net Tithe After Local Allowable Expenses</th>
                            <th style={{textAlign : 'right'}}>{CisUI().getCurrencyFormated1(state.netTitheAfterExpense)}</th>
                        </tr>
                        <tr>
                            <th colSpan={2} style={{textAlign : 'right'}}>Less Local Development Fund ( 20%)</th>
                            <th style={{textAlign : 'right'}}>({CisUI().getCurrencyFormated1(state.ddf)})</th>
                        </tr>
                        <tr>
                            <th colSpan={2} style={{textAlign : 'right'}}>Net Tithe to District</th>
                            <th style={{textAlign : 'right'}}>{CisUI().getCurrencyFormated1(state.NetTitheToDistrict)}</th>
                        </tr>
                        <tr>
                            <th colSpan={2} style={{textAlign : 'right'}}>Add : Missions Offering</th>
                            <th style={{textAlign : 'right'}}>{CisUI().getCurrencyFormated1(state.missionOffering)}</th>
                        </tr>
                        <tr>
                            <th colSpan={2} style={{textAlign : 'right'}}><Link to="ministryincome">Add : Ministry Income</Link></th>
                            <th style={{textAlign : 'right'}}>{CisUI().getCurrencyFormated1(state.MinistryIncome)}</th>
                        </tr>
                        <tr>
                            <th colSpan={2} style={{textAlign : 'right'}}>Add : Mckeowns</th>
                            <th style={{textAlign : 'right'}}>{CisUI().getCurrencyFormated1(state.Mckeowns)}</th>
                        </tr>
                        <tr>
                            <th colSpan={2} style={{textAlign : 'right'}}>Add : Internal Missions</th>
                            <th style={{textAlign : 'right'}}>{CisUI().getCurrencyFormated1(state.InternalMissions)}</th>
                        </tr>
                        <tr>
                            <th colSpan={2} style={{textAlign : 'right'}}>Add : National Prayer</th>
                            <th style={{textAlign : 'right'}}>{CisUI().getCurrencyFormated1(state.NationalPrayer)}</th>
                        </tr>
                        <tr>
                            <th colSpan={2} style={{textAlign : 'right'}}>Add : PENSOS</th>
                            <th style={{textAlign : 'right'}}>{CisUI().getCurrencyFormated1(state.PENSOS)}</th>
                        </tr>
                        {/*<tr>*/}
                        {/*    <th colSpan={2} style={{textAlign : 'right'}}>Add : Other Funds(Mckeowns, Internal Missions, National Prayer, & PENSOS)</th>*/}
                        {/*    <th style={{textAlign : 'right'}}>{CisUI().getCurrencyFormated1(0)}</th>*/}
                        {/*</tr>*/}
                        <tr>
                            <th colSpan={2} style={{textAlign : 'right'}}>NET CASH SENT TO DISTRICT	</th>
                            <th style={{textAlign : 'right'}}>{CisUI().getCurrencyFormated1(state.netCashToDistrict)}</th>
                        </tr>


                    </tbody>
                </table>


            </Card>
        </>
    );
};

export default LocalMonthlyReport;