import React, {useState, useEffect} from "react";
import { Form, Row, Col, Input, Button, Collapse ,Card, Table} from 'antd';
import jsonfile from './localexpenses.json'
import {Link, useHistory, useRouteMatch} from "react-router-dom";
import {CisUI} from '../../../../util/CISUI';
import AdvancedSearch from '../../../../util/AdvancedSearch';
import ActionButton from '../../../../util/actionbutton';
import SmartDataTable from 'react-smart-data-table';
import 'react-smart-data-table/dist/react-smart-data-table.css';
import IntlMessages from "util/IntlMessages";
import Config from "../../../../util/config";
import SelectData from "../../../../util/selectdata";
import axios from '../../../../util/Api'

const Search = Input.Search;
const Panel = Collapse.Panel;

const LocalSummaryReport = () => {
    const value = 0;
    const formRef = React.createRef();
    const [state,setState] = useState({
        reqURL: Config.apiserver+jsonfile.urls.summary,
        filtervalue : '',
        reportTitle : '',
        reportData : [],
        totalContribution : 0,
        month : "",
        region : "",
        district : "",
        contribution : [],
        expenses : [],
        totalExpenses : 0,
        netTitheAfterExpense : 0,
        ddf : 0,
        NetTitheToDistrict : 0,
        missionOffering : 0,
        MinistryIncome : 0,
        Mckeowns : 0,
        InternalMissions : 0,
        NationalPrayer : 0,
        PENSOS : 0,
        netCashToDistrict : 0,
    });

    const [report,setReport] = useState({});

    const history = useHistory();


    const [expand, setExpand] = useState(false);
    const [form] = Form.useForm();

    const onFinish = values => {
        console.log('Received values of form: ', values);
        let wkd = "";
        let wk = "";
        let dt = "";
        const qs = Object.keys(values).map(key =>
            {
                if(key === 'date') {
                    wk = formRef.current.getFieldValue('date');
                    const dt = CisUI().ParseDateFormat(values[key]);
                    return `${key}=${dt}`
                }
                else if(key === 'month') {
                    wk = formRef.current.getFieldValue('month');
                    // console.log("Selected Week : "+ wk);
                    wkd = CisUI().getMonthTitle(formRef.current.getFieldValue('month'));
                    return `${key}=${wkd[1]}`
                }
                else {
                    return `${key}=${values[key]}`
                }
            }
        ).join('&');

        const qsup = qs.replaceAll("undefined","");
        //reqURL = reqURL+"/?"+qsup;
        history.push("?"+qsup);
        const newURL = Config.apiserver+jsonfile.urls.summary+"?"+qsup;
        console.log("NEURL :" + newURL);
        console.log("Month : "+ wkd);
        const WKR = CisUI().ParseDateFormat(wk);
        const reportTitle = "Between "+CisUI().DateFormat(WKR[0])+" and "+ CisUI().DateFormat(WKR[1]);

        const url = Config.apiserver+jsonfile.urls.summary;
        //const data = SelectData(url);

        // setState({
        //     reqURL: newURL,
        //     reportTitle: reportTitle
        // });
        document.getElementById("loader").style.display = "block";
        axios.get(newURL)
            .then((res) => {
                // console.log(res.data);
                document.getElementById("loader").style.display = "none";
                if(res.data.status === 1) {
                    //console.log("Response : "+ res.data);
                    const data = res.data.data;
                    setState({
                        contribution : data.contribution,
                        totalContribution : data.totalContribution,
                        district : data.district,
                        region : data.region,
                        reportTitle : reportTitle,
                        month: WKR[0],
                        expenses : data.expenses,
                        totalExpenses : data.totalExpenses,
                        netTitheAfterExpense : data.netTitheAfterExpense,
                        ddf : data.ddf,
                        NetTitheToDistrict : data.NetTitheToDistrict,
                        missionOffering : data.missionOffering,
                        MinistryIncome : data.MinistryIncome,
                        Mckeowns : data.Mckeowns,
                        InternalMissions : data.InternalMissions,
                        NationalPrayer : data.NationalPrayer,
                        PENSOS : data.PENSOS,
                        netCashToDistrict : data.netCashToDistrict,
                    })
                }
            })
            .catch((error) => {
                console.log(error);
                document.getElementById("loader").style.display = "none";
            });
    };

    const handleOnFilter = ({ target: { name, value } }) => {
        //filterValue = value;
        console.log("filteringwith :"+ value);
        setState({filtervalue : "test"});
    }

    let  searchView = "";
    if(jsonfile.has_search) {
        searchView = <Card className="advance-search-card no-print">
            <Collapse
                accordion
                expandIconPosition="right"
                defaultActiveKey={['1']} >
                <Panel header="Advance Search"  key="1">
                    <Form
                        key={5}
                        form={form}
                        ref={formRef}
                        {...CisUI().formItemLayout}
                        name="advanced_search"
                        className="ant-advanced-search-form"
                        onFinish={onFinish}
                    >
                        {AdvancedSearch(jsonfile.input,"",1,'date')}
                    </Form>
                </Panel>
            </Collapse>
        </Card>
    }

    const getExtra = (
        <>
            {CisUI().listAction("../"+jsonfile.urls.add)}
        </>
    );

    const ReportData = state.reportData;
    const expenseTypes = SelectData("donation/expensetype?area=6");

    return (
        <>
            {searchView}
            <Card extra={getExtra}>
                {CisUI().showLoading}
                <div className="row">
                    <div className="col-md-12 text-center">
                        <h3>District Summary Report</h3>
                        <h2>{state.reportTitle}</h2>
                    </div>
                    <div className="col-md-6">
                        <table>
                            <tr>
                                <th style={{textAlign: 'right'}}>Region :</th>
                                <td>{state.region}</td>
                            </tr>
                            <tr>
                                <th style={{textAlign: 'right'}}>Dsitrict :</th>
                                <td>{state.district}</td>
                            </tr>
                        </table>
                    </div>
                </div>
                <table className="table table-bordered">
                    <thead>
                    <tr>
                        <th>Title</th>
                        <th style={{textAlign : 'right'}}>Amount</th>
                        <th style={{textAlign : 'right'}}>Amount</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <th>TOTAL CONTRIBUTION RECEIVED (Gross Tithe & Offering)	</th>
                        <th style={{textAlign : 'right'}}></th>
                        <th style={{textAlign : 'right'}}>{CisUI().getCurrencyFormated1(state.totalContribution)}</th>
                    </tr>

                    <tr>
                        <th>ALLOWABLE DISTRICT EXPENSES	</th>
                        <th style={{textAlign : 'right'}}></th>
                        <th style={{textAlign : 'right'}}></th>
                    </tr>
                    {
                        state.expenses.map((items, index) =>
                            <tr>
                                <td>{items.title}</td>
                                <td style={{textAlign : 'right'}}>{CisUI().getCurrencyFormated1(items.amount)}</td>
                                <td style={{textAlign : 'right'}}></td>
                            </tr>
                        )
                    }
                    <tr>
                        <th>Total Allowable District Expenses</th>
                        <th style={{textAlign : 'right'}}></th>
                        <th style={{textAlign : 'right'}}>({CisUI().getCurrencyFormated1(state.totalExpenses)})</th>
                    </tr>

                    <tr>
                        <th colSpan={2} style={{textAlign : 'right'}}>Net Tithe After Local Allowable Expenses</th>
                        <th style={{textAlign : 'right'}}>{CisUI().getCurrencyFormated1(state.netTitheAfterExpense)}</th>
                    </tr>
                    <tr>
                        <th colSpan={2} style={{textAlign : 'right'}}>Less: District Development Fund ( 5%)</th>
                        <th style={{textAlign : 'right'}}>({CisUI().getCurrencyFormated1(state.ddf)})</th>
                    </tr>
                    <tr>
                        <th colSpan={2} style={{textAlign : 'right'}}>Net Tithe to Region</th>
                        <th style={{textAlign : 'right'}}>{CisUI().getCurrencyFormated1(state.NetTitheToDistrict)}</th>
                    </tr>
                    <tr>
                        <th colSpan={2} style={{textAlign : 'right'}}>Add : Missions Offering</th>
                        <th style={{textAlign : 'right'}}>{CisUI().getCurrencyFormated1(state.missionOffering)}</th>
                    </tr>
                    <tr>
                        <th colSpan={2} style={{textAlign : 'right'}}><a href="#">Add : Ministry Income</a></th>
                        <th style={{textAlign : 'right'}}>{CisUI().getCurrencyFormated1(state.MinistryIncome)}</th>
                    </tr>
                    <tr>
                        <th colSpan={2} style={{textAlign : 'right'}}>Add : Mckeowns</th>
                        <th style={{textAlign : 'right'}}>{CisUI().getCurrencyFormated1(state.Mckeowns)}</th>
                    </tr>
                    <tr>
                        <th colSpan={2} style={{textAlign : 'right'}}>Add : Internal Missions</th>
                        <th style={{textAlign : 'right'}}>{CisUI().getCurrencyFormated1(state.InternalMissions)}</th>
                    </tr>
                    <tr>
                        <th colSpan={2} style={{textAlign : 'right'}}>Add : National Prayer</th>
                        <th style={{textAlign : 'right'}}>{CisUI().getCurrencyFormated1(state.NationalPrayer)}</th>
                    </tr>
                    <tr>
                        <th colSpan={2} style={{textAlign : 'right'}}>Add : PENSOS</th>
                        <th style={{textAlign : 'right'}}>{CisUI().getCurrencyFormated1(state.PENSOS)}</th>
                    </tr>
                    {/*<tr>*/}
                    {/*    <th colSpan={2} style={{textAlign : 'right'}}>Add : Other Funds(Mckeowns, Internal Missions, National Prayer, & PENSOS)</th>*/}
                    {/*    <th style={{textAlign : 'right'}}>{CisUI().getCurrencyFormated1(0)}</th>*/}
                    {/*</tr>*/}
                    <tr>
                        <th colSpan={2} style={{textAlign : 'right'}}>NET CASH SENT TO REGION	</th>
                        <th style={{textAlign : 'right'}}>{CisUI().getCurrencyFormated1(state.netCashToDistrict)}</th>
                    </tr>


                    </tbody>
                </table>


            </Card>
        </>
    );
};

export default LocalSummaryReport;