import React, {useState, useEffect} from "react";
import { Form, Row, Col, Input, Button ,Card, Collapse, Table} from 'antd';
import jsonfile from './loginhistory.json';
import {Link, useHistory, useRouteMatch} from "react-router-dom";
import {CisUI} from '../../../../util/CISUI';
import AdvancedSearch from '../../../../util/AdvancedSearch';
import SmartDataTable from 'react-smart-data-table';
import 'react-smart-data-table/dist/react-smart-data-table.css';
import IntlMessages from "util/IntlMessages";
import Config from "../../../../util/config";

const Search = Input.Search;
const Panel = Collapse.Panel;
const Meta = Card.Meta;

const LoginHistory = () => {
    const value = 0;
    const history = useHistory();
    const [state,setState] = useState({
        reqURL: Config.apiserver+jsonfile.urls.list,
        filtervalue : '',
        subtitle:''
    });
    useEffect(() => {
        setState({
            reqURL: Config.apiserver+jsonfile.urls.list,
        });
    }, []);
    const headers = {
        // _id: {
        //     text: '#',
        //     invisible: false,
        //     filterable: false,
        //     transform: (value) => `${++value}`,
        // },
        id: {
            text: <IntlMessages id="Sl NO"/>,
            sortable: true,
            filterable: true,
        },
        user_name: {
            text: <IntlMessages id="User Name"/>,
            sortable: true,
            filterable: true,
        },
        login_source: {
            text: <IntlMessages id="Login Source"/>,
            sortable: true,
            filterable: true,
        },
        login_time: {
            text: <IntlMessages id="Login Time"/>,
            sortable: true,
            filterable: true,
            transform: (value) => (
                CisUI().DateFormatWithTime(value)
            )
        },
        hit_time: {
            text: <IntlMessages id="Hit Time"/>,
            sortable: true,
            filterable: true,
            transform: (value) => (
                CisUI().DateFormatWithTime(value)
            )
        },
        ip: {
            text: <IntlMessages id="IP"/>,
            sortable: true,
            filterable: true,
        },
        logout_time: {
            text: <IntlMessages id="Logout Time"/>,
            sortable: true,
            filterable: true,
            transform: (value) => (
                CisUI().DateFormatWithTime(value)
            )
        },
        browser_info: {
            text: <IntlMessages id="Browser Info"/>,
            sortable: true,
            filterable: true,
        },
        // status: {
        //     text: <IntlMessages id="Status"/>,
        //     sortable: true,
        //     filterable: true,
        // },
        status: {
            text: <IntlMessages id="Status"/>,
            sortable: true,
            filterable: true,
            transform: (value, idx, row) => (
                CisUI().getStatusLabel(value)
            )
        },
        // actions: {
        //     text: 'Actions',
        //     sortable: false,
        //     filterable: false,
        //     transform: (value, idx, row) => (
        //         <>
        //             <Link to={`userprofile/${row.id}`} className="btn btn-primary btn-circle">
        //                 <i className="fas fa-trash-restore"></i>
        //             </Link>
        //         </>
        //     ),
        // },
    };

    const orderedHeaders = jsonfile.listView.title;


    const getFields = () => {
        const count = expand ? 10 : 6;
        const children = [];
        const inputs = jsonfile.input;

        inputs.map((list, index) => {
            if(list.isFilterable == 1) {
                children.push(
                    <Col lg={6} md={6} sm={12} xs={24} key={index} style={{display: index < count ? 'block' : 'none'}}>
                        <div className="gx-form-row0">
                            <Form.Item
                                name={`${list.name}`}
                                label={`${list.label}`}
                                rules={[
                                    {
                                        required: true,
                                        message: "",
                                    },
                                ]}
                            >
                                <Input placeholder={list.name} />
                            </Form.Item>
                        </div>
                    </Col>,
                );
            }
        });
        return children;
    };

    const [expand, setExpand] = useState(false);
    const [form] = Form.useForm();

    const onFinish = values => {
        console.log('Received values of form: ', values);
        const qs = Object.keys(values).map(key => `${key}=${values[key]}`).join('&');
        const qsup = qs.replaceAll("undefined","");
        //reqURL = reqURL+"/?"+qsup;
        history.push("?"+qsup);
        const newURL = Config.apiserver+jsonfile.urls.list+"/?"+qsup;
        setState({reqURL: newURL});
    };

    let  searchView = "";
    if(jsonfile.has_search) {
        searchView = <Card className="advance-search-card">
            <Collapse
                accordion
                expandIconPosition="right"
                defaultActiveKey={['1']} >
                <Panel header="Advance Search"  key="1">
                    <Form
                        key={5}
                        form={form}
                        {...CisUI().formItemLayout}
                        name="advanced_search"
                        className="ant-advanced-search-form"
                        onFinish={onFinish}
                    >
                        {AdvancedSearch(jsonfile.loginSearchInputs)}
                    </Form>
                </Panel>
            </Collapse>
        </Card>
    }

    const handleOnFilter = ({ target: { name, value } }) => {
        //filterValue = value;
        console.log("filteringwith :"+ value);
        setState({filtervalue : value});
    }


    const getExtra = (
        <>
            {/*<Search*/}
            {/*    placeholder='Filter...'*/}
            {/*    name='filtervalue'*/}
            {/*    onSearch={handleOnFilter}*/}
            {/*    onChange={handleOnFilter}*/}
            {/*    style={{width: 200}}*/}
            {/*/>*/}
            {CisUI().listAction("../"+jsonfile.urls.add, "", state.reqURL, "data", 'login_history', jsonfile, state.subtitle, 'landscape')}
        </>
    );

    return (
        <>
            {searchView}
            <Card title="Login History" extra={getExtra}>
                {/*{CisUI().listAction()}*/}
                {/*<SmartDataTable*/}
                {/*    name='test-fake-table'*/}
                {/*    data={Config.apiserver+"user/loginhistory"}*/}
                {/*    dataKey="data"*/}
                {/*    headers={headers}*/}
                {/*    orderedHeaders={orderedHeaders}*/}
                {/*    hideUnordered={CisUI().hideUnordered}*/}
                {/*    className={CisUI().sematicUI.table}*/}
                {/*    filterValue={filterValue}*/}
                {/*    perPage={CisUI().perPage}*/}
                {/*    sortable*/}
                {/*    withLinks*/}
                {/*    withHeader*/}
                {/*    loader={CisUI().loader}*/}
                {/*    parseBool={{*/}
                {/*        yesWord: 'Yes',*/}
                {/*        noWord: 'No',*/}
                {/*    }}*/}
                {/*    parseImg={{*/}
                {/*        style: {*/}
                {/*            border: '1px solid #ddd',*/}
                {/*            borderRadius: '50px',*/}
                {/*            padding: '3px',*/}
                {/*            width: '30px',*/}
                {/*            height: '30px'*/}
                {/*        },*/}
                {/*    }}*/}
                {/*    emptyTable={CisUI().emptyTable}*/}
                {/*/>*/}

                <SmartDataTable
                    name='test-fake-table'
                    data={state.reqURL}
                    dataRequestOptions={CisUI().HeaderRequest}
                    dataKey="data"
                    headers={headers}
                    orderedHeaders={orderedHeaders}
                    hideUnordered={CisUI().hideUnordered}
                    className={CisUI().sematicUI.table}
                    filterValue={state.filtervalue}
                    perPage={CisUI().perPage}
                    sortable
                    withLinks
                    dynamic
                    withHeader
                    loader={CisUI().loader}
                    parseBool={{
                        yesWord: 'Yes',
                        noWord: 'No',
                    }}
                    parseImg={{
                        style: {
                            border: '1px solid #ddd',
                            borderRadius: '50px',
                            padding: '3px',
                            width: '30px',
                            height: '30px'
                        },
                    }}
                    emptyTable={CisUI().emptyTable}
                />

            </Card>
        </>
    );
};

export default LoginHistory;