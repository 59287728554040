import {Form, Row, Col, Input, Button, Card, Collapse, Select, DatePicker, Checkbox, notification, Spin} from 'antd';
import React, {useState, useEffect} from "react";
import jsonfile from './invoice.json'
import {CisUI} from '../../../../util/CISUI'
import 'react-smart-data-table/dist/react-smart-data-table.css'
import Config from "../../../../util/config";
import axios from "../../../../util/Api";
import {useHistory} from "react-router-dom";
import RemoteJson from "../../../../util/gerremotedata";
import SelectData from "../../../../util/selectdata";
import moment from "moment";

const {Option} = Select;

const NewInvoice = (props) => {

    const formRef = React.createRef();

    const [loading, setLoading] = useState([]);

    const [state, setState] = useState({
        data: [],
        rows: []
    })

    const [receivable, setReceivable] = useState([]);
    const [accounts, setAccounts] = useState([]);
    const [customer, setcustomer] = useState([]);
    const [bill, setBill] = useState(
        {
            total : 0,
            grand_total : 0,
            freight : 0,
            taxp : 0,
            tax_amount : 0,
            discount_p : 0,
            discount_amount : 0
        }
    );

    const [comp, setComp] = useState(
        {
            com_address: "",
            com_city: "",
            com_state: "",
            com_zip_code: ""
        }
    );

    const [vend, setVend] = useState(
        {
            customer_address: "",
            customer_city: "",
            customer_state: "",
            customer_zip_code: "",
            shipping_method: ""
        }
    );

    const handleChange = (e) => {
        setComp({
            [e.target.name]: e.target.value
        })
    }

    const userID = props.match.params.id;
    let userData = [];
    // console.log("Userid : "+props.match.params.id);
    let isEdit = 0;
    if (userID > 0) {
        isEdit = 1;
    }
    let Title = jsonfile.addtitle;
    let endpoint = jsonfile.urls.add;
    let redirectto = "../" + jsonfile.urls.list;

    if (isEdit === 1) {
        userData = RemoteJson(jsonfile.urls.view + "/" + userID);
        Title = jsonfile.edittitle;
        endpoint = jsonfile.urls.edit + "/" + userID;
        redirectto = "../../" + jsonfile.urls.list;
    }

    let customers = [];
    const Companies = SelectData("company/companylists");
    let ReceiveAble = [];
    let Accounts = [];

    const [comapny, setCompany] = useState([]);
    const [local, setLocal] = useState([]);


    const [form] = Form.useForm();
    const history = useHistory();

    const onFinish = (values) => {
        setLoading({loading: true})
        values["items"] = state.rows;
        values["summary"] = bill;
        console.log(JSON.stringify(values));

        axios.post(Config.apiserver + endpoint, values)
            .then(res => {
                console.log(res)
                if (res.data.status === 1) {
                    notification.success({
                        message: 'Success',
                        type: "success",
                        description: res.data.msg
                    });
                    history.push(redirectto);
                } else {
                    notification.warning({
                        message: 'Alert',
                        type: "warning",
                        description: res.data.msg
                    });
                }
                setLoading({loading: false});
            })
            .catch(errors => {
                console.log(errors.response.data.errors);
                setLoading({loading: false});
            });
    };

    const onCompanyChange = value => {
        console.log("selected : "+ value);
        let fl = Companies.filter(item => item.id === value);
        if(fl.length > 0) {
            const company = fl[0];
            const area = company['area'];
            axios.get("customer/customerinfobycompany?com_id="+value)
                .then((res) => {
                    console.log(res.data);
                    if(res.data.status === 1) {
                        setcustomer(res.data.data);
                        setReceivable(res.data.receiveable);
                        setAccounts(res.data.accounts);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });

            // ReceiveAble = SelectData("receiveable?com_id="+value+"&area="+area);
            // Accounts = SelectData("accountslist?com_id="+value+"&area="+area);

            // console.log("selected : "+ JSON.stringify(company));
            //console.log("address : "+ company.address_line_1+", "+company.address_line_2);
            document.getElementById("input_com_address").value = "Test";
            formRef.current.setFieldsValue({
                com_address: company.address_line_1+", "+company.address_line_2,
                com_city: company.city,
                com_state: company.state,
                com_zip_code: company.zip_code,
                customer: "",
                customer_address: "",
                customer_city: "",
                customer_state: "",
                customer_zip_code: "",
                shipping_method: "",
                sales_tax_pp: 0
            });
        }
    }

    const oncustomerChange = value => {
        console.log("selected : "+ value);
        let fl = customer.filter(item => item.id === value);
        if(fl.length > 0) {
            const company = fl[0];
            console.log("selected : "+ JSON.stringify(company));
            var bill = company["address"];

            formRef.current.setFieldsValue({
                customer_address: bill,
                customer_city: company["city"],
                customer_state: company["state_name"],
                customer_zip_code: company["zip_code"]
            });

            var total = bill.total;
            var freight = company.shipping_freight;
            var taxp = company.shipping_tax;
            var discount_p = bill.discount_p;
            var discount_amount = total * discount_p / 100;
            var totalBeforeTax = total - discount_amount ;
            var taxAmount = totalBeforeTax * (taxp / 100);
            var grandTotal = totalBeforeTax + taxAmount + freight;

            setBill({
                total : total,
                grand_total : grandTotal,
                freight : freight,
                taxp : taxp,
                tax_amount : taxAmount,
                discount_p : discount_p,
                discount_amount : discount_amount
            });
        }
    }

    const appendChild = () => {
        let { data } = state;
        data.push(data.length); // data.length is one more than actual length since array starts from 0.
        setState({
            data : data,
            rows : {
                code : '',
                name : '',
                qty : 0,
                price : 0,
                amount : 0
            }
        });

    };


    const removeRow = () => {
        let { data } = state;
        data.pop(data.length); // data.length is one more than actual length since array starts from 0.
        setState({data});
    }

    const ItemCellChange = idx => e => {
        const { name, value } = e.target;
        const rows = [...state.rows];
        console.log("selectedITems:"+ JSON.stringify(rows[idx]));
        if(name === "code") {
            rows[idx] = {
                code: value,
                name: rows[idx].name,
                qty: rows[idx].qty,
                price: rows[idx].price,
                amount: rows[idx].amount
            };
        }
        else if(name === "name") {
            rows[idx] = {
                code: rows[idx].code,
                name: value,
                qty: rows[idx].qty,
                price: rows[idx].price,
                amount: rows[idx].amount
            };
        }
        else if(name === "qty") {
            const amount = value * rows[idx].price;
            rows[idx] = {
                code: rows[idx].code,
                name: rows[idx].name,
                qty: value,
                price: rows[idx].price,
                amount: amount
            };
        }
        else if(name === "price") {
            //CalculateCell();
            const amount = value * rows[idx].qty;
            rows[idx] = {
                code: rows[idx].code,
                name: rows[idx].name,
                qty: rows[idx].qty,
                price: value,
                amount: amount
            };

        }
        else if(name === "amount") {
            rows[idx] = {
                code: rows[idx].code,
                name: rows[idx].name,
                qty: rows[idx].qty,
                price: rows[idx].price,
                amount: value
            };
        }

        setState({
            rows
        });

        let totals = 0
        rows.map((item, idx) => {
            totals += item.amount;
        });

        var total = totals;
        var freight = bill.freight;
        var taxp = bill.taxp;
        var discount_p = bill.discount_p;
        var discount_amount = (parseFloat(total) * discount_p / 100) || 0;
        var totalBeforeTax = (parseFloat(total)) - parseFloat(discount_amount);
        var taxAmount = (parseFloat(totalBeforeTax) * (parseFloat(taxp) / 100)) || 0;
        var grandTotal = totalBeforeTax + taxAmount + parseFloat(freight);

        setBill({
            total : total,
            grand_total : grandTotal,
            freight : freight,
            taxp : taxp,
            tax_amount : taxAmount,
            discount_p : discount_p,
            discount_amount : discount_amount
        });

    };

    const ChangeTax = e => {

        const  value = parseFloat(e.target.value);
        let totals = 0;
        state.rows.map((item, idx) => {
            totals += item.amount;
        });

        const BeforeTax = (totals) - bill.discount;
        const tax = BeforeTax * (value / 100);
        const grand = (totals + bill.freight + tax) - bill.discount;
        setBill({
            total : totals,
            grand_total : grand,
            freight : bill.freight,
            tax : value,
            discount : bill.discount
        });
    }

    const ChangeFreight = e => {
        let totals = 0;
        const  value = parseFloat(e.target.value);
        state.rows.map((item, idx) => {
            totals += item.amount;
        });


        var total = totals;
        var freight = value || 0;
        var taxp = bill.taxp;
        var discount_p = bill.discount_p;
        var discount_amount = (parseFloat(total) * discount_p / 100) || 0;
        var totalBeforeTax = (parseFloat(total)) - parseFloat(discount_amount);
        var taxAmount = (parseFloat(totalBeforeTax) * (parseFloat(taxp) / 100)) || 0;
        var grandTotal = totalBeforeTax + taxAmount + parseFloat(freight);

        setBill({
            total : total,
            grand_total : grandTotal,
            freight : freight,
            taxp : taxp,
            tax_amount : taxAmount,
            discount_p : discount_p,
            discount_amount : discount_amount
        });
    }

    const ChangeDiscount = e => {
        let totals = 0;
        const  value = parseFloat(e.target.value);
        state.rows.map((item, idx) => {
            totals += item.amount;
        });

        const BeforeTax = (totals) - bill.discount;
        const tax = BeforeTax * (bill.tax / 100);
        const grand = (totals + bill.freight + tax) - value;

        setBill({
            total : totals,
            grand_total : grand,
            freight : bill.freight,
            tax : bill.tax,
            discount : value
        });
    }

    const onDiscountChange = e => {

        var total = bill.total;
        var freight = bill.freight;
        var taxp = bill.taxp;
        var discount_p = parseFloat(e.target.value);
        var discount_amount = parseFloat(total) * discount_p / 100;
        var totalBeforeTax = parseFloat(total) - parseFloat(discount_amount);
        var taxAmount = parseFloat(totalBeforeTax) * (parseFloat(taxp) / 100);
        var grandTotal = totalBeforeTax + taxAmount + + parseFloat(freight);

        setBill({
            total : total,
            grand_total : grandTotal,
            freight : freight,
            taxp : taxp,
            tax_amount : taxAmount,
            discount_p : discount_p,
            discount_amount : discount_amount
        });
    }

    const onTaxChange = e => {
        var total = bill.total;
        var freight = bill.freight;
        var taxp = parseFloat(e.target.value);
        var discount_p = bill.discount_p;
        var discount_amount = parseFloat(total) * discount_p / 100;
        var totalBeforeTax = parseFloat(total)  - parseFloat(discount_amount);
        var taxAmount = parseFloat(totalBeforeTax) * (parseFloat(taxp) / 100);
        var grandTotal = parseFloat(totalBeforeTax) + parseFloat(taxAmount) + + parseFloat(freight);

        setBill({
            total : total,
            grand_total : grandTotal,
            freight : freight,
            taxp : taxp,
            tax_amount : taxAmount,
            discount_p : discount_p,
            discount_amount : discount_amount
        });
    }

    const handleAddRow = () => {
        const item = {
            code : '',
            name : '',
            qty : 0,
            price : 0,
            amount : 0
        };
        setState({
            rows: [...state.rows, item]
        });
    };

    const handleRemoveRow = idx => e => {
        // console.log(idx);
        // console.log(state.rows);
        let filteredArray = state.rows.filter((item, index) => index !== idx)
        setState({rows: filteredArray});
    };

    // useEffect(() => {
    //     handleAddRow();
    // }, []);


    var totalAmount = 0;

    const CalculateCell = () => {
        var table = document.getElementById('itemtable');
        for (var r = 0, n = table.rows.length; r < n; r++) {
            var rowID = table.rows[r];
            var qty = rowID.cells[3].getElementsByTagName('INPUT')[0].value;
            var price = rowID.cells[4].getElementsByTagName('INPUT')[0].value;
            var total = parseInt(qty) * parseFloat(price);
            totalAmount += total;
            rowID.cells[4].getElementsByTagName('INPUT')[0].value = total;
        }
    }

    const Row = ({ id }) => (
        <tr>
            <td style={{width : '30px'}}><a onClick={handleRemoveRow} className="btn btn-primary btn-circle"><i className="fa fa-trash"></i></a></td>
            <td style={{width : '150px'}}><input className="form-control" placeholder="Enter Item Code" name="code" value={state.rows[id].code} onChange={ItemCellChange(id)}  /></td>
            <td><input className="form-control" placeholder="Enter Item Description" name="name" value={state.rows[id].name} onChange={ItemCellChange(id)}  /></td>
            <td style={{width : '100px'}}><input className="form-control" placeholder="Quantity" name="qty" value={state.rows[id].qty} onChange={ItemCellChange(id)}  /></td>
            <td style={{width : '100px'}}><input className="form-control" placeholder="Price" name="price" value={state.rows[id].price} onChange={ItemCellChange(id)}  /></td>
            <td style={{width : '100px'}}><input className="form-control"  name="amount" value={state.rows[id].amount} onChange={ItemCellChange(id)} /></td>
        </tr>
    );

    return (
        <Card title={Title}>
            {
                isEdit === 1
                    ? CisUI().addAction('../../' + jsonfile.urls.list)
                    : CisUI().addAction('../' + jsonfile.urls.list)
            }
            <Form
                form={form}
                {...CisUI().formItemLayout}
                name="input"
                ref={formRef}
                className="ant-advanced-search-form"
                onFinish={onFinish}
                initialValues={{remember: true}}
            >

                <div className="row">
                    <div className="col-md-6">
                        <Card title="Company Information">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="gx-form-row0">
                                        <Form.Item
                                            name="company"
                                            label="Company"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Select a company"
                                                },
                                            ]}
                                        >
                                            <Select
                                                showSearch
                                                placeholder="Select a company"
                                                optionFilterProp="children"
                                                onChange={onCompanyChange}
                                                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                            >
                                                {
                                                    Companies.map((items, index) =>
                                                        <Option key={++index} value={items.id}>{items.name}</Option>
                                                    )
                                                }
                                            </Select>
                                        </Form.Item>

                                    </div>
                                </div>

                                <div className="col-md-12">
                                    <div className="gx-form-row0">
                                        <Form.Item
                                            label="Company Address"
                                            name="com_address"
                                            onChange={handleChange}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Company Shipping Address"
                                                },
                                            ]}
                                        >
                                            <Input defaultValue={comp.com_address} value={comp.com_address} placeholder="Company Shipping Address" />
                                        </Form.Item>
                                    </div>
                                </div>

                                <div className="col-md-4">
                                    <div className="gx-form-row0">
                                        <Form.Item
                                            label="Company City"
                                            name="com_city"
                                            onChange={handleChange}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Company City"
                                                },
                                            ]}
                                        >
                                            <Input placeholder="Company City" />
                                        </Form.Item>
                                    </div>
                                </div>

                                <div className="col-md-4">
                                    <div className="gx-form-row0">
                                        <Form.Item
                                            label="Company State"
                                            name="com_state"
                                            onChange={handleChange}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Company City"
                                                },
                                            ]}
                                        >
                                            <Input value={comp.com_city} placeholder="Company State" />
                                        </Form.Item>
                                    </div>
                                </div>

                                <div className="col-md-4">
                                    <div className="gx-form-row0">
                                        <Form.Item
                                            label="Company Zip Code"
                                            name="com_zip_code"
                                            onChange={handleChange}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Company Zip Code"
                                                },
                                            ]}
                                        >
                                            <Input value={comp.com_zip_code} placeholder="Company Zip Code" />
                                        </Form.Item>
                                    </div>
                                </div>

                            </div>
                        </Card>
                    </div>
                    <div className="col-md-6">
                        <Card title="Customer Information">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="gx-form-row0">
                                        <Form.Item
                                            name="customer_id"
                                            label="Customer"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Select a customer"
                                                },
                                            ]}
                                        >
                                            <Select
                                                showSearch
                                                placeholder="Select a customer"
                                                optionFilterProp="children"
                                                onChange={oncustomerChange}
                                                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                            >
                                                {
                                                    customer.map((items, index) =>
                                                        <Option key={++index} value={items.id}>{items.full_name}</Option>
                                                    )
                                                }
                                            </Select>
                                        </Form.Item>

                                    </div>
                                </div>

                                <div className="col-md-12">
                                    <div className="gx-form-row0">
                                        <Form.Item
                                            id="customer_address"
                                            label="Customer Address"
                                            name="customer_address"
                                            onChange={handleChange}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Customer Address"
                                                },
                                            ]}
                                        >
                                            <Input value={vend.customer_address} placeholder="Customer Address" />
                                        </Form.Item>
                                    </div>
                                </div>

                                <div className="col-md-4">
                                    <div className="gx-form-row0">
                                        <Form.Item
                                            id="customer_city"
                                            label="Customer City"
                                            name="customer_city"
                                            onChange={handleChange}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "customer City"
                                                },
                                            ]}
                                        >
                                            <Input value={vend.customer_city} placeholder="customer City" />
                                        </Form.Item>
                                    </div>
                                </div>

                                <div className="col-md-4">
                                    <div className="gx-form-row0">
                                        <Form.Item
                                            id="customer_state"
                                            label="Customer State"
                                            name="customer_state"
                                            onChange={handleChange}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Customer State"
                                                },
                                            ]}
                                        >
                                            <Input value={vend.customer_state} placeholder="Customer State" />
                                        </Form.Item>
                                    </div>
                                </div>

                                <div className="col-md-4">
                                    <div className="gx-form-row0">
                                        <Form.Item
                                            label="Customer Zip Code"
                                            name="customer_zip_code"
                                            onChange={handleChange}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "customer Zip Code"
                                                },
                                            ]}
                                        >
                                            <Input value={vend.customer_zip_code} placeholder="customer Zip Code" />
                                        </Form.Item>
                                    </div>
                                </div>

                            </div>
                        </Card>

                    </div>
                </div>
                <div className="row">

                    <div className="col-md-2">
                        <div className="gx-form-row0">
                            <Form.Item
                                name="date"
                                label="Order Date"
                                initialValue={moment()}
                                rules={[
                                    {
                                        required: true,
                                        message: "Order date"
                                    },
                                ]}
                            >
                                <DatePicker format={CisUI().dateFormat} placeholder="Order Date" />
                            </Form.Item>

                        </div>
                    </div>

                    <div className="col-md-4">
                        <Form.Item
                            name="receivable_id"
                            label="Accounts Receivable"
                            rules={[
                                {
                                    required: true,
                                    message: "Select a accounts receivable"
                                },
                            ]}
                        >
                            <Select
                                showSearch
                                placeholder="Select a receivable account"
                                optionFilterProp="children"
                                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                            >
                                {
                                    receivable.map((items, index) =>
                                        <Option key={++index} value={items.id}>{items.name}</Option>
                                    )
                                }
                            </Select>
                        </Form.Item>
                    </div>

                    <div className="col-md-4">
                        <Form.Item
                            name="account_id"
                            label="Account"
                            rules={[
                                {
                                    required: true,
                                    message: "Select an account"
                                },
                            ]}
                        >
                            <Select
                                showSearch
                                placeholder="Select an account"
                                optionFilterProp="children"
                                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                            >
                                {
                                    accounts.map((items, index) =>
                                        <Option key={++index} value={items.id}>{items.name}</Option>
                                    )
                                }
                            </Select>
                        </Form.Item>
                    </div>

                </div>


                <div className="row">
                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-md-6">
                                <h2>Invoice Items</h2>
                            </div>
                            <div className="col-md-6" style={{textAlign : "right"}}>
                                <a className="btn btn-primary" onClick={handleAddRow}>Add More</a>
                            </div>
                        </div>
                        <table id="itemtable" className="ptable table table-bordered">
                            <thead>
                            <tr>
                                <th></th>
                                <th>Item Code</th>
                                <th>Item Description</th>
                                <th>Quantity</th>
                                <th>Unit Price($)</th>
                                <th>Amount</th>
                            </tr>
                            </thead>
                            <tbody>
                            {state.rows.map((item, idx) => (
                                <tr id="addr0" key={idx}>
                                    <td style={{width : '30px'}}><a onClick={handleRemoveRow(idx)} className="btn btn-primary btn-circle"><i className="fa fa-trash"></i></a></td>
                                    <td style={{width : '150px'}}><input className="form-control" placeholder="Enter Item Code" name="code" value={state.rows[idx].code} onChange={ItemCellChange(idx)}   /></td>
                                    <td><input className="form-control" placeholder="Enter Item Description" name="name" value={state.rows[idx].name} onChange={ItemCellChange(idx)}  /></td>
                                    <td style={{width : '100px'}}><input className="form-control text-right" style={{textAlign : 'right'}} placeholder="Quantity" name="qty" value={state.rows[idx].qty} onChange={ItemCellChange(idx)}  /></td>
                                    <td style={{width : '100px'}}><input className="form-control text-right" style={{textAlign : 'right'}} placeholder="Price" name="price" value={state.rows[idx].price} onChange={ItemCellChange(idx)}  /></td>
                                    <td style={{width : '100px'}}><input className="form-control text-right" style={{textAlign : 'right'}} readOnly={true} name="amount" value={state.rows[idx].amount} onChange={ItemCellChange(idx)}  /></td>
                                </tr>
                            ))}
                            </tbody>
                            <tfoot>
                            <tr>
                                <td style={{textAlign : 'right', fontWeight : 'bold'}} colSpan={5}>Total</td>
                                <td style={{textAlign : 'right', fontWeight : 'bold'}}>{CisUI().getCurrencyFormated1(bill.total)}</td>
                            </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-12" style={{textAlign: 'center', marginTop : '30px'}}>
                        <Button id="submitbutton" type="primary" htmlType="submit">
                            Save Invoice
                        </Button>
                        <Button type="info" onClick={() => history.goBack()} htmlType="back">Cancel</Button>
                    </div>
                </div>

            </Form>
        </Card>
    );
};

export default NewInvoice;