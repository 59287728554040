import {Form, Row, Col, Input, Button, Card, Collapse, Modal, Select, DatePicker, Checkbox, notification, Spin} from 'antd';
import React, {useState, useEffect} from "react";
import jsonfile from './bill.json'
import {CisUI} from '../../../../util/CISUI'
import 'react-smart-data-table/dist/react-smart-data-table.css'
import Config from "../../../../util/config";
import axios from "../../../../util/Api";
import {Link, useHistory} from "react-router-dom";
import RemoteJson from "../../../../util/gerremotedata";
import SelectData from "../../../../util/selectdata";
import moment from "moment";
import 'moment/locale/es-us';
import BillInput from "./billInput";

const {Option} = Select;
const {TextArea} = Input;
const queryString = require('query-string');


const NewBill = (props) => {

    const formRef = React.createRef();
    const [modal, contextHolder] = Modal.useModal();
    const [form] = Form.useForm();
    const history = useHistory();

    const [title, setTitle] = useState(jsonfile.addtitle);
    const [hasPayment, setHasPayment] = useState(false);
    const [payments, setPayment] = useState([]);
    const [isApprover, setApprover] = useState(false);
    const [hasBill, setHasbill] = useState(false);
    const [bill, setBill] = useState({});
    const [comp, setComp] = useState([]);
    const [vendor, setVendor] = useState([]);
    const [accounts, setAccount] = useState([]);
    const [payable, setPayable] = useState([]);
    const [fund, setFund] = useState([]);
    const [expense, setExpense] = useState([]);

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);
    const [isRejectModalVisible, setIsRejectModalVisible] = useState(false);

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const showModalCofirm = () => {
        setIsConfirmModalVisible(true);
    };

    const handleOkConfirm = () => {
        setIsConfirmModalVisible(false);
        BillStatusUpdate(bill.id,1);
    };

    const handleCancelConfirm = () => {
        setIsConfirmModalVisible(false);
    };

    const showModalReject = () => {
        setIsRejectModalVisible(true);
    };

    const handleOkReject = () => {
        setIsRejectModalVisible(false);
        BillStatusUpdate(bill.id,3);
    };

    const handleCancelReject = () => {
        setIsRejectModalVisible(false);
    };


    const handleChange = (e) => {
        setComp({
            [e.target.name]: e.target.value
        })
    }

    const BillStatusUpdate = (id,status) => {
        document.getElementById("loader").style.display = "block";
        axios.get("vendor/billstatusupdate?bill_id="+id+"&status="+status)
            .then((res) => {
                document.getElementById("loader").style.display = "none";
                console.log(res.data);
                if(res.data.status === 1) {
                    notification.success({
                        message: 'Success',
                        type: "success",
                        description: res.data.msg
                    });
                    getPODetails();
                }
                else {
                    document.getElementById("loader").style.display = "none";
                    notification.success({
                        message: 'Failed',
                        type: "danger",
                        description: res.data.msg
                    });
                }
            })
            .catch((error) => {
                console.log(error);
                document.getElementById("loader").style.display = "none";
            });
    }

    let Vendors = [];
    const Companies = SelectData("company/companylists");

    const [comapny, setCompany] = useState([]);
    const [local, setLocal] = useState([]);

    const onCompanyChange = value => {
        console.log("selected : "+ value);
        let fl = Companies.filter(item => item.id === value);
        if(fl.length > 0) {
            const company = fl[0];
            axios.get("billentrydata?com_id="+value)
                .then((res) => {
                    console.log(res.data);
                    if(res.data.status === 1) {
                        setVendor(res.data.vendor);
                        setAccount(res.data.account);
                        setPayable(res.data.payable);
                        setFund(res.data.fund);
                        setExpense(res.data.expense);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });

            formRef.current.setFieldsValue({
                vendor: "",
                accounts_payable: "",
                accounts_id: ""
            });
        }
    }

    const updateCompanyList = () => {
        axios.get("billentrydata?com_id="+polist.company_id)
            .then((res) => {
                console.log(res.data);
                if(res.data.status === 1) {
                    setVendor(res.data.vendor);
                    setAccount(res.data.account);
                    setPayable(res.data.payable);
                    setFund(res.data.fund);
                    setExpense(res.data.expense);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const [loading, setLoading] = useState([]);
    const [polist, setPolist] = useState({
        po_id : 0,
        po_number : "",
        date: moment(),
        due_date: moment(),
        reference_no: "",
        company: "",
        company_id: 0,
        vendor_id: 0,
        vendor: "",
        payable_id: null,
        fund_program_id : null,
        func_expense_id : null,
        amount : 0,
        description : "",
        remark : "",
        status : 0,
        approved_by : "",
        approved_at : "",
    });


    const [state, setState] = useState({
        po: {},
        items: []
    });

    const parsed = queryString.parse(props.location.search);

    const po = parsed.po;
    let PORData = [];
    let poid = 0;
    if(po > 0) {
        poid = po;
    }

    const userID = props.match.params.id;
    let userData = [];
    // console.log("Userid : "+props.match.params.id);
    let isEdit = 0;
    if (userID > 0) {
        isEdit = 1;
    }

    let endpoint = jsonfile.urls.add;
    let redirectto = "../" + jsonfile.urls.list;

    if (isEdit === 1) {
        userData = RemoteJson(jsonfile.urls.view + "/" + userID);
        setTitle(jsonfile.edittitle);
        endpoint = jsonfile.urls.edit + "/" + userID;
        redirectto = "../../" + jsonfile.urls.list;
    }

    const onFinish = (values) => {
        setLoading({loading: true})
        values["amount"] = polist.amount;
        values["vendor_id"] = polist.vendor_id;
        values["company_id"] = polist.company_id;
        values["po_id"] = polist.po_id;
        values["po_number"] = polist.po_number;
        console.log(JSON.stringify(values));

        axios.post(Config.apiserver + endpoint, values)
            .then(res => {
                console.log(res)
                if (res.data.status === 1) {
                    notification.success({
                        message: 'Success',
                        type: "success",
                        description: res.data.msg
                    });
                    //history.push(redirectto);
                    history.push("newbill?po="+polist.po_id);
                } else {
                    notification.warning({
                        message: 'Alert',
                        type: "warning",
                        description: res.data.msg
                    });
                }
                setLoading({loading: false});
            })
            .catch(errors => {
                console.log(errors.response.data.errors);
                setLoading({loading: false});
            });
    };

    let POData = {};

    useEffect(() => {
        getPODetails();
    }, []);

    const getPODetails = () => {
        document.getElementById("loader").style.display = "block";
        if(isEdit === 0) {
             axios.get(Config.apiserver + "purchase/podetails/" + poid)
                .then((res) => {
                    document.getElementById("loader").style.display = "none";
                    //console.log(res.data);
                    if (res.data.status === 1) {
                        const PODataD = res.data.podata;
                        if(res.data.hasBill === 1) {
                            setBill(res.data.bill);
                            setHasbill(true);
                            setTitle("Bill Details");
                        }
                        if(res.data.isApprover === 1) {
                            setApprover(true);
                        }
                        if(res.data.hasPayment === 1) {
                            setHasPayment(true);
                            setPayment(res.data.payment);
                        }
                        setPolist({
                            po_number : PODataD.po_number,
                            date: moment(),
                            due_date: moment(),
                            reference_no: "",
                            company: PODataD.company,
                            vendor: PODataD.vendor_name,
                            company_id: PODataD.company_id,
                            vendor_id: PODataD.vendor_id,
                            payable_id: null,
                            fund_program_id : null,
                            func_expense_id : null,
                            amount : PODataD.grand_total,
                            description : PODataD.description,
                            remark : PODataD.remark,
                            status : PODataD.status,
                            approved_by : PODataD.approved_user,
                            approved_at : PODataD.approved_at
                        });

                        updateCompanyList();

                    }
                })
                .catch((error) => {
                    console.log(error);
                    document.getElementById("loader").style.display = "none";
                });
        }
        else {
            setPolist({
                po_number : userData.po_number,
                date: CisUI().DateFormat(userData.date),
                due_date: CisUI().DateFormat(userData.due_date),
                reference_no: userData.reference_no,
                company_id: userData.company_id,
                vendor_id: userData.vendor_d,
                payable_id: userData.payable_id,
                fund_program_id : userData.fund_program_id,
                func_expense_id : userData.func_expense_id,
                amount : userData.amount,
                description : userData.description,
                remark : userData.remark
            });
        }
    }

    const noBillField = (
        <>
            {
                polist.status === 1 ?
                    <>
                        <div className="row">
                            <div className="col-md-4">
                                <Form.Item
                                    name="date"
                                    label="Date"
                                    initialValue={polist.date}
                                    rules={[
                                        {
                                            required: true,
                                            message: "Select a date"
                                        },
                                    ]}
                                >
                                    <DatePicker format={CisUI().dateFormat} className="gx-mb-3 gx-w-100"
                                                placeholder="Date"/>
                                </Form.Item>
                            </div>

                            <div className="col-md-4">
                                <Form.Item
                                    name="due_date"
                                    label="Due Date"
                                    initialValue={polist.due_date}
                                    rules={[
                                        {
                                            required: true,
                                            message: "Select a due date"
                                        },
                                    ]}
                                >
                                    <DatePicker format={CisUI().dateFormat} className="gx-mb-3 gx-w-100"
                                                placeholder="Due Date"/>
                                </Form.Item>
                            </div>

                            <div className="col-md-4">
                                <Form.Item
                                    name="reference_no"
                                    label="Reference#"
                                    initialValue={polist.reference_no}
                                    rules={[
                                        {
                                            required: false,
                                            message: "Enter reference number"
                                        },
                                    ]}
                                >
                                    <Input className="gx-mb-3 gx-w-100" placeholder="Enter a reference number"/>
                                </Form.Item>
                            </div>

                            <div className="col-md-4">
                                <Form.Item
                                    name="payable_id"
                                    label="Accounts Payable"
                                    initialValue={polist.payable_id}
                                    rules={[
                                        {
                                            required: true,
                                            message: "Select a accounts payable"
                                        },
                                    ]}
                                >
                                    <Select
                                        showSearch
                                        placeholder="Select a payable account"
                                        optionFilterProp="children"
                                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                    >
                                        {
                                            payable.map((items, index) =>
                                                <Option key={++index} value={items.id}>{items.name}</Option>
                                            )
                                        }
                                    </Select>
                                </Form.Item>
                            </div>

                            <div className="col-md-4">
                                <Form.Item
                                    name="account_id"
                                    initialValue={polist.account_id}
                                    label="Account"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Select an account"
                                        },
                                    ]}
                                >
                                    <Select
                                        showSearch
                                        placeholder="Select an account"
                                        optionFilterProp="children"
                                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                    >
                                        {
                                            accounts.map((items, index) =>
                                                <Option key={++index} value={items.id}>{items.name}</Option>
                                            )
                                        }
                                    </Select>
                                </Form.Item>
                            </div>

                            <div className="col-md-4">
                                <Form.Item
                                    name="fund_program_id"
                                    label="Select a Fund Program"
                                    initialValue={polist.fund_program_id}
                                    rules={[
                                        {
                                            required: false,
                                            message: "Select a fund program"
                                        },
                                    ]}
                                >
                                    <Select
                                        showSearch
                                        placeholder="Select a fund prgoram"
                                        optionFilterProp="children"
                                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                    >
                                        {
                                            fund.map((items, index) =>
                                                <Option key={++index} value={items.id}>{items.name}</Option>
                                            )
                                        }
                                    </Select>
                                </Form.Item>
                            </div>

                            <div className="col-md-4">
                                <Form.Item
                                    name="func_expense_id"
                                    label="Functional Expense"
                                    initialValue={polist.func_expense_id}
                                    rules={[
                                        {
                                            required: false,
                                            message: "Select a functional expense"
                                        },
                                    ]}
                                >
                                    <Select
                                        showSearch
                                        placeholder="Select a functional expense"
                                        optionFilterProp="children"
                                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                    >
                                        {
                                            expense.map((items, index) =>
                                                <Option key={++index} value={items.id}>{items.name}</Option>
                                            )
                                        }
                                    </Select>
                                </Form.Item>
                            </div>
                            <div className="col-md-4">
                                <Form.Item
                                    name="description"
                                    label="Description"
                                    initialValue={polist.description}
                                    rules={[
                                        {
                                            required: true,
                                            message: "Enter description here"
                                        },
                                    ]}
                                >
                                    <TextArea placeholder="Enter description here"/>
                                </Form.Item>
                            </div>

                            <div className="col-md-4">
                                <Form.Item
                                    name="remark"
                                    label="Remark/Memo"
                                    initialValue={polist.remark}
                                    rules={[
                                        {
                                            required: false,
                                            message: "Enter something here"
                                        },
                                    ]}
                                >
                                    <TextArea placeholder="Enter somthing ehre"/>
                                </Form.Item>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-12" style={{textAlign: 'center', marginTop: '30px'}}>
                                <Button id="submitbutton" type="primary" htmlType="submit">
                                    Save
                                </Button>
                                <Button type="info" onClick={() => history.goBack()} htmlType="back">Cancel</Button>
                            </div>
                        </div>
                    </>
                    : ""
            }
        </>
    );

    const ApproveOrReject = (
        <>
            <div className="col-md-12" style={{textAlign: 'center', marginTop: '30px'}}>
                {
                    bill.status === 0 ?
                        isApprover === true
                            ? <>
                                <Button id="submitbutton" type="danger" onClick={showModalReject} htmlType="button">Reject</Button>
                                <Button id="submitbutton" type="primary" onClick={showModalCofirm} htmlType="button">Approve</Button>
                            </>
                            : ""
                    : ""
                }
                {
                    bill.status === 1 ?
                        hasPayment === false ?
                            <><Button id="submitbutton" type="primary" onClick={showModalReject} htmlType="button"><Link to={"newpayment?bill_id="+bill.id}>Payment</Link></Button></>
                        : ""
                    : ""
                }
                <Button type="info" onClick={() => history.goBack()} htmlType="back">Back</Button>
            </div>
        </>
    );

    const PaymentInformaiton = (
        <></>
    );

    let totalPaid = 0;

    const BillInfo = (
        <div className="row" style={{padding: '20px'}}>
            <h4>Bill Information</h4>
            <div className="col-md-4">
                <label style={{fontWeight: 'bold'}}>Bill Number : </label>
                <span> {bill.bill_no}</span>
            </div>
            <div className="col-md-4">
                <label style={{fontWeight: 'bold'}}>Description : </label>
                <span> {bill.description}</span>
            </div>
            <div className="col-md-4">
                <label style={{fontWeight: 'bold'}}>Date : </label>
                <span> {CisUI().DateFormat(bill.date)}</span>
            </div>
            <div className="col-md-4">
                <label style={{fontWeight: 'bold'}}>Due Date : </label>
                <span> {CisUI().DateFormat(bill.due_date)}</span>
            </div>
            <div className="col-md-4">
                <label style={{fontWeight: 'bold'}}>Reference No# : </label>
                <span> {bill.reference_no}</span>
            </div>
           <div className="col-md-4">
                <label style={{fontWeight: 'bold'}}>Accounts Payable :  </label>
                <span> {bill.payable}</span>
            </div>
            <div className="col-md-4">
                <label style={{fontWeight: 'bold'}}>Account : </label>
                <span> {bill.accounts_head}</span>
            </div>
            <div className="col-md-4">
                <label style={{fontWeight: 'bold'}}>Fund Program : </label>
                <span> {bill.fund_program}</span>
            </div>
            <div className="col-md-4">
                <label style={{fontWeight: 'bold'}}>Functional Expense : </label>
                <span> {bill.functional_expense}</span>
            </div>
            <div className="col-md-4">
                <label style={{fontWeight: 'bold'}}>Bill Amount : </label>
                <span style={{color: 'green'}}> {CisUI().getCurrencyFormated1(bill.amount)}</span>
            </div>
            <div className="col-md-4">
                <label style={{fontWeight: 'bold'}}>Remark : </label>
                <span> {bill.remark}</span>
            </div>
            <div className="col-md-4">
                <label style={{fontWeight: 'bold'}}>Created By : </label>
                <span> {bill.created_by}</span>
            </div>
            <div className="col-md-4">
                <label style={{fontWeight: 'bold'}}>Created at : </label>
                <span> {bill.created_at}</span>
            </div>
            <div className="col-md-4">
                <label style={{fontWeight: 'bold'}}>Updated at : </label>
                <span> {bill.updated_at}</span>
            </div>
            <div className="col-md-4">
                <label style={{fontWeight: 'bold'}}>Approved/Reject By : </label>
                <span> {bill.approved_user}</span>
            </div>
            <div className="col-md-4">
                <label style={{fontWeight: 'bold'}}>Approved/Reject at : </label>
                <span> {bill.approved_at}</span>
            </div>
            <div className="col-md-4">
                <label style={{fontWeight: 'bold'}}>Bill Status : </label>
                <span> {CisUI().getBillSatus(bill.status)}</span>
            </div>

            {
                hasPayment ?
                    <>
                        <h4 style={{marginTop: '20px'}}>Payment Informaiton</h4>
                        <div className="table-responsive">
                            <table className="table table-bordered">
                                <thead>
                                <tr>
                                    <th>Date</th>
                                    <th>Title</th>
                                    <th>Account</th>
                                    <th>Payment Method</th>
                                    <th>Bank Account</th>
                                    <th>Reference/Check No.</th>
                                    <th style={{textAlign: 'right'}}>Amount</th>
                                    <th>Actions</th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    payments.map((list, index) => {
                                        totalPaid += parseFloat(list.credit);
                                        return <tr>
                                            <td>{CisUI().DateFormat(list.date)}</td>
                                            <td>{list.title}</td>
                                            <td>{list.account_head}</td>
                                            <td>{list.payment_method}</td>
                                            <td>{list.bank_account}</td>
                                            <td>{list.reference}</td>
                                            <td style={{textAlign: 'right'}}>{list.credit}</td>
                                            <td><a href="#" className="btn btn-primary btn-xs">Check Print</a></td>
                                        </tr>
                                    })
                                }
                                </tbody>
                                <tfoot>
                                <tr>
                                    <th colSpan={6}>Total Paid</th>
                                    <th style={{textAlign: 'right'}}>{CisUI().getCurrencyFormated1(totalPaid)}</th>
                                    <th></th>
                                </tr>
                                </tfoot>
                            </table>
                        </div>
                    </>
                : ""
            }

            {ApproveOrReject}


        </div>
    );

    return (
        <Card title={title}>
            {
                isEdit === 1
                    ? CisUI().addAction('../../' + jsonfile.urls.list)
                    : CisUI().addAction('../' + jsonfile.urls.list)
            }
            <Form
                form={form}
                {...CisUI().formItemLayout}
                name="input"
                ref={formRef}
                className="ant-advanced-search-form"
                onFinish={onFinish}
            >
                {CisUI().showLoading}
                <div className="row" style={{padding: '20px'}}>
                    <h4>PO Information</h4>
                    <div className="col-md-4">
                        <label style={{fontWeight: 'bold'}}>Company Name : </label>
                        <span> {polist.company}</span>
                    </div>
                    <div className="col-md-4">
                        <label style={{fontWeight: 'bold'}}>PO Number : </label>
                        <span> {polist.po_number}</span>
                    </div>
                    <div className="col-md-4">
                        <label style={{fontWeight: 'bold'}}>Vendor Name : </label>
                        <span> {polist.vendor}</span>
                    </div>
                    <div className="col-md-4">
                        <label style={{fontWeight: 'bold'}}>PO Amount : </label>
                        <span style={{fontWeight: 'bold',color: 'green'}}> {CisUI().getCurrencyFormated1(polist.amount)}</span>
                    </div>
                    <div className="col-md-4">
                        <label style={{fontWeight: 'bold'}}>PO Status : </label>
                        <span> {CisUI().getBillSatus(polist.status)}</span>
                    </div>
                    <div className="col-md-4">
                        <label style={{fontWeight: 'bold'}}>Approved By : </label>
                        <span> {polist.approved_by}</span>
                    </div>
                    <div className="col-md-4">
                        <label style={{fontWeight: 'bold'}}>Approved at : </label>
                        <span> {polist.approved_at}</span>
                    </div>
                </div>

                {
                    hasBill === true
                    ?BillInfo
                    :noBillField
                }



            </Form>

            <Modal title="Alert" visible={isConfirmModalVisible} okText={"Confirm"} onOk={handleOkConfirm} onCancel={handleCancelConfirm}>
                <h4>Are you sure you want to approve ?</h4>
            </Modal>

            <Modal title="Alert" visible={isRejectModalVisible} okText={"Confirm"} onOk={handleOkReject} onCancel={handleCancelReject}>
                <h4>Are you sure you want to reject ?</h4>
            </Modal>

        </Card>
    );
};

export default NewBill;