import {Form, Row, Col, Input, Button, Card, Collapse, Modal, Select, DatePicker, Checkbox, notification, Spin} from 'antd';
import React, {useState, useEffect} from "react";
import jsonfile from './banktransaction.json'
import {CisUI} from '../../../../util/CISUI'
import 'react-smart-data-table/dist/react-smart-data-table.css'
import Config from "../../../../util/config";
import axios from "../../../../util/Api";
import {Link, useHistory} from "react-router-dom";
import RemoteJson from "../../../../util/gerremotedata";
import SelectData from "../../../../util/selectdata";
import moment from "moment";
import 'moment/locale/es-us';

const {Option} = Select;
const {TextArea} = Input;
const queryString = require('query-string');


const BankReconcilation = (props) => {

    const formRef = React.createRef();
    const [modal, contextHolder] = Modal.useModal();
    const [form] = Form.useForm();
    const history = useHistory();

    const [title, setTitle] = useState(jsonfile.new_deposit);
    const [hasPayment, setHasPayment] = useState(false);
    const [payments, setPayment] = useState([]);
    const [isApprover, setApprover] = useState(false);
    const [hasBill, setHasbill] = useState(false);
    const [bill, setBill] = useState({});
    const [comp, setComp] = useState([]);
    const [vendor, setVendor] = useState([]);
    const [accounts, setAccount] = useState([]);
    const [payable, setPayable] = useState([]);
    const [fund, setFund] = useState([]);
    const [expense, setExpense] = useState([]);
    const [incomes, setIncomes] = useState([]);
    const [bankaccount, setBankAccount] = useState([]);
    const [cashaccount, setCashAccount] = useState([]);


    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);
    const [isRejectModalVisible, setIsRejectModalVisible] = useState(false);

    const [paymentmethod, setPaymentmethod] = useState([]);


    let Vendors = [];
    const Companies = SelectData("company/companylists");

    const [comapny, setCompany] = useState([]);
    const [local, setLocal] = useState([]);

    const onCompanyChange = value => {
        console.log("selected : "+ value);
        let fl = Companies.filter(item => item.id === value);
        if(fl.length > 0) {
            const company = fl[0];
            axios.get("billentrydata?com_id="+value)
                .then((res) => {
                    console.log(res.data);
                    if(res.data.status === 1) {
                        setVendor(res.data.vendor);
                        setAccount(res.data.account);
                        setPayable(res.data.payable);
                        setFund(res.data.fund);
                        setExpense(res.data.expense_account);
                        setIncomes(res.data.income_account);
                        setBankAccount(res.data.bank);
                        setCashAccount(res.data.cashaccount);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });

            formRef.current.setFieldsValue({
                vendor: "",
                accounts_payable: "",
                accounts_id: ""
            });
        }
    }

    const updateCompanyList = () => {
        axios.get("billentrydata?com_id="+userData.company_id)
            .then((res) => {
                console.log(res.data);
                if(res.data.status === 1) {
                    setVendor(res.data.vendor);
                    setAccount(res.data.account);
                    setPayable(res.data.payable);
                    setFund(res.data.fund);
                    setExpense(res.data.expense);
                    setCashAccount(res.data.cashaccount);
                    setExpense(res.data.expense_account);
                    setIncomes(res.data.income_account);
                    setBankAccount(res.data.bank);

                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const [loading, setLoading] = useState([]);

    const [state, setState] = useState({
        balance : 0,
        ending_balance : 0
    });

    const parsed = queryString.parse(props.location.search);

    const po = parsed.po;
    let PORData = [];
    let poid = 0;
    if(po > 0) {
        poid = po;
    }

    const userID = props.match.params.id;
    let userData = [];
    // console.log("Userid : "+props.match.params.id);
    let isEdit = 0;
    if (userID > 0) {
        isEdit = 1;
    }

    let endpoint = jsonfile.urls.add;
    let redirectto = "../" + jsonfile.urls.list;

    if (isEdit === 1) {
        userData = RemoteJson(jsonfile.urls.view + "/" + userID);
        setTitle(jsonfile.edittitle);
        endpoint = jsonfile.urls.edit + "/" + userID;
        redirectto = "../../" + jsonfile.urls.list;
    }

    const onFinish = (values) => {
        setLoading({loading: true})
        values["balance"] = state.balance;
        values["ending_balance"] = state.ending_balance;

        let com_id = CisUI().getUserInfo('com_id');
        if(com_id === 0) {
            com_id = values['com_id'];
        }
        const date1 = values['month'];
        const date = CisUI().ParseDateFormatFromMonth(date1);
        //console.log(date);
        const account_id = values['account_id'];
        let incomeAct = "";
        let expenseAct = "";
        if(values['income_account_id'] > 0) {
             const inc = incomes.filter(items => items.id === values['income_account_id']);
             if(inc.length > 0) {
                 values['incomeAct'] = inc[0].name;
             }
        }

        if(values['expense_account_id'] > 0) {
            const exp = expense.filter(items => items.id === values['expense_account_id']);
            if(exp.length > 0) {
                values['expenseAct'] = exp[0].name;
            }
        }

        console.log(JSON.stringify(values));

        if(com_id > 0 && date !=='' && account_id > 0 && state.ending_balance > 0) {
            localStorage.setItem("reconcile", JSON.stringify(values));
            history.push("reconcilestart?account_id=" + account_id);
        }
        else {
            notification.warning({
                message: 'Alert',
                type: "warning",
                description: "Please select a company, date, and enter ending balance"
            });
        }

    };

    let POData = {};

    const onAccountChange = (e) => {
        let com_id = CisUI().getUserInfo('com_id');
        if(com_id === 0) {
            com_id = formRef.current.getFieldValue('com_id');
        }
        const date1 = formRef.current.getFieldValue('month');
        const date = CisUI().ParseDateFormatFromMonth(date1);
        console.log(date);
        const account_id = formRef.current.getFieldValue('account_id');

        if(com_id > 0 && date !=='' && account_id > 0) {
            setState({
                balance : 0
            });
            axios.get(Config.apiserver+"getbegainingbalance?com_id="+com_id+"&account_id="+account_id+"&date="+date)
                .then(res => {
                    console.log(res)
                    if (res.data.status === 1) {
                        const balance = res.data.balance;
                        setState({
                            balance : balance,
                            ending_balance : res.data.ending_balance || 0
                        });
                    } else {
                        notification.warning({
                            message: 'Alert',
                            type: "warning",
                            description: res.data.msg
                        });
                    }
                    setLoading({loading: false});
                })
                .catch(errors => {
                    console.log(errors.response.data.errors);
                    setLoading({loading: false});
                });
        }
        else {
            notification.warning({
                message: 'Alert',
                type: "warning",
                description: "Please select a company and date"
            });
        }
    }

    const onEndingBalanceChange = (e) => {
        setState({
            balance : state.balance,
            ending_balance : e.target.value
        });
    }

    const noBillField = (
        <>
            <div className="row">
                <div className="col-md-3">
                    <Form.Item
                        name="com_id"
                        label="Company"
                        rules={[
                            {
                                required: true,
                                message: "Select an company"
                            },
                        ]}
                    >
                        <Select
                            showSearch
                            placeholder="Select an company"
                            optionFilterProp="children"
                            onChange={onCompanyChange}
                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        >
                            {
                                Companies.map((items, index) =>
                                    <Option key={++index} value={items.id}>{items.name}</Option>
                                )
                            }
                        </Select>
                    </Form.Item>
                </div>
                <div className="col-md-3">
                    <Form.Item
                        name="month"
                        label="Month"
                        rules={[
                            {
                                required: true,
                                message: "Select a month"
                            },
                        ]}
                    >
                        <DatePicker picker="month" className="gx-mb-3 gx-w-100"
                                    placeholder="month"/>
                    </Form.Item>
                </div>

                <div className="col-md-3">
                    <Form.Item
                        name="account_id"
                        label="Account"
                        rules={[
                            {
                                required: true,
                                message: "Select an account"
                            },
                        ]}
                    >
                        <Select
                            showSearch
                            placeholder="Select an account"
                            optionFilterProp="children"
                            onChange={onAccountChange}
                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        >
                            {
                                accounts.map((items, index) =>
                                    <Option key={++index} value={items.id}>{items.name}</Option>
                                )
                            }
                        </Select>
                    </Form.Item>
                </div>

                <div className="col-md-3">
                    <label>Beginning Balance</label>
                    <input className="form-control" readOnly={true} name="beg_balance" value={state.balance}  />
                </div>
                <div className="col-md-3">
                    <label>Ending Balance</label>
                    <input className="form-control" name="ending_balance" onChange={onEndingBalanceChange} id="ending_balance" value={state.ending_balance}   />
                </div>
            </div>


            <div className="row" style={{padding : '10px'}}>
                <h3>Enter the service charge if necessary</h3>
                <div className="col-md-3">
                    <Form.Item
                        name="service_date"
                        label="Date"
                        rules={[
                            {
                                required: false,
                                message: "Select a date"
                            },
                        ]}
                    >
                        <DatePicker picker="date" defaultValue={moment()} className="gx-mb-3 gx-w-100"
                                    placeholder="date" />
                    </Form.Item>
                </div>
                <div className="col-md-3">
                    <Form.Item
                        name="service_charge"
                        label="Service Charge"
                        rules={[
                            {
                                required: false,
                                message: "Input an amount"
                            },
                        ]}
                    >
                        <Input defaultValue={0} className="gx-mb-3 gx-w-100"
                                    placeholder="Enter an amount" />
                    </Form.Item>
                </div>

                <div className="col-md-3">
                    <Form.Item
                        name="expense_account_id"
                        label="Expense Account"
                        rules={[
                            {
                                required: true,
                                message: "Select an expense account"
                            },
                        ]}
                    >
                        <Select
                            showSearch
                            placeholder="Select an expense account"
                            optionFilterProp="children"
                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        >
                            {
                                expense.map((items, index) =>
                                    <Option key={++index} value={items.id}>{items.name}</Option>
                                )
                            }
                        </Select>
                    </Form.Item>
                </div>

            </div>

            <div className="row" style={{padding : '10px'}}>
                <h3>Enter the interest earned, if necessary</h3>
                <div className="col-md-3">
                    <Form.Item
                        name="interest_date"
                        label="Interest Date"
                        rules={[
                            {
                                required: false,
                                message: "Select a interest date"
                            },
                        ]}
                    >
                        <DatePicker picker="date" defaultValue={moment()} className="gx-mb-3 gx-w-100"
                                    placeholder="date" />
                    </Form.Item>
                </div>
                <div className="col-md-3">
                    <Form.Item
                        name="interest_charge"
                        label="Interest Amount"
                        rules={[
                            {
                                required: false,
                                message: "Input an interest amount"
                            },
                        ]}
                    >
                        <Input defaultValue={0} className="gx-mb-3 gx-w-100"
                               placeholder="Enter an amount" />
                    </Form.Item>
                </div>

                <div className="col-md-3">
                    <Form.Item
                        name="income_account_id"
                        label="Income Account"
                        rules={[
                            {
                                required: true,
                                message: "Select an income account"
                            },
                        ]}
                    >
                        <Select
                            showSearch
                            placeholder="Select an income account"
                            optionFilterProp="children"
                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        >
                            {
                                incomes.map((items, index) =>
                                    <Option key={++index} value={items.id}>{items.name}</Option>
                                )
                            }
                        </Select>
                    </Form.Item>
                </div>

            </div>



            <div className="row">
                <div className="col-md-12" style={{textAlign: 'center', marginTop: '30px'}}>
                    <Button id="submitbutton" type="primary" htmlType="submit">
                        Start Reconciling
                    </Button>
                    <Button type="info" onClick={() => history.goBack()} htmlType="back">Cancel</Button>
                </div>
            </div>
        </>
    );


    return (
        <Card title={"New Bank Reconciliation"}>
            {
                isEdit === 1
                    ? CisUI().addAction('../../' + jsonfile.urls.list)
                    : CisUI().addAction('../' + jsonfile.urls.list)
            }
            <Form
                form={form}
                {...CisUI().formItemLayout}
                name="input"
                ref={formRef}
                className="ant-advanced-search-form"
                onFinish={onFinish}
            >
                {CisUI().showLoading}
                {
                    noBillField
                }
            </Form>
        </Card>
    );
};

export default BankReconcilation;