import React, {useState, useEffect} from "react";
import {Row, Col, Form, Card, Input, Select, Button, DatePicker, notification, Popconfirm} from 'antd';
import jsonfile from './employeeidentity.json';
import {useHistory} from "react-router-dom";
import RemoteJson from "../../../../util/gerremotedata";
import StaticSelectData from "../../../../util/StaticSelectData";
import {CisUI} from "../../../../util/CISUI";
import Config from "../../../../util/config";
import axios from 'axios';

const {Option} = Select;
const {TextArea} = Input
const EmployeeIdentity = (props) => {

    const history = useHistory();
    const [form] = Form.useForm();
    const [loading, setLoading] = useState([]);

    const userID = props.empid;
    const editID = props.editEmpId;
    let userData = [];
    // console.log("Userid : "+props.match.params.id);
    let isEdit = 0;
    if(editID > 0) {
        isEdit = 1;
    }
    let Title = jsonfile.addtitle;
    let endpoint = jsonfile.urls.add+"/"+userID;
    let redirectto = "../"+jsonfile.urls.list;

    const Types = StaticSelectData('identity_type');

    const [state, setState] = useState({
        rows: [
            {
                type: "",
                number: "",
                issue_date : "",
                expire_date : ""
            }
        ]
    });

    let edit_data = [];
    if(isEdit === 1 ) {
        userData = RemoteJson(jsonfile.urls.view+"/"+editID);
        Title = jsonfile.edittitle;
        endpoint = jsonfile.urls.edit+"/"+editID;
        redirectto = "../../"+jsonfile.urls.list;
        // console.log(userData.type)

        userData.forEach((item, index) =>
            edit_data.push({
                type: item.type,
                number: item.number,
                issue_date: item.issue_date,
                expire_date: item.expire_date
            })
        )
        // console.log(d);
    }

    useEffect(() => {
        if(isEdit === 1) {
            setState({
                rows: edit_data
            });
        }
    }, [userData]);

    // console.log(state.rows)

    const handleAdd = () => {
        // console.log("hi");
        const item = {
            type: "",
            number: "",
            issue_date : "",
            expire_date : ""
        };
        setState({
            rows: [...state.rows, item]
        });
    };

    const handleRemoveRow = (idx) => e => {
        console.log(idx);

        const filteredArray = [...state.rows];
        // console.log(filteredArray.length)
        if(filteredArray.length == 1) {
            notification.warning({
                message: 'Alert',
                type : "warning",
                description: "You can not delete this item."
            });
        } else {
            setState({
                rows: filteredArray.filter((item, index) => index !== idx)
            });
        }

    };

    const handleAddChange = (id) => e => {

        const { name, value } = e.target;
        const rows = [...state.rows];
        // console.log("selectedITems:"+ JSON.stringify(rows[id]));

        if(name === "type") {
            rows[id] = {
                type: value,
                number: rows[id].number,
                issue_date: rows[id].issue_date,
                expire_date: rows[id].expire_date
            };
        }
        else if(name === "number") {
            rows[id] = {
                type: rows[id].type,
                number: value,
                issue_date: rows[id].issue_date,
                expire_date: rows[id].expire_date
            };
        }
        else if(name === "issue_date") {
            rows[id] = {
                type: rows[id].type,
                number: rows[id].number,
                issue_date: value,
                expire_date: rows[id].expire_date
            };
        }
        else if(name === "expire_date") {
            rows[id] = {
                type: rows[id].type,
                number: rows[id].number,
                issue_date: rows[id].issue_date,
                expire_date: value
            };
        }

        setState({
            rows
        });

    }

    const onFinish = (values) => {
        values["employee_identity"] = state.rows;
        // console.log(values.employee_identity);

        setLoading({loading: true})

        axios.post(Config.apiserver + endpoint, values, CisUI().HeaderRequest)
            .then(res => {
                console.log(res.data)
                if(res.data.status === 1) {
                    notification.success({
                        message: 'Success',
                        type : "success",
                        description: res.data.msg
                    });
                    // history.push(redirectto);
                }
                else {
                    notification.warning({
                        message: 'Alert',
                        type : "warning",
                        description: res.data.msg
                    });
                }
                setLoading({loading:false});
            })
            .catch(errors => {
                // console.log(errors);
                setLoading({loading:false});
            });
    }

    return (
        <Card title={Title}>
            <div className="row">
                <div className="col-md-12">
                    <div className="row">
                        <div className="col-md-6">
                            {/*<h2>Purchase Items</h2>*/}
                        </div>
                        <div className="col-md-6" style={{textAlign : "right"}}>
                            <a className="btn btn-primary" onClick={handleAdd}>Add More</a>
                        </div>
                    </div>
                    <Form
                        form={form}
                        {...CisUI().formItemLayout}
                        name="input"
                        className="ant-advanced-search-form"
                        onFinish={onFinish}
                        initialValues={{ remember: true }}
                    >
                        <table id="empidentity" className="ptable table table-bordered">
                            <thead>
                            <tr>
                                <th></th>
                                <th>Type</th>
                                <th>Number</th>
                                <th>Issue Date</th>
                                <th>Expire Date</th>
                            </tr>
                            </thead>
                            <tbody>
                            {/*{console.log(state.rows)}*/}
                            {state.rows.map((item, idx) =>
                                // console.log(item.type)
                                    (

                                <tr id="addr0" key={idx}>
                                    <td style={{width : '30px'}}>
                                        <a onClick={handleRemoveRow(idx)} className="btn btn-primary btn-circle"><i className="fa fa-trash"></i></a>
                                        {/*<Popconfirm title="Sure to delete?" onConfirm={handleRemoveRow(idx)}>*/}
                                        {/*    <a className="btn btn-primary btn-circle"><i className="fa fa-trash"></i></a>*/}
                                        {/*</Popconfirm>*/}
                                    </td>
                                    <td style={{width : '150px'}}>

                                        <select name="type" id="type" className="form-control" onChange={handleAddChange(idx)} value={state.rows[idx].type}>
                                            <option value="">Select An Option</option>
                                            {
                                                Types.map((items, index) =>
                                                    // console.log(state.rows[idx].type === items.id)
                                                    // <option key={++index} value={items.id}>{items.name == state.rows[idx].type ? state.rows[idx].type : items.name}</option>
                                                    <option key={++index} value={items.id}>{items.name}</option>
                                                )
                                            }

                                        </select>

                                    </td>
                                    <td style={{width : '100px'}}><input type="number" className="form-control" name="number" value={state.rows[idx].number} onChange={handleAddChange(idx)} /></td>
                                    <td style={{width : '100px'}}><input type="date" className="form-control" name="issue_date" value={state.rows[idx].issue_date} onChange={handleAddChange(idx)} /></td>
                                    <td style={{width : '100px'}}><input type="date" className="form-control " name="expire_date" value={state.rows[idx].expire_date} onChange={handleAddChange(idx)} /></td>
                                </tr>
                            )
                            )}
                            </tbody>
                        </table>

                        <Row gutter={24}>
                            <Col lg={24} md={24} sm={12} xs={24} style={{textAlign: 'center'}}>
                                <Button type="primary" htmlType="submit">
                                    Submit
                                </Button>
                                <Button type="info" onClick={() => history.goBack()} htmlType="back">Save & Submit</Button>
                            </Col>
                        </Row>
                    </Form>

                </div>
            </div>
        </Card>
    );
}

export default EmployeeIdentity;