import {Form, Row, Col, Input, InputNumber, DatePicker, Button, Card, Select, Checkbox, notification, Upload} from 'antd';
import React, {Component,useState, useEffect} from "react";
import jsonfile from './budget.json';
import {CisUI} from '../../../../util/CISUI';
import PostToApi from '../../../../util/PostToApi';
import InputFields from '../../../../util/InputField';
import 'react-smart-data-table/dist/react-smart-data-table.css';
import Config from "../../../../util/config";
import axios from "../../../../util/Api";
import {useHistory} from "react-router-dom";
import RemoteJson from "../../../../util/gerremotedata";
import SelectData from "../../../../util/selectdata";
import StaticSelectData from "../../../../util/StaticSelectData";
import $ from "jquery";
import InputOnly from "../../../../util/InputOnly";
import getRemoteJSON from "../../../../util/getremotejson";
import _ from "lodash";

import moment from "moment";
import 'moment/locale/es-us';

const { Option } = Select;
// const Option = Select.Option;
const {TextArea} = Input;


const NewBudget = (props) => {

    const formRef = React.createRef();

    const [loading, setLoading] = useState([]);
    const [inputList, setInputList] = useState([]);
    const [inputList2, setInputList2] = useState([]);
    const [incomes, setInocmes] = useState([]);
    const [expenses, setExpenses] = useState([]);
    const [checked, setChecked] = useState([]);
    const [processed, setProcessed] = useState(false);

    const monthNames = ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];

    const monthS = ["jan", "feb", "mar", "apr", "may", "jun",
        "jul", "aug", "sep", "oct", "nov", "dec"
    ];

    const [form] = Form.useForm();

    let endpoint = jsonfile.urls.add;
    const redirectto = "../../../"+jsonfile.urls.list;
    const history = useHistory();

    const handleChange=(e)=> {
        this.setState({
            [e.target.id]: e.target.value
        })
    }

    const userID = props.match.params.id;
    let userData = [];
    console.log("Userid : "+props.match.params.id);
    let isEdit = 0;
    if(userID > 0) {
        isEdit = 1;
    }
    let Title = jsonfile.addtitle;
    if(isEdit === 1 ) {
        userData = RemoteJson(jsonfile.urls.view+"/"+userID);
        Title = jsonfile.edittitle;
        endpoint = jsonfile.urls.edit+"/"+userID;
    }


    const onFinish = (values) => {
        setLoading({loading:true})
        //console.log("Input Data : "+values);
        //console.log("ExpenseData : "+inputList);

        const newArray = inputList.filter(value => JSON.stringify(value) !== '{}');
        const newArray2 = inputList2.filter(value2 => JSON.stringify(value2) !== '{}');

        //console.log("AfterFilter : "+JSON.stringify(newArray));
        values['expenses'] = newArray;
        values['expenses2'] = newArray2;
        console.log("All Input : "+JSON.stringify(values));
        let msg = "Sorry! request not processed, please try again";

        // axios.post(Config.apiserver + endpoint, values)
        //     .then(res => {
        //         console.log(res)
        //         if(res.data.status === 1) {
        //             notification.warning({
        //                 message: 'Alert',
        //                 type : "warning",
        //                 description: res.data.msg
        //             });
        //             history.push(redirectto);
        //         }
        //         else {
        //             notification.warning({
        //                 message: 'Alert',
        //                 type : "warning",
        //                 description: res.data.msg
        //             });
        //         }
        //         setLoading({loading:false});
        //     })
        //     .catch(errors => {
        //         console.log(errors);
        //         // msg = (errors);
        //         setLoading({loading:false});
        //         //setResponse([]);
        //     });



    };

    //const dataOptions2 = SelectData("incomehead");
    //const dataOptions = SelectData("expensehead");

    useEffect(() => {
        getBudgetHead();
    }, []);

    let dataex = [];

    let dataex2 = [];

    const getBudgetHead = async () => {
        return await axios.get("budgethead")
            .then((res) => {
                // console.log(res.data);
                if(res.data.status === 1) {
                    //setInocmes(res.data.incomes);
                    //setExpenses(res.data.expenses);

                    res.data.incomes.map((value, index) => {
                        dataex2[index] = {
                            id: value.id,
                            name: value.name,
                            total: 0,
                            jan : 0,
                            feb : 0,
                            mar : 0,
                            apr : 0,
                            may : 0,
                            jun : 0,
                            jul : 0,
                            aug : 0,
                            sep : 0,
                            oct : 0,
                            nov : 0,
                            dec : 0
                        };
                    });
                    setInocmes(dataex2);

                    res.data.expenses.map((value, index) => {
                        dataex[index] = {
                            id: value.id,
                            name: value.name,
                            total: 0,
                            jan : 0,
                            feb : 0,
                            mar : 0,
                            apr : 0,
                            may : 0,
                            jun : 0,
                            jul : 0,
                            aug : 0,
                            sep : 0,
                            oct : 0,
                            nov : 0,
                            dec : 0
                        };
                    });
                    setExpenses(dataex);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    //console.log("UserData : "+userData);

    const handleInputChange = (e, index) => {
        if(e && e.target) {
            const {name, value} = e.target;
            const list = [...incomes, { }];

            list[index]["id"] = incomes[index]["id"];
            list[index]["name"] = incomes[index]["name"];
            list[index]["total"] = value;

            _.times(12, (i) => {
                list[index][monthS[i]] = incomes[index][monthS[i]] || 0;
            });

            setInocmes(list);

        }
    };

    const  handleAmountInputChange = (e, index,i) => {
        if(e && e.target) {
            const list = [...incomes, { }];
            list[index]["id"] = incomes[index]["id"];
            list[index]["name"] = incomes[index]["name"];
            list[index]["total"] = incomes[index]["total"];

            _.times(12, (ix) => {
                if(ix === i) {
                    list[index][monthS[ix]] = e.target.value || 0;
                }
                else {
                    list[index][monthS[ix]] = incomes[index][monthS[ix]] || 0;
                }

            });

            setInocmes(list);
        }
    };

    const m_handleInputChange = (e, index) => {
        if(e && e.target) {
            const {name, value} = e.target;
            const list = [...expenses, { }];

            list[index]["id"] = expenses[index]["id"];
            list[index]["name"] = expenses[index]["name"];
            list[index]["total"] = value;

            _.times(12, (i) => {
                list[index][monthS[i]] = expenses[index][monthS[i]] || 0;
            });

            setExpenses(list);

        }
    };


    const  m_handleAmountInputChange = (e, index,i) => {
        if(e && e.target) {
            const list = [...expenses, { }];
            list[index]["id"] = expenses[index]["id"];
            list[index]["name"] = expenses[index]["name"];
            list[index]["total"] = expenses[index]["total"];

            _.times(12, (ix) => {
                if(ix === i) {
                    list[index][monthS[ix]] = e.target.value || 0;
                }
                else {
                    list[index][monthS[ix]] = expenses[index][monthS[ix]] || 0;
                }

            });

            setExpenses(list);
        }
    };



    $('#input_region_id').on('change', function() {
        alert( "Regeion Selected" );
    });


    const onCheck = (e) => {
        let ck = checked;
        if(e.target.checked == true) {
            const vl = e.target.value;
            if(ck.indexOf(vl) > -1) {
            }
            else {
                ck.push(vl);
            }
        }
        else {
            const vl = e.target.value;
            if(ck.indexOf(vl) > -1) {
                const index = ck.indexOf(vl);
                ck.splice(index,1);
            }
        }
        setChecked(ck);
        console.log("checked value :",ck);
    }

    const onFillClick = () => {
        if(checked.length > 0) {

            const list = [...incomes, { }];

            checked.map((value,index) => {
                const data = incomes[value];
                list[value]["id"] = incomes[value]["id"];
                list[value]["name"] = incomes[value]["name"];
                let total = 0;
                _.times(12, (ix) => {
                    list[value][monthS[ix]] = incomes[value]["total"] || 0;
                    total += parseFloat(incomes[value]["total"]) || 0;
                });
                list[value]["total"] = total;

            });

            setInocmes(list);

        }
        else {
            notification.warning({
                message: 'Alert',
                type : "warning",
                description: "Sorry you did not select any items"
            });
        }
    }

    const onDistributeClick = () => {
        if(checked.length > 0) {

            const list = [...incomes, { }];
            checked.map((value,index) => {
                const data = incomes[value];
                list[value]["id"] = incomes[value]["id"];
                list[value]["name"] = incomes[value]["name"];
                list[value]["total"] = incomes[value]["total"];
                const eachV = incomes[value]["total"] / 12;
                _.times(12, (ix) => {
                    list[value][monthS[ix]] = eachV.toFixed(2) || 0;
                });
            });

            setInocmes(list);

        }
        else {
            notification.warning({
                message: 'Alert',
                type : "warning",
                description: "Sorry you did not select any items"
            });
        }
    }

    const onFillClickExp = () => {
        if(checked.length > 0) {

            const list = [...expenses, { }];

            checked.map((value,index) => {
                const data = expenses[value];
                list[value]["id"] = expenses[value]["id"];
                list[value]["name"] = expenses[value]["name"];
                let total = 0;
                _.times(12, (ix) => {
                    list[value][monthS[ix]] = expenses[value]["total"] || 0;
                    total += parseFloat(expenses[value]["total"]) || 0;
                });
                list[value]["total"] = total;

            });

            setExpenses(list);

        }
        else {
            notification.warning({
                message: 'Alert',
                type : "warning",
                description: "Sorry you did not select any items"
            });
        }
    }

    const onDistributeClickExp = () => {
        if(checked.length > 0) {

            const list = [...expenses, { }];
            checked.map((value,index) => {
                const data = expenses[value];
                list[value]["id"] = expenses[value]["id"];
                list[value]["name"] = expenses[value]["name"];
                list[value]["total"] = expenses[value]["total"];
                const eachV = expenses[value]["total"] / 12;
                _.times(12, (ix) => {
                    list[value][monthS[ix]] = eachV.toFixed(2) || 0;
                });
            });

            setExpenses(list);

        }
        else {
            notification.warning({
                message: 'Alert',
                type : "warning",
                description: "Sorry you did not select any items"
            });
        }
    }


    return (
        <Card title={Title}>
            {
                isEdit === 1
                    ? CisUI().addAction("../../"+jsonfile.urls.list)
                    : CisUI().addAction("../"+jsonfile.urls.list)
            }
            <Form
                form={form}
                ref={formRef}
                {...CisUI().formItemLayout}
                name="input"
                className="ant-advanced-search-form"
                onFinish={onFinish}
            >
                {InputOnly(jsonfile.input,userData,isEdit)}

                <Row gutter={24}>
                    <div className="table-responsive">
                        <h4>Incomes </h4>
                        <button type="button" onClick={onFillClick} className="btn btn-primary">Fill</button>
                        <button type="button" onClick={onDistributeClick} className="btn btn-primary">Distribute</button>
                        <table className="table table-bordered">
                            <thead>
                            <tr>
                                <th></th>
                                <th style={{width : "300px"}}>Account Name</th>
                                <th style={{width : "80px"}}>Total</th>
                                {_.times(12, (i) => (
                                    <td style={{width : "60px"}} key={i}>{monthNames[i]}</td>
                                ))}
                            </tr>
                            </thead>

                            {incomes.map((x, index) => {
                                return (
                                    <>
                                        <tr>
                                            <th>
                                                <input type="checkbox" onClick={onCheck} name="ck" id={"ck_"+x.id} value={index} />
                                            </th>
                                            <td>{x.name}
                                                <input type="hidden" id={"income_"+x.id} name="income" value={x.id} />
                                            </td>
                                            <td style={{width : "80px"}}>
                                                <input
                                                    className="ant-input"
                                                    name="total"
                                                    value={incomes[index].total}
                                                    style={{width : "60px"}}
                                                    id={"total_"+x.id+"_"+index}
                                                    onChange={e => handleInputChange(e, index)}
                                                />
                                            </td>
                                            {_.times(12, (i) => (
                                                <td style={{width : "60px"}}>
                                                    <input
                                                        className="ant-input"
                                                        name={"amount"}
                                                        value={incomes[index][monthS[i]]}
                                                        style={{width : "60px"}}
                                                        id={"amount_"+x.id+"_"+index}
                                                        onChange={e => handleAmountInputChange(e, index,i)}
                                                    />
                                                </td>
                                            ))}
                                        </tr>
                                    </>
                                );
                            })}
                        </table>
                        <h4>Expenses </h4>
                        <button type="button" onClick={onFillClickExp} className="btn btn-primary">Fill</button>
                        <button type="button" onClick={onDistributeClickExp} className="btn btn-primary">Distribute</button>
                        <table className="table table-bordered">
                        <thead>
                            <tr>
                                <th></th>
                                <th style={{width : "300px"}}>Account Name</th>
                                <th style={{width : "80px"}}>Total</th>
                                {_.times(12, (i) => (
                                    <td style={{width : "60px"}} key={i}>{monthNames[i]}</td>
                                ))}
                            </tr>
                        </thead>

                        {expenses.map((x, i) => {
                            return (
                                <>
                                    <tr>
                                        <th>
                                            <input type="checkbox" onClick={onCheck} name="ck" id={"ck_"+x.id} value={i} />
                                        </th>
                                        <td>{x.name}
                                            <input type="hidden" id={"expensetype_"+x.id} name="expensetype" value={x.id} />
                                        </td>
                                        <td style={{width : "80px"}}>
                                            <input
                                                className="ant-input"
                                                name="total"
                                                value={expenses[i]["total"]}
                                                style={{width : "60px"}}
                                                id={"total_"+x.id+"_"+i}
                                                onChange={e => m_handleInputChange(e, i)}
                                            />
                                        </td>
                                        {_.times(12, (ix) => (
                                            <td style={{width : "60px"}}>
                                                <input
                                                    className="ant-input"
                                                    name="amount"
                                                    value={expenses[i][monthS[ix]]}
                                                    style={{width : "60px"}}
                                                    id={"amount_"+x.id+"_"+ix}
                                                    onChange={e => m_handleAmountInputChange(e, i,ix)}
                                                />
                                            </td>
                                        ))}
                                    </tr>
                                </>
                            );
                        })}

                    </table>
                    </div>
                </Row>


                <Row gutter={24}>
                    <Col lg={24} md={24} sm={12} xs={24} style={{textAlign: 'center', marginTop : '30px'}}>
                        <Button id="submitbutton" type="primary" htmlType="submit">
                            Submit
                        </Button>
                        <Button type="info" onClick={() => history.goBack()} htmlType="back">Cancel</Button>
                    </Col>
                </Row>

            </Form>


        </Card>
    );
};

export default NewBudget;