import {Form, Row, Col, Input, Button, Card, Collapse, Select, DatePicker, Checkbox, notification, Spin} from 'antd';
import React, {useState, useEffect} from "react";
import {Link} from "react-router-dom";
import jsonfile from './purchase.json'
import {CisUI} from '../../../util/CISUI'
import PostToApi from '../../../util/PostToApi'
import InputFields from '../../../util/InputField'
import 'react-smart-data-table/dist/react-smart-data-table.css'
import Config from "../../../util/config";
import axios from "../../../util/Api";
import {useHistory} from "react-router-dom";
import RemoteJson from "../../../util/gerremotedata";
import SelectData from "../../../util/selectdata";
import moment from "moment";
import Pdf from "react-to-pdf";
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import {JsonToExcel} from 'react-json-excel';



var Barcode = require('react-barcode');
const {Option} = Select;



const PrintDetails = (props) => {

    const formRef = React.createRef();

    const [loading, setLoading] = useState([]);

    const [state, setState] = useState({
        po: {},
        items: []
    })


    const userID = props.match.params.id;
    let userData = [];
    // console.log("Userid : "+props.match.params.id);
    let isEdit = 0;
    if (userID > 0) {
        isEdit = 1;
    }

    const print = () => {
        window.print();
    };

    let Title = jsonfile.details_title;
    let endpoint = jsonfile.urls.add;
    let redirectto = "../" + jsonfile.urls.list;

    if (isEdit === 1) {

        Title = jsonfile.details_title;
        endpoint = jsonfile.urls.edit + "/" + userID;
        redirectto = "../../" + jsonfile.urls.list;
    }

    useEffect(() => {
        axios.get(Config.apiserver + jsonfile.urls.view + "/" + userID)
            .then((res) => {
                //console.log(res.data);
                if (res.data.status === 1) {
                    setState({
                        po: res.data.podata,
                        items: res.data.items
                    })
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    const [form] = Form.useForm();
    const history = useHistory();

    var totalAmount = 0;

        const  filename = 'Excel-file'
        const fields = {
            "index": "Index",
            "guid": "GUID"
        }
        const style = {
            padding: "5px"
        };
        const data = [
            { index: 0, guid: 'asdf231234'},
            { index: 1, guid: 'wetr2343af'}
        ];
        const text = "Excel";

    return (
       <div className="row">
           <div className="col-md-12 text-right no-print" style={{textAlign : 'right'}}>
               <a className="btn btn-text-adjust btn-circle-md" onClick={print} title="Print"><i className="fas fa-print"/></a>
               {/*<Pdf targetRef={formRef} filename={"POInvoice-"+state.po.po_number+".pdf"}>*/}
               {/*    {({ toPdf }) => <a className="btn btn-text-adjust btn-circle-md" onClick={toPdf} title="PDF"><i className="fas fa-file-pdf"/></a>}*/}
               {/*</Pdf>*/}
               {/*<JsonToExcel*/}
               {/*    data={data}*/}
               {/*    filename={filename}*/}
               {/*    fields={fields}*/}
               {/*    style={style}*/}
               {/*    text={text}*/}
               {/*/>*/}

           </div>
           <div ref={formRef} className="col-md-12" style={{textAlign : 'center'}}>
               <div style={{width : '100%', background: 'white', padding : '20px' }}>
                   <table style={{width : '100%' }}>
                       <tr>
                           <td style={{width : '50%',textAlign : 'left'}}>
                               <img alt="cop" style={{width : '300px'}} src={Config.print_logourl} />
                               {/*<div>{Config.address}</div>*/}
                               {/*<div>{Config.city},{Config.state},{Config.zipcode}</div>*/}
                               {/*<div>Phone : {Config.phone}</div>*/}
                               {/*<div>Email : {Config.email}</div>*/}
                               {/*<div>Website : {Config.website}</div>*/}
                           </td>
                           <td style={{width : '50%',textAlign : 'right'}}>
                               <h2>PURCHASE ORDER</h2>
                               <div>Date : {CisUI().DateFormat(state.po.date)}</div>
                               <div>PO Number : {state.po.po_number}</div>
                               <div><Barcode height={50} value={state.po.po_number} /></div>
                           </td>
                       </tr>
                       <tr>
                           <td style={{width : '50%',textAlign : 'left', paddingTop : '20px'}}>
                               <h3>Ship From : </h3>
                               <h5>{state.po.vendor_name}</h5>
                               <div>{state.po.vendor_address}</div>
                               <div>{state.po.vendor_city},{state.po.vendor_state},{state.po.vendor_zipcode}</div>
                               <div>Contact Person : {state.po.vendor_contact}</div>
                               <div>Phone : {state.po.vendor_phone}</div>
                           </td>
                           <td style={{width : '50%',textAlign : 'left', paddingTop : '20px'}}>
                               <h3>Ship To : </h3>
                               <h5>{state.po.company}</h5>
                               <div>{state.po.com_address}</div>
                               <div>{state.po.com_city},{state.po.com_state},{state.po.com_zipcode}</div>
                               <div>Phone : {state.po.company_phone}</div>
                           </td>
                       </tr>
                   </table>

                   <table className="ptable table table-bordered" style={{marginTop : '30px'}} width="100%">
                       <thead>
                        <tr>
                            <th>Required By</th>
                            <th>Shipping Method</th>
                            <th>Payment Terms</th>
                            <th>Discount(%)</th>
                            <th>Sales Tax(%)</th>
                        </tr>
                       </thead>
                       <tbody>
                       <tr>
                           <td>{CisUI().DateFormat(state.po.required_by_date)}</td>
                           <td>{state.po.shipping_method}</td>
                           <td>{state.po.payment_terms}</td>
                           <td>{state.po.discount_p}%</td>
                           <td>{state.po.sales_tax_p}%</td>
                       </tr>
                       </tbody>
                   </table>

                   <table id="itemtable" style={{marginTop : '30px'}} className="ptable table table-bordered">
                       <thead>
                       <tr>
                           <th>#</th>
                           <th>Item Code</th>
                           <th>Item Description</th>
                           <th>Quantity</th>
                           <th>Unit Price($)</th>
                           <th>Amount</th>
                       </tr>
                       </thead>
                       <tbody>
                       {state.items.map((item, idx) => (
                           <tr id="addr0" key={idx}>
                               <td style={{width : '30px'}}>{(idx+1)}</td>
                               <td style={{width : '150px'}}>{item.code}</td>
                               <td>{item.name}</td>
                               <td style={{width : '70px', textAlign : 'right'}}>{item.quantity}</td>
                               <td style={{width : '70px', textAlign : 'right'}}>{CisUI().getCurrencyFormated1(item.price)}</td>
                               <td style={{width : '70px', textAlign : 'right'}}>{CisUI().getCurrencyFormated1((item.price * item.quantity))}</td>
                           </tr>
                       ))}
                       </tbody>
                       <tfoot>
                       <tr>
                           <td colSpan={3} rowSpan={7} style={{verticalAlign : "top"}}>
                               <div style={{background : '#eaf0f3', marginTop : '20px', padding : '3px', textTransform: 'uppercase', borderBottom: '1px solid #ddd'}} >Comments or Special Instructions</div>
                               <textarea rows={5} className="form-control"></textarea>
                           </td>
                           <td style={{textAlign : 'right', fontWeight : 'bold'}} colSpan={2}>Gross Total</td>
                           <td style={{textAlign : 'right', fontWeight : 'bold'}}>{CisUI().getCurrencyFormated1(state.po.total)}</td>
                       </tr>
                       <tr>
                           <td style={{textAlign : 'right'}} colSpan={2}>Discount</td>
                           <td style={{textAlign : 'right'}}>{CisUI().getCurrencyFormated1(state.po.discount)}</td>
                       </tr>
                       <tr>
                           <td style={{textAlign : 'right', fontWeight : 'bold'}} colSpan={2}>After Discount</td>
                           <td style={{textAlign: 'right', fontWeight : 'bold'}}>{CisUI().getCurrencyFormated1((parseFloat(state.po.total) - parseFloat(state.po.discount)))}</td>
                       </tr>

                       <tr>
                           <td style={{textAlign : 'right'}} colSpan={2}>Sales Tax</td>
                           <td style={{textAlign : 'right'}}>{CisUI().getCurrencyFormated1(state.po.sales_tax)}</td>
                       </tr>
                       <tr>
                           <td style={{textAlign : 'right'}} colSpan={2}>Freight</td>
                           <td style={{textAlign : 'right'}}>{CisUI().getCurrencyFormated1(state.po.freight)}</td>
                       </tr>
                       <tr>
                           <td style={{textAlign : 'right', fontWeight : 'bold'}} colSpan={2}>Grand Total</td>
                           <td style={{textAlign : 'right', fontWeight : 'bold'}}>{CisUI().getCurrencyFormated1(state.po.grand_total)}</td>
                       </tr>
                       </tfoot>
                   </table>


                   <div className="row">
                       <div className="col-md-12" style={{textAlign: 'center'}}>
                           If you have any questions about this purchase order, please feel free to contact us
                       </div>
                   </div>

               </div>
           </div>
       </div>
    );
};
export default PrintDetails;