import {
    Form,
    Row,
    Col,
    Input,
    Button,
    Card,
    Collapse,
    Modal,
    Select,
    DatePicker,
    Checkbox,
    notification,
    Spin
} from 'antd';
import React, {useState, useEffect} from "react";
import {Link} from "react-router-dom";
import jsonfile from './invoice.json'
import {CisUI} from '../../../../util/CISUI'
import 'react-smart-data-table/dist/react-smart-data-table.css'
import Config from "../../../../util/config";
import axios from "../../../../util/Api";
import {useHistory} from "react-router-dom";
import moment from "moment";

var Barcode = require('react-barcode');
const {Option} = Select;

const ReachableContext = React.createContext();
const UnreachableContext = React.createContext();


const InvoiceDetails = (props) => {

    const formRef = React.createRef();

    const [modal, contextHolder] = Modal.useModal();
    const [loading, setLoading] = useState([]);

    const [state, setState] = useState({
        po: {},
        items: [],
        isPOApprover: 0,
        hasPayment: 0
    })


    const userID = props.match.params.id;
    let userData = [];
    // console.log("Userid : "+props.match.params.id);
    let isEdit = 0;
    if (userID > 0) {
        isEdit = 1;
    }

    const print = () => {
        window.print();
    };

    const onFinish = () => {

    }

    let Title = jsonfile.details_title;
    let endpoint = jsonfile.urls.add;
    let redirectto = "../" + jsonfile.urls.list;

    if (isEdit === 1) {

        Title = jsonfile.details_title;
        endpoint = jsonfile.urls.edit + "/" + userID;
        redirectto = "../../" + jsonfile.urls.list;
    }

    useEffect(() => {
        getPODetails();
    }, []);

    const getPODetails = () => {
        axios.get(Config.apiserver + jsonfile.urls.view + "/" + userID)
            .then((res) => {
                //console.log(res.data);
                if (res.data.status === 1) {
                    setState({
                        po: res.data.podata,
                        items: res.data.items,
                        hasPayment: res.data.hasPayment
                    })
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const [form] = Form.useForm();
    const history = useHistory();

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);
    const [isRejectModalVisible, setIsRejectModalVisible] = useState(false);

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const showModalCofirm = () => {
        setIsConfirmModalVisible(true);
    };

    const handleOkConfirm = () => {
        setIsConfirmModalVisible(false);
        PoStatusUpdate(state.po.id,1);
    };

    const handleCancelConfirm = () => {
        setIsConfirmModalVisible(false);
    };

    const showModalReject = () => {
        setIsRejectModalVisible(true);
    };

    const handleOkReject = () => {
        setIsRejectModalVisible(false);
        PoStatusUpdate(state.po.id,3);
    };

    const handleCancelReject = () => {
        setIsRejectModalVisible(false);
    };

    const handleChange = () => {

    }



    const PoStatusUpdate = (id,status) => {
        document.getElementById("loader").style.display = "block";
        axios.get("purchase/postatuschange?po_id="+id+"&status="+status)
            .then((res) => {
                document.getElementById("loader").style.display = "none";
                console.log(res.data);
                if(res.data.status === 1) {
                    notification.success({
                        message: 'Success',
                        type: "success",
                        description: res.data.msg
                    });
                    getPODetails();
                }
                else {
                    document.getElementById("loader").style.display = "none";
                    notification.success({
                        message: 'Failed',
                        type: "danger",
                        description: res.data.msg
                    });
                }
            })
            .catch((error) => {
                console.log(error);
                document.getElementById("loader").style.display = "none";
            });
    }

    var totalAmount = 0;

    const config = {
        title: 'Alert',
        content: (
            <>
                <ReachableContext.Consumer>{name => `Reachable: ${name}!`}</ReachableContext.Consumer>
                <br />
                <UnreachableContext.Consumer>{name => `Unreachable: ${name}!`}</UnreachableContext.Consumer>
            </>
        ),
    };


    return (
        <>
            <Card title={Title}>
                {CisUI().showLoading}
                {
                    isEdit === 1
                        ? CisUI().addAction('../../' + jsonfile.urls.list)
                        : CisUI().addAction('../' + jsonfile.urls.list)
                }

                <div className="row">
                    <div className="col-md-12" style={{textAlign: 'center'}}>
                        <div style={{width: '100%', background: 'white', border: '1px #ddd solid', padding: '20px'}}>
                            <table style={{width: '100%'}}>
                                <tr>
                                    <td style={{width: '50%', textAlign: 'left'}}>
                                        <img alt="cop" style={{width: '300px'}} src={Config.print_logourl}/>
                                        {/*<div>{Config.address}</div>*/}
                                        {/*<div>{Config.city},{Config.state},{Config.zipcode}</div>*/}
                                        {/*<div>Phone : {Config.phone}</div>*/}
                                        {/*<div>Email : {Config.email}</div>*/}
                                        {/*<div>Website : {Config.website}</div>*/}
                                    </td>
                                    <td style={{width: '50%', textAlign: 'right'}}>
                                        <h2>Invoice</h2>
                                        <div>Date : {CisUI().DateFormat(state.po.date)}</div>
                                        <div>Invoice Number : {state.po.invoice_number}</div>
                                        <div><Barcode height={50} value={state.po.invoice_number}/></div>
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{width: '50%', textAlign: 'left', paddingTop: '20px'}}>
                                        <h3>Company Information : </h3>
                                        <h5>{state.po.company}</h5>
                                        <div>{state.po.com_address}</div>
                                        <div>{state.po.com_city}, {state.po.com_state}, {state.po.com_zipcode}</div>
                                        <div>Phone : {state.po.company_phone}</div>
                                        <div>Email : {state.po.company_email}</div>
                                    </td>
                                    <td style={{width: '50%', textAlign: 'left', paddingTop: '20px'}}>
                                        <h3>Customer Information : </h3>
                                        <h5>{state.po.customer_name}</h5>
                                        <div>{state.po.customer_address}</div>
                                        <div>{state.po.customer_city}, {state.po.customer_state}, {state.po.customer_zipcode}</div>
                                        <div>Phone : {state.po.customer_phone}</div>
                                        <div>Email : {state.po.customer_email}</div>
                                    </td>
                                </tr>
                            </table>

                            <table id="itemtable" style={{marginTop: '30px'}} className="ptable table table-bordered">
                                <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Item Code</th>
                                    <th>Item Description</th>
                                    <th>Quantity</th>
                                    <th>Unit Price($)</th>
                                    <th>Amount</th>
                                </tr>
                                </thead>
                                <tbody>
                                {state.items.map((item, idx) => (
                                    <tr id="addr0" key={idx}>
                                        <td style={{width: '30px'}}>{(idx + 1)}</td>
                                        <td style={{width: '150px'}}>{item.code}</td>
                                        <td>{item.name}</td>
                                        <td style={{width: '70px', textAlign: 'right'}}>{item.quantity}</td>
                                        <td style={{
                                            width: '70px',
                                            textAlign: 'right'
                                        }}>{CisUI().getCurrencyFormated1(item.price)}</td>
                                        <td style={{
                                            width: '70px',
                                            textAlign: 'right'
                                        }}>{CisUI().getCurrencyFormated1((item.price * item.quantity))}</td>
                                    </tr>
                                ))}
                                </tbody>
                                <tfoot>
                                <tr>
                                    <td colSpan={3} rowSpan={7} style={{verticalAlign: "top"}}></td>
                                    <td style={{textAlign: 'right', fontWeight: 'bold'}} colSpan={2}>Total</td>
                                    <td style={{
                                        textAlign: 'right',
                                        fontWeight: 'bold'
                                    }}>{CisUI().getCurrencyFormated1(state.po.total)}</td>
                                </tr>
                                <tr>
                                    <td style={{textAlign: 'right', fontWeight: 'bold'}} colSpan={2}>Paid Amount</td>
                                    <td style={{
                                        textAlign: 'right',
                                        fontWeight: 'bold'
                                    }}>{CisUI().getCurrencyFormated1(state.po.paid_amount)}</td>
                                </tr>
                                <tr>
                                    <td style={{textAlign: 'right', fontWeight: 'bold'}} colSpan={2}>Balance Due</td>
                                    <td style={{
                                        textAlign: 'right',
                                        fontWeight: 'bold'
                                    }}>{CisUI().getCurrencyFormated1(state.po.total - state.po.paid_amount)}</td>
                                </tr>
                                </tfoot>
                            </table>

                        </div>
                    </div>
                </div>

                <div className="row no-print">
                    <div className="col-md-12" style={{textAlign: 'center', marginTop: '30px'}}>

                        <Button id="submitbutton" onClick={print} type="primary" htmlType="button">Print</Button>
                        <Button id="submitbutton" type="primary" htmlType="submit">Send to Customer</Button>
                        <Button id="submitbutton" onClick={showModal} type="primary" htmlType="submit">Send to Anyone</Button>
                        {
                            state.po.status === 1 ?
                                <>
                                    <Button id="submitbutton" type="primary" htmlType="button"><Link to={"../../crm/payment/"+userID}>
                                        {
                                            state.hasPayment === 0 ? "Payment Receive" : "Payment Details"
                                        }
                                    </Link></Button>
                                </>
                                :""
                        }
                        <Button type="info" onClick={() => history.goBack()} htmlType="back">Back</Button>
                    </div>
                </div>
            </Card>
            <Modal title="Send To Anyone" visible={isModalVisible} okText={"Send"} onOk={handleOk} onCancel={handleCancel}>
                <Form
                    form={form}
                    {...CisUI().formItemLayout}
                    name="input"
                    ref={formRef}
                    className="ant-advanced-search-form"
                    onFinish={onFinish}>

                    <div className="row">
                        <div className="col-md-12">
                            <Form.Item
                                label="Email Address"
                                name="email"
                                onChange={handleChange}
                                rules={[
                                    {
                                        required: true,
                                        message: "Enter email addres, separate by comma for multiple email "
                                    },
                                ]}
                            >
                                <Input placeholder="Enter email address" />
                            </Form.Item>
                        </div>
                    </div>

                </Form>
            </Modal>

            <Modal title="Alert" visible={isConfirmModalVisible} okText={"Confirm"} onOk={handleOkConfirm} onCancel={handleCancelConfirm}>
                <h4>Are you sure you want to approve ?</h4>
            </Modal>

            <Modal title="Alert" visible={isRejectModalVisible} okText={"Confirm"} onOk={handleOkReject} onCancel={handleCancelReject}>
                <h4>Are you sure you want to reject ?</h4>
            </Modal>

        </>
    );
};

export default InvoiceDetails;