import {Form, Row, Col, Input, Button, DatePicker, Card, Select, Checkbox,notification, Spin} from 'antd';
import React, {Component,useState, useEffect} from "react";
import jsonfile from './billpayment.json'
import {CisUI} from '../../../../util/CISUI'
import PostToApi from '../../../../util/PostToApi'
import InputFields from '../../../../util/InputField'
import 'react-smart-data-table/dist/react-smart-data-table.css'
import Config from "../../../../util/config";
import axios from "../../../../util/Api";
import {useHistory} from "react-router-dom";
import moment from "moment";
import 'moment/locale/es-us';
import RemoteJson from "../../../../util/gerremotedata";

const {Option} = Select;
const {TextArea} = Input;
const queryString = require('query-string');


const NewBillPaymnet = (props) => {

    const formRef = React.createRef();
    const [loading, setLoading] = useState([]);
    const [state, setState] = useState([]);
    const [dvalue, setDvalue] = useState({
        reference : "",
        amount : state.amount,
        remark : "",
    });
    const [paymentmethod, setPaymentmethod] = useState([]);
    const [bankaccounts, setBankAccounts] = useState([]);

    const handleChange=(e)=> {
        this.setState({
            [e.target.id]: e.target.value
        })
    }

    const userID = props.match.params.id;
    let userData = dvalue;
    // console.log("Userid : "+props.match.params.id);
    let isEdit = 1;
    if(userID > 0) {
        isEdit = 1;
    }

    const parsed = queryString.parse(props.location.search);

    const po = parsed.bill_id;
    let PORData = [];
    let poid = 0;
    if(po > 0) {
        poid = po;
    }

    let Title = jsonfile.addtitle;
    let endpoint = jsonfile.urls.add;
    let redirectto = "../"+jsonfile.urls.list;

    if(isEdit === 1 ) {
        userData = RemoteJson(jsonfile.urls.view+"/"+userID);
        Title = jsonfile.edittitle;
        endpoint = jsonfile.urls.add;
        redirectto = "../../"+jsonfile.urls.list;
    }


    const [form] = Form.useForm();
    const history = useHistory();

    const onFinish = (values) => {
        setLoading({loading:true})
        values["bill_id"] = state.id;
        values["bill_no"] = state.bill_no;
        console.log("Input Data : "+values);
        // console.log(values);
        let msg = "Sorry! request not processed, please try again";
        if(values['amount'] <= state.amount) {
            axios.post(Config.apiserver + endpoint, values, CisUI().HeaderRequest)
                .then(res => {
                    console.log(res)
                    if (res.data.status === 1) {
                        notification.success({
                            message: 'Success',
                            type: "success",
                            description: res.data.msg
                        });
                        history.push("../../crm/invoicelist");
                    } else {
                        notification.warning({
                            message: 'Alert',
                            type: "warning",
                            description: res.data.msg
                        });
                    }
                    setLoading({loading: false});
                })
                .catch(errors => {
                    console.log(errors.response.data.errors);
                    // msg = (errors);
                    setLoading({loading: false});
                    //setResponse([]);
                });
        }
        else {
            notification.warning({
                message: 'Alert',
                type: "warning",
                description: "Entering amount should not be more than actual bill amount"
            });
        }
    };

    let PaymentMethod = [];
    let BankAccounts = [];

    useEffect(() => {
        axios.get(Config.apiserver + "vendor/billdetails/" + poid,"",CisUI().HeaderRequest)
            .then((res) => {
                console.log(res.data);
                if (res.data.status === 1) {
                    setState(res.data.data);
                    setPaymentmethod(res.data.paymentMethod);
                    setBankAccounts(res.data.banakAccounts);
                    setDvalue({
                        reference : "",
                        amount : res.data.data.amount,
                        remark : "",
                    });

                    formRef.current.setFieldsValue({
                       amount : res.data.data.amount
                    });
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    //console.log("UserData : "+userData);

    const onPaymentMethodChange = (value) => {
        console.log("mehtod" + value);
        if(value === 2) {
            document.getElementById("bankaccount").style.display = "block";
        }
        else {
            document.getElementById("bankaccount").style.display = "none";
        }
        formRef.current.setFieldsValue({
            bank_account_id : null,
            reference : ""
        });
    }

    const onBankAccountChange = (value) => {
        let ref = "";
        bankaccounts.map((items, index) => {
            if(items.id === value) {
                if(items.last_issued_check > 0) {
                    ref = parseInt(items.last_issued_check) + 1;
                }
                else {
                    ref = items.starting_check_no;
                }
            }
        });

        console.log("RefNo : "+ ref);

        formRef.current.setFieldsValue({
            reference : ref,
            amount : state.amount,
            remark : "",
        });
    }

    return (
        <Card title={Title}>
            {
                isEdit === 1
                    ? CisUI().addAction('../../'+jsonfile.urls.list)
                    : CisUI().addAction('../'+jsonfile.urls.list)
            }
            <Form
                form={form}
                {...CisUI().formItemLayout}
                name="input"
                ref={formRef}
                className="ant-advanced-search-form"
                onFinish={onFinish}
                initialValues={{ remember: true }}
            >

                <div className="row" style={{padding: '20px'}}>
                    <div className="col-md-4">
                        <label style={{fontWeight: 'bold'}}>Bill Number : </label>
                        <span> {state.bill_no}</span>
                    </div>
                    <div className="col-md-4">
                        <label style={{fontWeight: 'bold'}}>Description : </label>
                        <span> {state.description}</span>
                    </div>
                    <div className="col-md-4">
                        <label style={{fontWeight: 'bold'}}>Date : </label>
                        <span> {CisUI().DateFormat(state.date)}</span>
                    </div>
                    <div className="col-md-4">
                        <label style={{fontWeight: 'bold'}}>Due Date : </label>
                        <span> {CisUI().DateFormat(state.due_date)}</span>
                    </div>
                    <div className="col-md-4">
                        <label style={{fontWeight: 'bold'}}>Reference No# : </label>
                        <span> {state.reference_no}</span>
                    </div>
                    <div className="col-md-4">
                        <label style={{fontWeight: 'bold'}}>Accounts Payable :  </label>
                        <span> {state.payable}</span>
                    </div>
                    <div className="col-md-4">
                        <label style={{fontWeight: 'bold'}}>Account : </label>
                        <span> {state.accounts_head}</span>
                    </div>
                    <div className="col-md-4">
                        <label style={{fontWeight: 'bold'}}>Fund Program : </label>
                        <span> {state.fund_program}</span>
                    </div>
                    <div className="col-md-4">
                        <label style={{fontWeight: 'bold'}}>Functional Expense : </label>
                        <span> {state.functional_expense}</span>
                    </div>
                    <div className="col-md-4">
                        <label style={{fontWeight: 'bold'}}>Bill Amount : </label>
                        <span style={{color: 'green'}}> {CisUI().getCurrencyFormated1(state.amount)}</span>
                    </div>
                    <div className="col-md-4">
                        <label style={{fontWeight: 'bold'}}>Remark : </label>
                        <span> {state.remark}</span>
                    </div>
                    <div className="col-md-4">
                        <label style={{fontWeight: 'bold'}}>Created By : </label>
                        <span> {state.created_by}</span>
                    </div>
                    <div className="col-md-4">
                        <label style={{fontWeight: 'bold'}}>Created at : </label>
                        <span> {state.created_at}</span>
                    </div>
                    <div className="col-md-4">
                        <label style={{fontWeight: 'bold'}}>Updated at : </label>
                        <span> {state.updated_at}</span>
                    </div>
                    <div className="col-md-4">
                        <label style={{fontWeight: 'bold'}}>Approved/Reject By : </label>
                        <span> {state.approved_user}</span>
                    </div>
                    <div className="col-md-4">
                        <label style={{fontWeight: 'bold'}}>Approved/Reject at : </label>
                        <span> {state.approved_at}</span>
                    </div>
                    <div className="col-md-4">
                        <label style={{fontWeight: 'bold'}}>Bill Status : </label>
                        <span> {CisUI().getBillSatus(state.status)}</span>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-4">
                        <Form.Item
                            name="date"
                            label="Payment Date"
                            initialValue={moment()}
                            rules={[
                                {
                                    required: true,
                                    message: "Select a date"
                                },
                            ]}
                        >
                            <DatePicker format={CisUI().dateFormat} className="gx-mb-3 gx-w-100"
                                        placeholder="Date"/>
                        </Form.Item>
                    </div>
                    <div className="col-md-4">
                        <Form.Item
                            name="payment_method"
                            label="Payment Method"
                            initialValue={1}
                            rules={[
                                {
                                    required: true,
                                    message: "Select payment method"
                                },
                            ]}
                        >
                            <Select
                                showSearch
                                placeholder="Select a payable account"
                                optionFilterProp="children"
                                onChange={onPaymentMethodChange}
                                allowClear={true}
                                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                            >
                                {
                                    paymentmethod.map((items, index) =>
                                        <Option key={++index} value={items.id}>{items.name}</Option>
                                    )
                                }
                            </Select>
                        </Form.Item>
                    </div>

                    <div className="col-md-4" id="bankaccount" style={{display: 'none'}}>
                        <Form.Item
                            name="bank_account_id"
                            label="Bank Account"
                            rules={[
                                {
                                    required: false,
                                    message: "Select a bank account"
                                },
                            ]}
                        >
                            <Select
                                showSearch
                                placeholder="Select a bank account"
                                optionFilterProp="children"
                                onChange={onBankAccountChange}
                                allowClear={true}
                                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                            >
                                {
                                    bankaccounts.map((items, index) =>
                                        <Option key={++index} value={items.id}>{items.account_no}-{items.account_name}({items.bank_name})</Option>
                                    )
                                }
                            </Select>
                        </Form.Item>
                    </div>

                </div>

                {/*{InputFields(jsonfile.input,userData,isEdit,props)}*/}
                {InputFields(jsonfile.input,dvalue,isEdit,props)}
            </Form>
        </Card>
    );
};

export default NewBillPaymnet;