import {Form, Row, Col, Input, InputNumber, DatePicker, Button, Card, Select, Checkbox, notification, Upload} from 'antd';
import React, {Component,useState, useEffect} from "react";
import jsonfile from './localexpenses.json';
import jsonMemberfile from './../member/member.json';
import {CisUI} from '../../../../util/CISUI';
import PostToApi from '../../../../util/PostToApi';
import InputFields from '../../../../util/InputField';
import 'react-smart-data-table/dist/react-smart-data-table.css';
import Config from "../../../../util/config";
import axios from "../../../../util/Api";
import {useHistory} from "react-router-dom";
import RemoteJson from "../../../../util/gerremotedata";
import SelectData from "../../../../util/selectdata";
import StaticSelectData from "../../../../util/StaticSelectData";
import $ from "jquery";
import InputOnly from "../../../../util/InputOnly";
import getRemoteJSON from "../../../../util/getremotejson";
const { Option } = Select;
// const Option = Select.Option;
const {TextArea} = Input;


const NewExpenses = (props) => {

    const formRef = React.createRef();

    const [loading, setLoading] = useState([]);
    const [inputList, setInputList] = useState([]);


    const [form] = Form.useForm();

    let endpoint = jsonfile.urls.add;
    const redirectto = "../../../"+jsonfile.urls.list;
    const history = useHistory();

    const handleChange=(e)=> {
        this.setState({
            [e.target.id]: e.target.value
        })
    }

    const userID = props.match.params.id;
    let userData = [];
    console.log("Userid : "+props.match.params.id);
    let isEdit = 0;
    if(userID > 0) {
        isEdit = 1;
    }
    let Title = jsonfile.addtitle;
    if(isEdit === 1 ) {
        userData = RemoteJson(jsonfile.urls.view+"/"+userID);
        Title = jsonfile.edittitle;
        endpoint = jsonfile.urls.edit+"/"+userID;
    }


    const onFinish = (values) => {
        setLoading({loading:true})
        console.log("Input Data : "+values);
        console.log("ExpenseData : "+inputList);

        const newArray = inputList.filter(value => JSON.stringify(value) !== '{}');

        console.log("AfterFilter : "+inputList);
        values['expenses'] = newArray;
        let msg = "Sorry! request not processed, please try again";
        axios.post(Config.apiserver + endpoint, values)
            .then(res => {
                console.log(res)
                if(res.data.status === 1) {
                    notification.warning({
                        message: 'Alert',
                        type : "warning",
                        description: res.data.msg
                    });
                    history.push(redirectto);
                }
                else {
                    notification.warning({
                        message: 'Alert',
                        type : "warning",
                        description: res.data.msg
                    });
                }
                setLoading({loading:false});
            })
            .catch(errors => {
                console.log(errors);
                // msg = (errors);
                setLoading({loading:false});
                //setResponse([]);
            });
    };

    const dataOptions = SelectData("donation/expensetype?area=6");

    // useEffect(() => {
    //     setInputList(dataOptions);
    // }, []);

    const Tc = dataOptions.length;
    const dataex = [];
    dataOptions.map((value,index) => {
        dataex[index] = {
            expensetype : value.id,
            amount : '',
            note : '',
            file : ''
        };
    });

    useEffect(() => {
        setInputList(dataex);
    }, []);


    //console.log("UserData : "+userData);

    const handleInputChange = (e, index) => {
        if(e && e.target) {
            const {name, value} = e.target;

            const list = [...inputList, { }];

            list[index]["expensetype"] = dataOptions[index].id;
            list[index][name] = value;
            setInputList(list);
            //list[index]["expensetype"] = dataOptions[index].id;
            setInputList(list);
           // console.log(JSON.stringify(inputList));
        }
        else {
            const list = [...inputList];
            list[index]["expensetype"] = e;
            setInputList(list);
        }
    };

// handle click event of the Remove button
    const handleRemoveClick = index => {
        const list = [...inputList];
        list.splice(index, 1);
        setInputList(list);
    };

// handle click event of the Add button
    const handleAddClick = () => {
        setInputList([...inputList, { }]);
    };



    return (
        <Card title={Title}>
            {
                isEdit === 1
                    ? CisUI().addAction("../../"+jsonfile.urls.list)
                    : CisUI().addAction("../"+jsonfile.urls.list)
            }
            <Form
                form={form}
                ref={formRef}
                {...CisUI().formItemLayout}
                name="input"
                className="ant-advanced-search-form"
                onFinish={onFinish}
            >
                {InputOnly(jsonfile.input,userData,isEdit)}

                <Row gutter={24}>
                    <table className="table table-bordered">
                        <thead>
                            <tr>
                                <th>Account Name</th>
                                <th>Amount</th>
                                <th>Note</th>
                                <th>Attachment</th>
                            </tr>
                        </thead>

                    {dataOptions.map((x, i) => {
                        return (
                            <>
                                <tr>
                                    <td>{x.name}
                                    <input type="hidden" id={"expensetype_"+x.id} name="expensetype" value={x.id} />
                                    </td>
                                    <td>
                                        <input
                                            className="ant-input"
                                            name="amount"
                                            id={"amount_"+x.id}
                                            placeholder="Amount"
                                            value={x.amount}
                                            onChange={e => handleInputChange(e, i)}
                                        />
                                    </td>
                                    <td>
                                        <input
                                            className="ant-input"
                                            name="note"
                                            id={"note_"+x.id}
                                            placeholder="Enter a note"
                                            value={x.note}
                                            onChange={e => handleInputChange(e, i)}
                                        />
                                    </td>
                                    <td>
                                        <input
                                            className="ant-input"
                                            name="file"
                                            id={"file_"+x.id}
                                            type="file"
                                            value={x.file}
                                            onChange={e => handleInputChange(e, i)}
                                        />
                                    </td>
                                </tr>
                            </>
                        );
                    })}

                    </table>

                </Row>

                <Row gutter={24}>
                    <Col lg={24} md={24} sm={12} xs={24} style={{textAlign: 'center', marginTop : '30px'}}>
                        <Button id="submitbutton" type="primary" htmlType="submit">
                            Submit
                        </Button>
                        <Button type="info" onClick={() => history.goBack()} htmlType="back">Cancel</Button>
                    </Col>
                </Row>

            </Form>


        </Card>
    );
};

export default NewExpenses;