import React, {useState} from "react";
import {Row, Col, Form, Card, Input, Select, InputNumber, DatePicker, Table, Popconfirm, Upload, Button} from 'antd';
import jsonfile from './documents.json';
import {useHistory} from "react-router-dom";
import RemoteJson from "../../../../util/gerremotedata";
import InputFieldV2 from "../../../../util/InputFieldV2";
import StaticSelectData from "../../../../util/StaticSelectData";
import {CisUI} from "../../../../util/CISUI";
import moment from 'moment';
import { UploadOutlined, InboxOutlined } from '@ant-design/icons';

const {Option} = Select;
const {TextArea} = Input
const SkillInfo = ({props}) => {

    // console.log(props);

    const history = useHistory();
    const {form} = Form.useForm();

    const userID = props;
    let userData = [];
    // console.log("Userid : "+props.match.params.id);
    let isEdit = 0;
    if (userID > 0) {
        isEdit = 1;
    }
    let Title = jsonfile.addtitle;
    let endpoint = jsonfile.urls.add;
    let redirectto = "../" + jsonfile.urls.list;

    if (isEdit === 1) {
        userData = RemoteJson(jsonfile.urls.view + "/" + userID);
        Title = jsonfile.edittitle;
        endpoint = jsonfile.urls.edit + "/" + userID;
        redirectto = "../../" + jsonfile.urls.list;
    }

    const normFile = e => {
        console.log('Upload event:', e);

        if (Array.isArray(e)) {
            return e;
        }

        return e && e.fileList;
    };

    const columns = [
        {
            title: '#',
            dataIndex: 'operation',
            render: (_, record) =>
                data.dataSource.length >= 1 ? (
                    <Popconfirm title="Sure to delete?" onConfirm={() => handleDelete(record.key)}>
                        <a className="btn btn-primary btn-circle"><i className="fa fa-trash"></i></a>
                    </Popconfirm>
                ) : null,
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Details',
            dataIndex: 'details',
            key: 'details',
        },
        {
            title: 'Issue Date',
            dataIndex: 'issue_date',
            key: 'issue_date',
        },
        {
            title: 'Expire Date',
            dataIndex: 'expire_date',
            key: 'expire_date',
        },
        {
            title: 'Attachment',
            dataIndex: 'attachment',
            key: 'attachment',
        }
    ];

    const [data, setData] = useState({
        count: 2,
        dataSource: [
            {
                key: '1',
                name: <Form.Item name={columns.dataIndex}>
                        <Input placeholder="Name"/>
                    </Form.Item>,
                details: <Form.Item name={columns.dataIndex}>
                            <TextArea className="gx-mt-3 gx-w-100" placeholder="Details"/>
                        </Form.Item>,
                issue_date: <Form.Item name={columns.dataIndex}>
                                <DatePicker className="gx-w-100" format={CisUI().dateFormat} placeholder="Issue Date"/>
                            </Form.Item>,
                expire_date: <Form.Item name={columns.dataIndex}>
                                <DatePicker className="gx-w-100" format={CisUI().dateFormat} placeholder="Issue Date"/>
                            </Form.Item>,
                attachment: <Form.Item
                    name={columns.dataIndex}
                    valuePropName="fileList"
                    getValueFromEvent={normFile}
                >
                    <Upload name="logo" listType="picture">
                        <Button>
                            <UploadOutlined /> Click to upload
                        </Button>
                    </Upload>
                </Form.Item>,
            }
        ]
    });


    const handleDelete = (key) => {
        const dataSource = [...data.dataSource];
        setData({
            dataSource: dataSource.filter((item) => item.key !== key),
        });

    };

    const handleAdd = () => {
        const { count, dataSource } = data;
        const newData =  {
            key: count,
            name: <Form.Item name={columns.dataIndex}>
                    <Input placeholder="Name"/>
                </Form.Item>,
            details: <Form.Item name={columns.dataIndex}>
                <TextArea className="gx-mt-3 gx-w-100" placeholder="Details"/>
            </Form.Item>,
            issue_date: <Form.Item name={columns.dataIndex}>
                <DatePicker className="gx-w-100" format={CisUI().dateFormat} placeholder="Issue Date"/>
            </Form.Item>,
            expire_date: <Form.Item name={columns.dataIndex}>
                <DatePicker className="gx-w-100" format={CisUI().dateFormat} placeholder="Issue Date"/>
            </Form.Item>,
            attachment: <Form.Item
                name={columns.dataIndex}
                valuePropName="fileList"
                getValueFromEvent={normFile}
            >
                <Upload name="logo" action="/upload.do" listType="picture">
                    <Button>
                        <UploadOutlined /> Click to upload
                    </Button>
                </Upload>
            </Form.Item>,
        };
        setData({
            dataSource: [...dataSource, newData],
            count: count + 1,
        });
    };


    return (
        <Card title={Title}>
            <div className="row">
                <div className="col-md-12">
                    <div className="row">
                        <div className="col-md-6">
                            {/*<h2>Purchase Items</h2>*/}
                        </div>
                        <div className="col-md-6" style={{textAlign : "right"}}>
                            <a className="btn btn-primary" onClick={handleAdd}>Add More</a>
                        </div>
                    </div>

                    <Table
                        bordered
                        className="gx-table-responsive"
                        dataSource={data.dataSource}
                        columns={columns}
                        pagination={false}
                    />

                </div>
            </div>
        </Card>
    );
};

export default SkillInfo;