import {Form, Row, Col, Input, Button, Card, Collapse, Select, Checkbox,notification, Spin} from 'antd';
import React, {Component,useState, useEffect} from "react";
import jsonfile from './company.json'
import jsonUserfile from './../../user_management/user/user.json'
import {CisUI} from '../../../../util/CISUI'
import PostToApi from '../../../../util/PostToApi'
import InputFields from '../../../../util/InputField'
import 'react-smart-data-table/dist/react-smart-data-table.css'
import Config from "../../../../util/config";
import axios from "../../../../util/Api";
import {useHistory} from "react-router-dom";
import RemoteJson from "../../../../util/gerremotedata";
import AdvancedSearch from "../../../../util/AdvancedSearch";
import ActionButton from "../../../../util/actionbutton";
import IntlMessages from "../../../../util/IntlMessages";

import SmartDataTable from 'react-smart-data-table'
import 'react-smart-data-table/dist/react-smart-data-table.css'
import SelectData from "../../../../util/selectdata";


const Search = Input.Search;
const Panel = Collapse.Panel;
const Meta = Card.Meta;

const { Option } = Select;


const NewCompany = (props) => {

    const formRef = React.createRef();
    const [loading, setLoading] = useState([]);
    const [diplay, setDisplay] = useState({
        region: "none",
        district: "none",
        local: "none",
    });


    const [form] = Form.useForm();

    const history = useHistory();

    const handleChange=(e)=> {
        this.setState({
            [e.target.id]: e.target.value
        })
    }

    const userID = props.match.params.id;
    let userData = [];
    console.log("Userid : "+props.match.params.id);
    let isEdit = 0;
    if(userID > 0) {
        isEdit = 1;
    }
    let Title = jsonfile.addtitle;
    let endpoint = "company/newcompany";
    let redirectto = "../"+jsonfile.urls.list;


    const [state,setState] = useState({
        reqURL: Config.apiserver+jsonUserfile.urls.list+"/"+userID,
        filtervalue : '',
        area : null
    });
    let areaReq = true;
    if(isEdit === 1 ) {
        userData = RemoteJson(jsonfile.urls.view+"/"+userID);
        Title = jsonfile.edittitle;
        endpoint = "company/companyedit/"+userID;
        redirectto = '../../'+jsonfile.urls.list;
        areaReq = false;
    }

    useEffect(() => {
        setState(
            {
                reqURL: Config.apiserver+jsonUserfile.urls.list+"/"+userID,
                filtervalue : '',
                area : userData.area
            }
        );
        // formRef.current.setFieldsValue({
        //    area :  userData.area
        // });

        form.setFieldsValue({ area: userData.area })

    }, []);


    const onFinish = (values) => {
        setLoading({loading:true})
        console.log("Input Data : "+values);

        let pass = 0;
        if(values['area'] == 4) {
            if(values['region'] > 0) {
                pass = 1;
            }
            else {
                // notification.warning({
                //     message: 'Alert',
                //     type : "warning",
                //     description: "Please select a region"
                // });
                pass = 1;
            }
        }
        else if(values['area'] == 5) {
            if(values['region'] > 0) {
                if(values['district']>0) {
                    pass = 1;
                }
                else {
                    // notification.warning({
                    //     message: 'Alert',
                    //     type : "warning",
                    //     description: "Please select a district"
                    // });
                    pass = 1;
                }
            }
            else {
                notification.warning({
                    message: 'Alert',
                    type : "warning",
                    description: "Please select a region & district"
                });
            }
        }
        else if(values['area'] == 6) {
            if(values['region'] > 0) {
                if(values['district']>0) {
                    if(values['local'] >0) {
                        pass = 1
                    }
                    else {
                        // notification.warning({
                        //     message: 'Alert',
                        //     type : "warning",
                        //     description: "Please select a local"
                        // });
                        pass = 1;
                    }
                }
                else {
                    notification.warning({
                        message: 'Alert',
                        type : "warning",
                        description: "Please select a district"
                    });
                }
            }
            else {
                notification.warning({
                    message: 'Alert',
                    type : "warning",
                    description: "Please select a region, district & local"
                });
            }
        }

        if(pass == 1) {

            let msg = "Sorry! request not processed, please try again";
            axios.post(Config.apiserver + endpoint, values)
                .then(res => {
                    // console.log(res)
                    if (res.data.status === 1) {
                        notification.success({
                            message: 'Success',
                            type: "warning",
                            description: res.data.msg
                        });
                        history.push(redirectto);
                    } else {
                        notification.warning({
                            message: 'Alert',
                            type: "warning",
                            description: res.data.msg
                        });
                    }
                    setLoading({loading: false});
                })
                .catch(errors => {
                    console.log(errors);
                    // msg = (errors);
                    setLoading({loading: false});
                    //setResponse([]);
                });
        }
    };

    let  searchView = "";
    if(jsonfile.has_search) {
        searchView = <Card className="advance-search-card">
            <Collapse
                accordion
                expandIconPosition="right"
                defaultActiveKey={['1']} >
                <Panel header="Advance Search"  key="1">
                    <Form
                        key={5}
                        form={form}
                        {...CisUI().formItemLayout}
                        name="advanced_search"
                        className="ant-advanced-search-form"
                        onFinish={onFinish}
                    >
                        {AdvancedSearch(jsonfile.input)}
                    </Form>
                </Panel>
            </Collapse>
        </Card>
    }

    const handleOnFilter = ({ target: { name, value } }) => {
        //filterValue = value;
        console.log("filteringwith :"+ value);
        setState({filtervalue : "test"});
    }

    const headers = {
        // _id: {
        //     text: '#',
        //     invisible: false,
        //     filterable: false,
        //     transform: (value) => `${value++}`,
        // },
        id: {
            text: <IntlMessages id="ID"/>,
            sortable: false,
            filterable: true,
            invisible : true
        },
        photo: {
            text: <IntlMessages id="Photo"/>,
            sortable: false,
            filterable: false,
            invisible: true
        },
        area: {
            text: <IntlMessages id="Access Level"/>,
            sortable: true,
            filterable: true,
        },
        name: {
            text: <IntlMessages id="Fullname"/>,
            sortable: true,
            filterable: true,
        },
        company: {
            text: <IntlMessages id="Company"/>,
            sortable: true,
            filterable: true,
            invisible: true
        },
        phone: {
            text: <IntlMessages id="Phone"/>,
            sortable: true,
            filterable: true,
        },
        email: {
            text: <IntlMessages id="Email"/>,
            sortable: true,
            filterable: true,
        },
        username: {
            text: <IntlMessages id="Username"/>,
            sortable: true,
            filterable: true,
        },
        position: {
            text: <IntlMessages id="Position"/>,
            sortable: true,
            filterable: true,
        },
        address: {
            text: <IntlMessages id="Address"/>,
            sortable: true,
            filterable: true,
            invisible: true
        },
        city: {
            text: <IntlMessages id="City"/>,
            sortable: true,
            filterable: true,
            invisible: true
        },
        status: {
            text: <IntlMessages id="Status"/>,
            sortable: true,
            filterable: true,
            transform: (value, idx, row) => (
                CisUI().getStatusLabel(value)
            )
        },
        actions: {
            text: 'Actions',
            sortable: false,
            filterable: false,
            textAlign: "center",
            transform: (value, idx, row) => (
                <>
                    {ActionButton(row.id,jsonUserfile.urls,"","../../")}
                </>
            ),
        },
    };

    const orderedHeaders = [
        'name',
        'username',
        'phone',
        'email',
        'area',
        'position',
        'status',
        'actions'
    ];

    const getExtra = (
        <>
            {/*<Search*/}
            {/*    placeholder='Filter...'*/}
            {/*    name='filtervalue'*/}
            {/*    onSearch={handleOnFilter}*/}
            {/*    onChange={handleOnFilter}*/}
            {/*    style={{width: 200}}*/}
            {/*/>*/}
            {CisUI().listAction("../../"+jsonUserfile.urls.add,"?comid="+userID)}
        </>
    );

    const UserList = (
            <Card title="Users">
                <SmartDataTable
                    name='test-fake-table'
                    data={state.reqURL}
                    dataRequestOptions={CisUI().HeaderRequest}
                    dataKey="data"
                    headers={headers}
                    orderedHeaders={orderedHeaders}
                    hideUnordered={CisUI().hideUnordered}
                    className={CisUI().sematicUI.table}
                    filterValue={state.filtervalue}
                    perPage={CisUI().perPage}
                    sortable
                    withLinks
                    dynamic
                    withHeader
                    loader={CisUI().loader}
                    parseBool={{
                        yesWord: 'Yes',
                        noWord: 'No',
                    }}
                    parseImg={{
                        style: {
                            border: '1px solid #ddd',
                            borderRadius: '50px',
                            padding: '3px',
                            width: '30px',
                            height: '30px'
                        },
                    }}
                    emptyTable={CisUI().emptyTable}
                />
            </Card>
    )

    const AccountsLevel = SelectData("accounts/accountslevel");
    const Regions = SelectData("regions");
    const District = SelectData("districtbyregion");
    const Local = SelectData("localbydistrict");


    const [district, setDistrict] = useState([]);
    const [local, setLocal] = useState([]);


    const onAreaChange = value1 => {
        const value = formRef.current.getFieldValue("area");
        if(value == 4) {
            //console.log("value : "+value);
            setDisplay({
                region: "none",
                district: "none",
                local: "none",
            });
            //document.getElementById("region").setAttribute("required","required");
            //formRef.current.setRules("required",true);
            document.getElementById("region").removeAttribute("required");
            document.getElementById("district").removeAttribute("required");
            document.getElementById("local").removeAttribute("required");
        }
        else if(value == 5) {
            setDisplay({
                region: "block",
                district: "none",
                local: "none",
            });
            document.getElementById("region").setAttribute("required","required");
            //document.getElementById("district").setAttribute("required","required");
            document.getElementById("district").removeAttribute("required");
            document.getElementById("local").removeAttribute("required");
        }
        else if(value == 6) {
            setDisplay({
                region: "block",
                district: "block",
                local: "none",
            });
            document.getElementById("region").setAttribute("required","required");
            document.getElementById("district").setAttribute("required","required");
            //document.getElementById("local").setAttribute("required","required");
            document.getElementById("local").removeAttribute("required");
        }
        else {
            setDisplay({
                region: "none",
                district: "none",
                local: "none",
            });

            document.getElementById("region").removeAttribute("required");
            document.getElementById("district").removeAttribute("required");
            document.getElementById("local").removeAttribute("required");
        }
    };

    const onRegionChange = value => {
        setDistrict(District[value]);
    };

    const onDistrictChange = value => {
        setLocal(Local[value]);
    };

    //console.log("UserData : "+userData);

    const componentDidMount = () => {
        console.log("System Loaded");
    }

    return (
        <>
            <Card title={Title}>
                {
                    isEdit === 1
                        ? CisUI().addAction('../../'+jsonfile.urls.list)
                        : CisUI().addAction("../"+jsonfile.urls.list)
                }
                <Form
                    form={form}
                    ref={formRef}
                    {...CisUI().formItemLayout}
                    name="input"
                    className="ant-advanced-search-form"
                    onFinish={onFinish}
                    initialValues={{area: state.area}}
                >
                    <Row gutter={24}>

                                    <Col lg={6} md={6} sm={12} xs={24} >
                                    <div id="area" className="gx-form-row0">
                                        <Form.Item
                                            name="area"
                                            label="Level of Company"
                                            initialValue={state.area}
                                            rules={[
                                                {
                                                    required: areaReq,
                                                    message: "Select level of company"
                                                },
                                            ]}
                                        >
                                            <Select
                                                showSearch
                                                placeholder="Select level of company"
                                                optionFilterProp="children"
                                                onChange={onAreaChange}
                                                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                            >
                                                {
                                                    AccountsLevel.map((items, index) =>
                                                        <Option key={++index} value={items.id}>{items.name}</Option>
                                                    )
                                                }
                                            </Select>
                                        </Form.Item>

                                    </div>
                                    </Col>


                        <Col lg={6} md={6} sm={12} xs={24} id="region" style={{display: diplay.region}} >
                            <div className="gx-form-row0">
                                <Form.Item
                                    name="region"
                                    label="Region"
                                    rules={[
                                        {
                                            required: false,
                                            message: "Select a region"
                                        },
                                    ]}
                                >
                                    <Select
                                        showSearch
                                        placeholder="Select Region"
                                        optionFilterProp="children"
                                        onChange={onRegionChange}
                                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                    >
                                        {
                                            Regions.map((items, index) =>
                                                <Option key={++index} value={items.id} >{items.name}</Option>
                                            )
                                        }
                                    </Select>
                                </Form.Item>

                            </div>
                        </Col>

                        <Col lg={6} md={6} sm={12} xs={24} id="district" style={{display: diplay.district}} >
                            <div className="gx-form-row0">
                                <Form.Item
                                    name="district"
                                    label="District"
                                    rules={[
                                        {
                                            required: false,
                                            message: "Select a district"
                                        },
                                    ]}
                                >
                                    <Select
                                        showSearch
                                        placeholder="Select District"
                                        optionFilterProp="children"
                                        onChange={onDistrictChange}
                                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                    >
                                        {
                                            district.map((items, index) =>
                                                <Option key={++index} value={items.id} >{items.name}</Option>
                                            )
                                        }
                                    </Select>
                                </Form.Item>

                            </div>
                        </Col>

                        <Col lg={6} md={6} sm={12} xs={24} id="local" style={{display: diplay.local}} >
                            <div className="gx-form-row0">
                                <Form.Item
                                    name="local"
                                    label="Local Assembly"
                                    rules={[
                                        {
                                            required: false,
                                            message: "Select a local assembly"
                                        },
                                    ]}
                                >
                                    <Select
                                        showSearch
                                        placeholder="Select Local"
                                        optionFilterProp="children"
                                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                    >
                                        {
                                            local.map((items, index) =>
                                                <Option key={++index} value={items.id} >{items.name}</Option>
                                            )
                                        }
                                    </Select>
                                </Form.Item>

                            </div>
                        </Col>

                    </Row>

                    {InputFields(jsonfile.input,userData,isEdit)}
                </Form>

            </Card>

            {
                isEdit === 1
                ?UserList
                :''
            }


        </>
    );
};

export default NewCompany;