import React, {useState, useEffect} from "react";
import {Form, Card,notification, Row, Col, Button, Input, Select} from 'antd';
import jsonfile from './bank_account.json'
import {CisUI} from '../../../../util/CISUI'
import 'react-smart-data-table/dist/react-smart-data-table.css'
import Config from "../../../../util/config";
import axios from "../../../../util/Api";
import {useHistory} from "react-router-dom";
import RemoteJson from "../../../../util/gerremotedata";
import InputFieldV2 from "../../../../util/InputFieldV2";
import SelectData from "../../../../util/selectdata";

const {Option} = Select;

const NewBankAccount = (props) => {

    const [loading, setLoading] = useState([]);
    const [accounts, setCashAccount] = useState([]);
    const [check, setCheck] = useState({
        input_size_width : 810,
        input_size_height : 384,
        input_date_left : 605,
        input_date_top : 77,
        input_pay_to_top : 38,
        input_pay_to_left : 96,
        input_amount_top : 34,
        input_amount_left : 576,
        input_amount_word_left : 125,
        input_amount_word_top : -34,
        input_signature_left : 624,
        input_signature_top : 48,
        input_seal_left : 384,
        input_seal_top : -48,
    });

    const handleChange=(e)=> {
        this.setState({
            [e.target.id]: e.target.value
        })
    }

    const userID = props.match.params.id;
    let userData = [];
    // console.log("Userid : "+props.match.params.id);
    let isEdit = 0;
    if(userID > 0) {
        isEdit = 1;
    }
    let Title = jsonfile.addtitle;
    let endpoint = jsonfile.urls.add;
    let redirectto = "../"+jsonfile.urls.list;

    if(isEdit === 1) {
        userData = RemoteJson(jsonfile.urls.view+"/"+userID);
        Title = jsonfile.edittitle;
        endpoint = jsonfile.urls.edit+"/"+userID;
        redirectto = "../../"+jsonfile.urls.list;
    }

    const Companies = SelectData("company/companylists");

    const [form] = Form.useForm();
    const history = useHistory();
    const formRef = React.createRef();

    const onFinish = (values) => {
        setLoading({loading:true})
        console.log("Input Data : "+values);
        // console.log(values);
        let msg = "Sorry! request not processed, please try again";
        axios.post(Config.apiserver + endpoint, values, CisUI().HeaderRequest)
            .then(res => {
                console.log(res)
                if(res.data.status === 1) {
                    notification.success({
                        message: 'Success',
                        type : "success",
                        description: res.data.msg
                    });
                    history.push(redirectto);
                }
                else {
                    notification.warning({
                        message: 'Alert',
                        type : "warning",
                        description: res.data.msg
                    });
                }
                setLoading({loading:false});
            })
            .catch(errors => {
                console.log(errors.response.data.errors);
                // msg = (errors);
                setLoading({loading:false});
                //setResponse([]);
            });
    };

    const onChangeValue = e => {
        console.log(e);
    }

    const onCompanyChange = value => {
        console.log("selected : "+ value);
        let fl = Companies.filter(item => item.id === value);
        if(fl.length > 0) {
            const company = fl[0];
            axios.get("billentrydata?com_id="+value)
                .then((res) => {
                    console.log(res.data);
                    if(res.data.status === 1) {
                        setCashAccount(res.data.cashaccount);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }

    useEffect(() => {
        const comID = CisUI().getUserInfo('com_id');
        if(comID > 0) {
            onCompanyChangeForComapny(comID);
        }
    }, []);

    const onCompanyChangeForComapny = value => {
        console.log("selected : "+ value);
        if(value > 0) {
            const company = value;
            axios.get("billentrydata?com_id="+value)
                .then((res) => {
                    console.log(res.data);
                    if(res.data.status === 1) {
                        setCashAccount(res.data.cashaccount);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }

    const updateCompanyList = () => {
        axios.get("billentrydata?com_id="+userData.company_id)
            .then((res) => {
                console.log(res.data);
                if(res.data.status === 1) {
                    setCashAccount(res.data.cashaccount);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    //console.log("UserData : "+userData);

    return (
        <Card title={Title}>
            {
                isEdit === 1
                    ? CisUI().addAction('../../'+jsonfile.urls.list)
                    : CisUI().addAction('../'+jsonfile.urls.list)
            }
            <Form
                form={form}
                {...CisUI().formItemLayout}
                name="input"
                className="ant-advanced-search-form"
                onFinish={onFinish}
                initialValues={{ remember: true }}
            >

                <div className="row">
                    <div className="col-md-4">
                        <Form.Item
                            name="com_id"
                            label="Company"
                            rules={[
                                {
                                    required: true,
                                    message: "Select an company"
                                },
                            ]}
                        >
                            <Select
                                showSearch
                                placeholder="Select an company"
                                optionFilterProp="children"
                                onChange={onCompanyChange}
                                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                            >
                                {
                                    Companies.map((items, index) =>
                                        <Option key={++index} value={items.id}>{items.name}</Option>
                                    )
                                }
                            </Select>
                        </Form.Item>
                    </div>

                    <div className="col-md-4">
                        <Form.Item
                            name="account_id"
                            label="Account"
                            rules={[
                                {
                                    required: false,
                                    message: "Select an account"
                                },
                            ]}
                        >
                            <Select
                                showSearch
                                placeholder="Select an account"
                                optionFilterProp="children"
                                onChange={onChangeValue}
                                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                            >
                                {
                                    accounts.map((items, index) =>
                                        <Option key={++index} value={items.id}>{items.name}</Option>
                                    )
                                }
                            </Select>
                        </Form.Item>
                    </div>
                </div>
                {InputFieldV2(jsonfile.input,userData,isEdit,props)}

                <div style={{display : 'none'}}>
                {/*<Row gutter={24}>*/}
                    <Col lg={24} md={24} sm={24} xs={24}>
                        <h2 style={{background:"#e8e8e8", padding:"5px"}}>Check Setup (All input should be in pixel.)</h2>
                        <Row>
                            <Col lg={8} md={8} sm={24} xs={24}>
                                <Card title="Check Leaf Size">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="gx-form-row0">
                                                <Form.Item
                                                    name="size_width"
                                                    label="Width"
                                                    initialValue={check.input_size_width}
                                                    onChange={onChangeValue}
                                                >
                                                    <Input placeholder="Width"/>
                                                </Form.Item>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="gx-form-row0">
                                                <Form.Item
                                                    name="size_height"
                                                    label="Height"
                                                    initialValue={check.input_size_height}
                                                    onChange={onChangeValue}
                                                >
                                                    <Input placeholder="Height"/>
                                                </Form.Item>
                                            </div>
                                        </div>
                                    </div>
                                </Card>
                            </Col>
                        </Row>

                        <Row>
                            <Col lg={8} md={8} sm={24} xs={24}>
                                <Card title="DATE">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="gx-form-row0">
                                            <Form.Item
                                                name="date_left"
                                                label="Left"
                                                initialValue={check.input_date_left}
                                                onChange={onChangeValue}
                                            >
                                                <Input placeholder="Left Position"/>
                                            </Form.Item>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="gx-form-row0">
                                            <Form.Item
                                                name="date_top"
                                                label="Top"
                                                initialValue={check.input_date_top}
                                                onChange={onChangeValue}
                                            >
                                                <Input placeholder="Top Postion"/>
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>
                                </Card>
                            </Col>

                            <Col lg={8} md={8} sm={24} xs={24}>
                                <Card title="Pay To ">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="gx-form-row0">
                                            <Form.Item
                                                name="pay_to_left"
                                                label="Left"
                                                initialValue={check.input_pay_to_left}
                                                onChange={onChangeValue}
                                            >
                                                <Input placeholder="Left Position"/>
                                            </Form.Item>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="gx-form-row0">
                                            <Form.Item
                                                name="pay_to_top"
                                                label="Top"
                                                initialValue={check.input_pay_to_left}
                                                onChange={onChangeValue}
                                            >
                                                <Input placeholder="Top Postion"/>
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>
                                </Card>
                            </Col>

                            <Col lg={8} md={8} sm={24} xs={24}>
                                <Card title="Amount">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="gx-form-row0">
                                            <Form.Item
                                                name="amount_left"
                                                label="Left"
                                                initialValue={check.input_amount_left}
                                                onChange={onChangeValue}
                                            >
                                                <Input placeholder="Left Position"/>
                                            </Form.Item>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="gx-form-row0">
                                            <Form.Item
                                                name="amount_top"
                                                label="Top"
                                                initialValue={check.input_amount_top}
                                                onChange={onChangeValue}
                                            >
                                                <Input placeholder="Top Postion"/>
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>
                                </Card>
                            </Col>

                            <Col lg={8} md={8} sm={24} xs={24}>
                                <Card title="Amount In Word">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="gx-form-row0">
                                            <Form.Item
                                                name="amount_word_left"
                                                label="Left"
                                                initialValue={check.input_amount_word_left}
                                                onChange={onChangeValue}
                                            >
                                                <Input placeholder="Left Position"/>
                                            </Form.Item>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="gx-form-row0">
                                            <Form.Item
                                                name="amount_word_top"
                                                label="Top"
                                                initialValue={check.input_amount_word_top}
                                                onChange={onChangeValue}
                                            >
                                                <Input placeholder="Top Postion"/>
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>
                                </Card>
                            </Col>

                            <Col lg={8} md={8} sm={24} xs={24}>
                                <Card title="Signature">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="gx-form-row0">
                                            <Form.Item
                                                name="signature_left"
                                                label="Left"
                                                initialValue={check.input_signature_left}
                                                onChange={onChangeValue}
                                            >
                                                <Input placeholder="Left Position"/>
                                            </Form.Item>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="gx-form-row0">
                                            <Form.Item
                                                name="signature_top"
                                                label="Top"
                                                initialValue={check.input_signature_top}
                                                onChange={onChangeValue}
                                            >
                                                <Input placeholder="Top Postion"/>
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>
                                </Card>
                            </Col>

                            <Col lg={8} md={8} sm={24} xs={24}>
                                <Card title="Seal">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="gx-form-row0">
                                            <Form.Item
                                                name="seal_left"
                                                label="Left"
                                                initialValue={check.input_seal_left}
                                                onChange={onChangeValue}
                                            >
                                                <Input placeholder="Left Position"/>
                                            </Form.Item>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="gx-form-row0">
                                            <Form.Item
                                                name="seal_top"
                                                label="Top"
                                                initialValue={check.input_seal_top}
                                                onChange={onChangeValue}
                                            >
                                                <Input placeholder="Top Postion"/>
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>
                                </Card>
                            </Col>

                        </Row>
                    </Col>
                {/*</Row>*/}

                <Row>
                    <div className="col-md-12" style={{padding : '10px'}}>
                        <h4>Check Preview</h4>
                        <div style={{background : '#d9fad1', border: '1px solid black', width: ''+(check.input_size_width)+'px',height: ''+(check.input_size_height)+'px'}} >
                            <div style={{ display: 'inline-block',  marginLeft: ''+(check.input_date_left)+'px',marginTop: ''+(check.input_date_top)+'px'}}>mm/dd/yyyy</div>
                            <div style={{ display: 'inline-block', marginLeft: ''+(check.input_pay_to_left)+'px',marginTop: ''+(check.input_pay_to_top)+'px'}}>Xyz Corporation Inc.</div>
                            <div style={{ display: 'inline-block', marginLeft: ''+(check.input_amount_left)+'px',marginTop: ''+(check.input_amount_top)+'px'}}>$ 1,00,000.00</div>
                            <div style={{ display: 'inline-block', marginLeft: ''+(check.input_amount_word_left)+'px',marginTop: ''+(check.input_amount_word_top)+'px'}}>One Hundred thousand US Dollars only</div>
                            <div style={{ display: 'inline-block', marginLeft: ''+(check.input_signature_left)+'px',marginTop: ''+(check.input_signature_top)+'px'}}>SIGNATURE</div>
                            <div style={{ display: 'inline-block', marginLeft: ''+(check.input_seal_left)+'px',marginTop: ''+(check.input_seal_top)+'px'}}>SEAL</div>
                        </div>
                    </div>
                </Row>

                </div>

                <Row gutter={24}>
                    <Col lg={24} md={24} sm={12} xs={24} style={{textAlign: 'center'}}>
                        <Button type="primary" htmlType="submit">
                            Submit
                        </Button>
                        <Button type="info" onClick={() => history.goBack()} htmlType="back">Cancel</Button>
                    </Col>
                </Row>
            </Form>
        </Card>
   );
};

export default NewBankAccount;