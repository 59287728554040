import {Form, Row, Col, Input, Button, Card, Collapse, Modal, Select, DatePicker, Checkbox, notification, Spin} from 'antd';

import React, {useState, useEffect} from "react";
import jsonfile from './bill.json';
import {Link, useHistory, useRouteMatch} from "react-router-dom";
import {CisUI} from '../../../../util/CISUI';
import AdvancedSearch from '../../../../util/AdvancedSearch';
import ActionButton from '../../../../util/actionbutton';
import SmartDataTable from 'react-smart-data-table';
import 'react-smart-data-table/dist/react-smart-data-table.css';
import IntlMessages from "util/IntlMessages";
import Config from "../../../../util/config";
import axios from "../../../../util/Api";

import moment from "moment";
import 'moment/locale/es-us';
import RemoteJson from "../../../../util/gerremotedata";
import SelectData from "../../../../util/selectdata";

const {Option} = Select;
const {TextArea} = Input;
const queryString = require('query-string');

const Search = Input.Search;
const Panel = Collapse.Panel;
const Meta = Card.Meta;

const UnpaidBill = () => {
    const value = 0;
    const formRef = React.createRef();
    const history = useHistory();
    const [state,setState] = useState({
        reqURL: Config.apiserver+jsonfile.urls.unpaid+"?status=1",
        filtervalue : ''
    });
    const [loading, setLoading] = useState([]);

    const [paymentmethod, setPaymentmethod] = useState([]);
    const [bankaccounts, setBankAccounts] = useState([]);


    const addtionalMenu = [
        {
            label : "Pay Now",
            url : "newpayment?bill_id=",
            hasID: 1,
            qry: "id"
        }
    ]

    const [stdata,setStdata] = useState([]);
    const [selected,setSelected] = useState([]);

    const selectedForPrint = (e) => {
        // console.log(JSON.stringify(selected));
        // const st = [...selected];
        // console.log(e.target.checked);
        if(e.target.checked === true) {
            //st[e.target.name] = e.target.value;
            selected.push(e.target.value);
        }
        else {
            selected.pop(e.target.value);
        }
        // setSelected(st);
        // console.log(JSON.stringify(selected));
        // console.log(JSON.stringify(selected.length));

        if(selected.length > 0) {
            document.getElementById("prntbtn").style.display = "block";
            setStdata(Object.values(selected).join(','));
        }
        else {
            document.getElementById("prntbtn").style.display = "none";
            setStdata("");
        }
    }

    const clickTOPrint = (id) => {
        const url = Config.apiserver+"vendor/checkprint?selected="+id;
        const ck = "../../checkprint.html?type=checkprint&url="+window.btoa(url)+"&selected="+id;
        //window.open(ck, "_blank");
        return ck;
    }

    const checkAll = (e) => {
        var checkboxes = document.getElementsByName('input');
        for(var i=0, n=checkboxes.length;i<n;i++) {
            checkboxes[i].checked = true;
        }
    }

    const headers = {
        id: {
            text: <input type="checkbox" onChange={checkAll} name="checkAll" id="checkAll" value={1} />,
            sortable: true,
            filterable: false,
            transform: (value, idx, row) => (
                <input type="checkbox" onChange={selectedForPrint} name={"ck_"+value} value={value} />
            )
        },
        po_number: {
            text: <IntlMessages id="PO Number"/>,
            sortable: true,
            filterable: false,
            transform: (value, idx, row) => (
                <Link to={"../purchase/podetails/"+row.id}>{value}</Link>
            )
        },
        bill_no: {
            text: <IntlMessages id="Bill Details"/>,
            sortable: true,
            filterable: false,
            transform: (value, idx, row) => (
                <Link to={"billdetails/"+row.id}>{value}</Link>
            )
        },
        created_at: {
            text: <IntlMessages id="Created At"/>,
            sortable: true,
            filterable: false,
            transform: (value, idx, row) => (
                CisUI().DateFormat(value)
            )
        },
        amount: {
            text: <IntlMessages id="Amount"/>,
            sortable: true,
            filterable: false,
            transform: (value, idx, row) => (
                CisUI().getCurrency(value)
            )
        },
        status: {
            text: <IntlMessages id="Status"/>,
            sortable: true,
            filterable: true,
            transform: (value, idx, row) => (
                CisUI().getOrderStatus(value)
            )
        }
    };

    const orderedHeaders = jsonfile.listView.title;

    const getFields = () => {
        const count = expand ? 10 : 6;
        const children = [];
        const inputs = jsonfile.input;

        inputs.map((list, index) => {
            if(list.isFilterable == 1) {
                children.push(
                    <Col lg={6} md={6} sm={12} xs={24} key={index} style={{display: index < count ? 'block' : 'none'}}>
                        <div className="gx-form-row0">
                            <Form.Item
                                name={`${list.name}`}
                                label={`${list.label}`}
                                rules={[
                                    {
                                        required: true,
                                        message: "",
                                    },
                                ]}
                            >
                                <Input placeholder={list.name} />
                            </Form.Item>
                        </div>
                    </Col>,
                );
            }
        });
        return children;
    };

    const [expand, setExpand] = useState(false);
    const [form] = Form.useForm();

    const onFinish = values => {
        // console.log('Received values of form: ', values);
        const data = {
            ...values,
            // 'purchase_date' : values['purchase_date'].format("YYYY-MM-DD"),
        }
        // console.log(data);
        const qs = Object.keys(data).map(key => `${key}=${data[key]}`).join('&');
        const qsup = qs.replaceAll("undefined","");
        //reqURL = reqURL+"/?"+qsup;
        history.push("?"+qsup);
        const newURL = Config.apiserver+jsonfile.urls.unpaid+"/?status=0&"+qsup;
        setState({reqURL: newURL});
    };

    const handleOnFilter = ({ target: { name, value } }) => {
        //filterValue = value;
        console.log("filteringwith :"+ value);
        setState({filtervalue : "test"});
    }

    // const filterValue = "";

    const onPaymentMethodChange = (value) => {
        console.log("mehtod" + value);
        if(value === 2) {
            document.getElementById("bankaccount").style.display = "block";
        }
        else {
            document.getElementById("bankaccount").style.display = "none";
        }
        formRef.current.setFieldsValue({
            bank_account_id : null,
            reference : ""
        });
    }

    const onBankAccountChange = (value) => {
        let ref = "";
        bankaccounts.map((items, index) => {
            if(items.id === value) {
                if(items.last_issued_check > 0) {
                    ref = parseInt(items.last_issued_check) + 1;
                }
                else {
                    ref = items.starting_check_no;
                }
            }
        });

        console.log("RefNo : "+ ref);

        formRef.current.setFieldsValue({
            reference : ref,
            amount : state.amount,
            remark : "",
        });
    }

    let  searchView = "";
    if(jsonfile.has_search) {
        searchView = <Card className="advance-search-card">
            <Collapse
                accordion
                expandIconPosition="right"
                defaultActiveKey={['1']} >
                <Panel header="Advance Search"  key="1">
                    <Form
                        key={5}
                        form={form}
                        {...CisUI().formItemLayout}
                        name="advanced_search"
                        className="ant-advanced-search-form"
                        onFinish={onFinish}
                    >
                        {AdvancedSearch(jsonfile.input,"",1)}
                    </Form>
                </Panel>
            </Collapse>
        </Card>
    }

    const getExtra = (
        <>
            {/*<Search*/}
            {/*    placeholder='Filter...'*/}
            {/*    name='filtervalue'*/}
            {/*    onSearch={handleOnFilter}*/}
            {/*    onChange={handleOnFilter}*/}
            {/*    style={{width: 200}}*/}
            {/*/>*/}
            {CisUI().listAction("../"+jsonfile.urls.add)}
        </>
    );

    const goForApprove = () => {
        axios.post(Config.apiserver + "vendor/billpay&tps="+stdata, null)
            .then(res => {
                console.log(res)
                if (res.data.status === 1) {
                    notification.success({
                        message: 'Success',
                        type: "success",
                        description: res.data.msg
                    });
                    history.push("../"+jsonfile.urls.list);
                    //history.push("newbill?po="+polist.po_id);
                } else {
                    notification.warning({
                        message: 'Alert',
                        type: "warning",
                        description: res.data.msg
                    });
                }
                setLoading({loading: false});
            })
            .catch(errors => {
                console.log(errors.response.data.errors);
                setLoading({loading: false});
            });
    }

    const sleep = (milliseconds) => {
        return new Promise(resolve => setTimeout(resolve, milliseconds))
    }


    const onFinishPayment = (values) => {
        setLoading({loading:true})
        values["tps"] = stdata;
        console.log("Input Data : "+values);
        // console.log(values);
        let msg = "Sorry! request not processed, please try again";
        axios.post(Config.apiserver + "vendor/billpay", values, CisUI().HeaderRequest)
            .then(res => {
                console.log(res)
                if (res.data.status === 1) {
                    notification.success({
                        message: 'Success',
                        type: "success",
                        description: res.data.msg
                    });

                    sleep(2000);
                   // history.push("../vendor/unpaidbill");
                    window.location.reload();
                } else {
                    notification.warning({
                        message: 'Alert',
                        type: "warning",
                        description: res.data.msg
                    });
                }
                setLoading({loading: false});
            })
            .catch(errors => {
                console.log(errors.response.data.errors);
                // msg = (errors);
                setLoading({loading: false});
                //setResponse([]);
            });
    }

    const paymentMethodD = SelectData("paymentmethod");
    const bankAccountD = SelectData("bankaccount");


    return (
        <>
            {searchView}
            <Card title={jsonfile.unpaid_title} extra={getExtra}>
                {/*{CisUI().listAction(jsonfile.urls.add)}*/}
                <div className="row" id="prntbtn" style={{padding: '10px',  display: 'none'}}>
                    <Form
                        form={form}
                        {...CisUI().formItemLayout}
                        name="input"
                        ref={formRef}
                        className="ant-advanced-search-form"
                        onFinish={onFinishPayment}
                        initialValues={{ remember: true }}
                    >

                        <div className="row">
                            <div className="col-md-3">
                                <Form.Item
                                    name="date"
                                    label="Payment Date"
                                    initialValue={moment()}
                                    rules={[
                                        {
                                            required: true,
                                            message: "Select a date"
                                        },
                                    ]}
                                >
                                    <DatePicker format={CisUI().dateFormat} className="gx-mb-3 gx-w-100"
                                                placeholder="Date"/>
                                </Form.Item>
                            </div>
                            <div className="col-md-3">
                                <Form.Item
                                    name="payment_method"
                                    label="Payment Method"
                                    initialValue={1}
                                    rules={[
                                        {
                                            required: true,
                                            message: "Select payment method"
                                        },
                                    ]}
                                >
                                    <Select
                                        showSearch
                                        placeholder="Select a payable account"
                                        optionFilterProp="children"
                                        onChange={onPaymentMethodChange}
                                        allowClear={true}
                                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                    >
                                        {
                                            paymentMethodD.map((items, index) =>
                                                <Option key={++index} value={items.id}>{items.name}</Option>
                                            )
                                        }
                                    </Select>
                                </Form.Item>
                            </div>

                            <div className="col-md-3" id="bankaccount" style={{display: 'none'}}>
                                <Form.Item
                                    name="bank_account_id"
                                    label="Bank Account"
                                    rules={[
                                        {
                                            required: false,
                                            message: "Select a bank account"
                                        },
                                    ]}
                                >
                                    <Select
                                        showSearch
                                        placeholder="Select a bank account"
                                        optionFilterProp="children"
                                        onChange={onBankAccountChange}
                                        allowClear={true}
                                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                    >
                                        {
                                            bankAccountD.map((items, index) =>
                                                <Option key={++index} value={items.id}>{items.account_no}-{items.account_name}({items.bank_name})</Option>
                                            )
                                        }
                                    </Select>
                                </Form.Item>
                            </div>
                            <div className="col-md-3">
                                <br />
                                <Button type="primary" htmlType="submit">PayNow</Button>
                            </div>

                        </div>

                    </Form>
                    {/*<a onClick={goForApprove} className="btn btn-primary" style={{width: '100px'}} >Pay Now</a>*/}
                </div>
                <SmartDataTable
                    name='test-fake-table'
                    data={state.reqURL}
                    dataRequestOptions={CisUI().HeaderRequest}
                    dataKey="data"
                    headers={headers}
                    orderedHeaders={orderedHeaders}
                    hideUnordered={CisUI().hideUnordered}
                    className={CisUI().sematicUI.table}
                    filterValue={state.filterValue}
                    perPage={CisUI().perPage}
                    sortable
                    withLinks
                    withHeader
                    loader={CisUI().loader}
                    parseBool={{
                        yesWord: 'Yes',
                        noWord: 'No',
                    }}
                    parseImg={{
                        style: {
                            border: '1px solid #ddd',
                            borderRadius: '50px',
                            padding: '3px',
                            width: '30px',
                            height: '30px'
                        },
                    }}
                    emptyTable={CisUI().emptyTable}
                />
            </Card>
        </>
    );
};

export default UnpaidBill;