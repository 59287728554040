import React, {useState, useEffect} from "react";
import { Form, Row, Col, Input, Button, Collapse ,Card, Table, Radio, notification} from 'antd';
import TaskList from "components/dashboard/CRM/TaskList";
import SiteVisit from "components/dashboard/CRM/SiteVisit";
import RecentActivity from "components/dashboard/CRM/RecentActivity";
import TicketList from "components/dashboard/CRM/TicketList";
import TaskByStatus from "components/dashboard/CRM/TaskByStatus";
import Overview from "components/dashboard/CRM/Overview";
import SiteAudience from "components/dashboard/CRM/SiteAudience";
import Auxiliary from "util/Auxiliary";
import TotalRevenueCard from "components/dashboard/CRM/TotalRevenueCard";
import NewCustomers from "components/dashboard/CRM/NewCustomers";
import GrowthCard from "components/dashboard/CRM/GrowthCard";
import Widget from "components/Widget/index";
import CurrencyCalculator from "components/dashboard/Crypto/CurrencyCalculator";
import IconWithTextCard from "components/dashboard/CRM/IconWithTextCard";
import {recentActivity, taskList, trafficData} from "./data";
import { MailOutlined,MessageOutlined,BellOutlined,UnorderedListOutlined   } from '@ant-design/icons';
import LineIndicator from "./LineIndicator";
import {siteVisit} from "./data";
import {Bar, BarChart, Area, AreaChart, CartesianGrid,  XAxis, YAxis, ResponsiveContainer, Tooltip} from "recharts";
import axios from "../../../util/Api";
import Config from "../../../util/config";
import {CisUI} from "../../../util/CISUI";
import Metrics from "../../../components/Metrics";

const AdminDashboard = (props) => {

    const userInfo = localStorage.getItem("userData");
    const user = JSON.parse(userInfo);
    const [incomeExpense,setInocmeExpnese] = useState([]);
    const [state,setState] = useState({
        totalMember : 0,
        totalPayingMember : 0,
        totalNonPayingMember : 0,
        payingPercent : 0,
        nonPayingPercent : 0,
        totalTithe : 0,
        titheCompare : [],
    });

    useEffect(() => {
        getDashboardData();
    }, []);


    const getDashboardData = () => {
        document.getElementById("loader").style.display = "block";
        axios.get(Config.apiserver + "dashboard")
            .then(res => {
                console.log(res)
                if (res.data.status === 1) {
                    setInocmeExpnese(res.data.incomeExpense);
                    const totalMember = res.data.totalMember
                    const totalPayingMember = res.data.totalPayingMember
                    const totalNonPayingMember = res.data.totalNonPayingMember
                    const payingPercent = ((totalPayingMember * 100) / totalMember).toFixed(2);
                    const nonPayingPercent = ((totalNonPayingMember * 100) / totalMember).toFixed(2);

                    setState({
                        totalMember : totalMember,
                        totalPayingMember : totalPayingMember,
                        payingPercent : payingPercent,
                        totalNonPayingMember : totalNonPayingMember,
                        nonPayingPercent : nonPayingPercent,
                        totalTithe : res.data.totalTithe,
                        titheCompare : res.data.titheCompare,
                    });
                } else {
                    notification.warning({
                        message: 'Alert',
                        type: "warning",
                        description: res.data.msg
                    });
                }
                document.getElementById("loader").style.display = "none";
            })
            .catch(errors => {
                console.log(errors.response.data.errors);
                document.getElementById("loader").style.display = "none";
            });
    }

    const width = 400;

    return (
        <Auxiliary>
            {CisUI().showLoading}
            <Row>
                <Col span={24}>
                    <div className="gx-card">
                        <div className="gx-card-body">
                            <Row>
                                <Col xl={6} lg={12} md={12} sm={12} xs={24}>

                                    <div className="gx-wel-ema gx-pt-xl-2">
                                        <h1 className="gx-mb-3">Welcome {user.name}!</h1>
                                        <p className="gx-fs-sm gx-text-uppercase">You Have</p>
                                        <ul className="gx-list-group">
                                            <li>
                                                <MessageOutlined />
                                                <span>5 Approval</span>
                                            </li>
                                            <li>
                                                <MailOutlined />
                                                <span>2 notifications</span>
                                            </li>
                                            <li>
                                                <UnorderedListOutlined />
                                                <span>7 Pending PO's</span>
                                            </li>
                                            <li>
                                                <BellOutlined />
                                                <span>3 Task</span>
                                            </li>
                                        </ul>
                                    </div>

                                </Col>

                                <Col xl={6} lg={12} md={12} sm={12} xs={24} className="gx-audi-col">
                                    <div className="gx-site-dash gx-mb-2 gx-pt-3 gx-pt-sm-0 gx-pt-xl-2">
                                        <h6 className="gx-text-uppercase gx-mb-2 gx-mb-sm-4">Member Count</h6>
                                        <ul className="gx-line-indicator">
                                            <li>
                                                <LineIndicator width="100%" title="Total Member" color="cyan" value={CisUI().getNumber(state.totalMember)} />
                                            </li>
                                            <li>
                                                <LineIndicator width={state.payingPercent+"%"} title={"Total Paying Member ("+CisUI().getNumber(state.totalPayingMember)+")"} color="geekblue" value={state.payingPercent+"%"} />
                                            </li>
                                            <li>
                                                <LineIndicator width={state.nonPayingPercent+"%"} title={"Total Nonpaying Member ("+CisUI().getNumber(state.totalNonPayingMember)+")"} color="orange" value={state.nonPayingPercent+"%"} />
                                            </li>
                                        </ul>
                                    </div>
                                </Col>

                                <Col xl={12} lg={24} md={24} sm={24} xs={24} className="gx-visit-col">
                                    <div className="gx-site-dash gx-pr-xl-5 gx-pt-3 gx-pt-xl-0 gx-pt-xl-2">
                                        <h6 className="gx-text-uppercase gx-mb-2 gx-mb-xl-4">Income & Expenditure</h6>
                                        <ResponsiveContainer width="100%" height={140}>
                                            <AreaChart data={incomeExpense}
                                                       margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
                                                <defs>
                                                    <linearGradient id="colorIncome" x1="0" y1="0" x2="0" y2="1">
                                                        <stop offset="5%" stopColor="#002974" stopOpacity={0.8}/>
                                                        <stop offset="95%" stopColor="#002974" stopOpacity={0.0}/>
                                                    </linearGradient>
                                                    <linearGradient id="colorExpnese" x1="0" y1="0" x2="0" y2="1">
                                                        <stop offset="5%" stopColor="#bf163f" stopOpacity={0.8}/>
                                                        <stop offset="95%" stopColor="#bf163f" stopOpacity={0}/>
                                                    </linearGradient>
                                                </defs>
                                                <XAxis dataKey="name" />
                                                <YAxis tickFormatter={(value) => new Intl.NumberFormat('en', { notation: "compact", compactDisplay: "short" }).format(value)} />
                                                <CartesianGrid strokeDasharray="3 3" />
                                                <Tooltip formatter={(value) => new Intl.NumberFormat('en').format(value)} />
                                                <Area type="monotone" dataKey="Income" label={"Income"} stroke="#8884d8" fillOpacity={1} fill="url(#colorIncome)" />
                                                <Area type="monotone" dataKey="Expense" label={"Expense"} stroke="#82ca9d" fillOpacity={1} fill="url(#colorExpnese)" />
                                            </AreaChart>
                                        </ResponsiveContainer>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </Col>
                <Col xl={8} lg={24} md={8} sm={24} xs={24}>
                    <Metrics title="Total Tithe Income">
                        <Row>
                            <Col xl={11} lg={12} md={24} sm={12} xs={12}>
                                <h4 className="gx-mb-1">{CisUI().getCurrencyFormated1(state.totalTithe)}</h4>
                                <p className="gx-mb-md-0 gx-text-light">Total Tithes YTD</p>
                            </Col>
                            <Col xl={13} lg={12} md={24} sm={12} xs={12}>

                                <ResponsiveContainer className="gx-barchart" width="100%" height={70}>
                                    <BarChart data={state.titheCompare}
                                              margin={{top: 0, right: 0, left: 0, bottom: 0}}>
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis dataKey="name" />
                                        <Tooltip formatter={(value) => new Intl.NumberFormat('en').format(value)} />
                                        <Bar dataKey="lastYear" stackId="a" fill="#038FDE" barSize={width <= 575 ? 4 : 8}/>
                                        <Bar dataKey="thisYear" stackId="a" fill="#FE9E15" barSize={width <= 575 ? 4 : 8}/>
                                    </BarChart>
                                </ResponsiveContainer>
                            </Col>
                        </Row>
                    </Metrics>
                </Col>
                <Col xl={8} lg={12} md={8} sm={24} xs={24}>
                    <NewCustomers/>
                </Col>
                <Col xl={8} lg={12} md={8} sm={24} xs={24}>
                    <GrowthCard trafficData={trafficData}/>
                </Col>

                <Col xl={8} lg={24} md={24} sm={24} xs={24} className="gx-order-sm-2">
                    <Widget>
                        <RecentActivity recentList={recentActivity} shape="circle"/>
                    </Widget>
                    <CurrencyCalculator/>
                </Col>

                <Col xl={16} lg={24} md={24} sm={24} xs={24} className="gx-order-sm-1">
                    <Row>
                        <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                            <IconWithTextCard cardColor="cyan" icon="diamond" title="09" subTitle="Projects"/>
                        </Col>
                        <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                            <IconWithTextCard cardColor="orange" icon="tasks" title="687" subTitle="Tasks"/>
                        </Col>
                        <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                            <IconWithTextCard cardColor="teal" icon="team" title="04" subTitle="Teams"/>
                        </Col>
                        <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                            <IconWithTextCard cardColor="red" icon="files" title="09" subTitle="Files"/>
                        </Col>
                        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                            <TaskList taskList={taskList}/>
                        </Col>
                        <Col xl={16} lg={16} md={16} sm={24} xs={24}>
                            <TicketList/>
                        </Col>
                        <Col xl={8} lg={8} md={8} sm={24} xs={24}>
                            <TaskByStatus/>
                        </Col>
                        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                            <Overview/>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Auxiliary>
    );
};

export default AdminDashboard;
