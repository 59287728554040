import React, {useState, useEffect} from "react";
import {Input, Select} from 'antd';
// import axios from "axios";
import Config from "./config";
import axios from 'util/Api'


const { Option } = Select;
const {TextArea} = Input;


const getRemoteJSON = async (endpoint="") => {
    let respData = [];

    // await axios.get(Config.apiserver+endpoint)
    //     .then((res) => {
    //         //console.log(res.data);
    //         respData = res.data.data;
    //     })
    //     .catch((error) => {
    //         console.log(error);
    //         // setState([]);
    //     });


    const getData = async () => {
        return await axios.get(Config.apiserver+endpoint)
            .then((res) => {
                //console.log(res.data);
                respData = res.data.data;
            })
            .catch((error) => {
                console.log(error);
                // setState([]);
            });
    };

    getData();

    return respData;

}

export default getRemoteJSON;